import React, { Fragment, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { can } from '../../utils/can';

import { JobAppContext } from '../../GlobalState';
import { Collapse, Navbar, Nav, NavItem } from 'reactstrap';
import { NavLink } from '../partials/NavLink';
import OtherLink from '../partials/Link'

import { SimpleFooter } from './Footer';

import BrandImage from '../../assets/images/ncscredit-horizontal.png';
import './LeftNav.css';

/*
const getTitle = () => {
	const company = JSON.parse(localStorage.getItem('company'))
	if (company !== null) {
		return `${company.name}`
	}
	return 'Job Information'
}
*/

let openSidebar = () => {
	let leftNav = document.getElementById('left-hand-nav');
	let sidebarLinks = leftNav.firstChild;

	leftNav.style.width='250px';
	sidebarLinks.style.display='block';

	document.addEventListener('click', closeSideBar);
}

let closeSideBar = () => {
	let leftNav = document.getElementById('left-hand-nav');
	let sidebarLinks = leftNav.firstChild;

	leftNav.style.width='';
	sidebarLinks.style.display='none';

	document.removeEventListener('click', closeSideBar);
}

const LeftNav = (props) => {

	const { isAuthenticated, user, signOut, fetchJobList, jobsPage } = useContext(JobAppContext);

	const [ isOpen, setIsOpen ] = useState(false);

	const getJobList = (jobList) => {
		fetchJobList(jobList)
	}

	/*
	const toggleNavbar = () => {
		setIsOpen(!isOpen);
	}
	*/

	return (
		<Navbar id="left-hand-nav" expand="sm" className="sidebar">
			{/*<NavbarToggler onClick={this.toggleNavbar} className="mr-2 mb-2" />*/}
			<Collapse isOpen={isOpen} navbar className="sidebar-sticky d-flex flex-column">
				
				<div style={{ borderBottom: 'solid 1px #000000', paddingBottom: '8px' }}>
					<Link to="/jobs">
						<img src={BrandImage} alt="NCS Job App" style={{ height: 'auto', width: '100%' }} />
					</Link>
				</div>

				<Nav className="mt-4 nav-links " navbar vertical style={{ flexGrow: 1 }}>
					
					<li className="NavLink">
						<span className="fa fa-list" />
						<span className="d-xs-inline d-sm-none d-xl-inline"> Job Lists</span>
						<ul className="list-unstyled m-0 xl:ml-3 text-align-left">
							<NavLink to="/jobs/open" highlight="open" classes="mb-0">Open</NavLink>
							<NavLink to="/jobs/review" highlight="review" classes="mb-0">Review</NavLink>
							<NavLink to="/jobs/submitted" highlight="submitted" classes="mb-0">Submitted</NavLink>
							<NavLink to="/jobs/archived" highlight="archived" classes="mb-0">Archived</NavLink>
							{/* <NavLink to="/jobs/archive" highlight="archive">Archive</NavLink> */}
						</ul>
					</li>
					
					<NavLink to="/forms" highlight="forms">
						<span className="fa fa-columns" />
						<span className="d-xs-inline d-sm-none d-xl-inline"> Forms</span>
					</NavLink>

					{can(['users'], user) && (
						<NavLink to="/users" highlight="users">
							<span className="fa fa-users" />
							<span className="d-xs-inline d-sm-none d-xl-inline"> Users</span>
						</NavLink>
					)}

					<NavLink to="/profile" highlight="profile" classes="d-block d-sm-none">
						<span className="far fa-user" />
						<span className="d-xs-inline d-sm-none d-xl-inline"> {user.name}</span>
					</NavLink>

					<li className="NavLink d-block d-sm-none">
						<a href="/" className="nav-link subtle" onClick={() => signOut()}>
							<span className="fas fa-sign-out-alt" />
							<span> Logout</span>
						</a>
					</li>
				</Nav>

				<ul className="navbar-nav flex-column nav-links nav-bottom d-none d-sm-block">
					<NavLink to="/profile" highlight="profile">
						<span className="far fa-user" />
						<span className="d-xs-inline d-sm-none d-xl-inline"> {user.name}</span>
					</NavLink>
					<li className="NavLink">
						<a href="/" className="nav-link subtle" onClick={() => signOut()}>
							<span className="fas fa-sign-out-alt" /><span className="d-none d-xl-inline"> Logout</span>
						</a>
					</li>
				</ul>

				<SimpleFooter />

			</Collapse>
		</Navbar>
	)
}
export default LeftNav;
