import React, { Fragment, useState, useEffect, useContext } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import { JobAppContext } from '../../GlobalState'

import { classnames } from '../../utils/classnames'
import { can } from '../../utils/can'

import {
	getCompany, updateCompany, 
	addService, updateService, removeService,
	addCustomer, updateCustomer, deleteCustomer, uploadCustomerFile
} from '../../api'

import {
	Alert, Button,
	Table,
	Modal, ModalHeader, ModalBody, ModalFooter,
	TabContent, TabPane, Nav, NavItem, NavLink
} from 'reactstrap'
import { Form } from '../partials'
import Place from '../partials/Place'
import { ListDropDown } from '../partials/ListDropDown'

const user_roles = [
	{ 'id': 1, 'value': 1, 'name': 'Owner' },
	{ 'id': 3, 'value': 3, 'name': 'General Contractor' },
	{ 'id': 4, 'value': 4, 'name': 'Subcontractor' },
	{ 'id': 5, 'value': 5, 'name': 'Sub-Subcontractor' },
]

const tabs = {
	SETTINGS: 'settings',
	SERVICES: 'services',
	CUSTOMERS: 'customers',
	STATS: 'stats',
	DATA_EXPORT: 'data_export'
}

const CompanyPage = (props) => {

	const { user, accessToken } = useContext(JobAppContext);

	const [ isLoading, setIsLoading ] = useState(true);
	const [ id, setId ] = useState(0);
	const [ name, setName ] = useState('');
	const [ review, setReview ] = useState(1);

	const [ activeTab, setActiveTab ] = useState(tabs.SETTINGS);

	// email reminder notification settings
	const [ config, setConfig ] = useState({
		from: 'Job App Admin <admin@jobinfo.app>',
		subject: '[company_name] | [job_name]',
		body: `Hi,\n\nPlease fill out the form located at the link below:\n\n[job_name]\n[link address]\n\nThanks,\n[user_name]\n[user_email]`,
		send_reminders: false,
		first_reminder: 2,
		between_reminders: 5,
		request_expires: 45,
		warn_sender: 7
	})

	const [ no_archive, setNoArchive ] = useState(true);
	
	const [ contact_id, setContactId ] = useState(0);

	const [ archive_length, setArchiveLength ] = useState(0);
	const [ archive_duration, setArchiveDuration ] = useState('day');
	
	const [ reference_label, setReferenceLabel ] = useState('Customer Reference #');
	const [ autofill_label, setAutofillLabel ] = useState('Customer Auto-fill');
	
	// this option not visible in the UI
	const [ custom_style, setCustomStyle ] = useState('');
	
	const [ service_types, setServiceTypes ] = useState([]);
	
	const [ isSaving, setIsSaving ] = useState(false);
	const [ showMessage, setShowMessage ] = useState(false);
	const [ unsavedChanges, setUnsavedChanges ] = useState(false);

	const [ serviceModal, setServiceModal ] = useState(false);
	const [ new_service, setNewService ] = useState({
		'id': 0,
		'text': '',
		'lien_direct': 0,
		'default': 0
	});

	const [ uploadModal, setUploadModal ] = useState(false);
	const [ uploadText, setUploadText ] = useState('');

	const [ stats, setStats ] = useState([]);
	const [ users, setUsers ] = useState([]);

	const [ customer_file_label, setCustomerFileLabel ] = useState('Choose File');
	
	const [ customerModal, setCustomerModal ] = useState(false);
	const [ editCustomer, setEditCustomer ] = useState({
		id: 0,
		contact_role : 0,
		address: {
			company   : "",
			name      : "",
			phone     : "",
			address   : "",
			address_2 : "",
			city      : "",
			state     : "",
			zip       : "",
			county    : "",
			email     : ""
		}
	});
	const [ customers, setCustomers ] = useState([]);

	const params = useParams();
	const history = useHistory();

	useEffect(() => {

		getCompany(params.id).then(data => {

			if ( data['error'] ) {
				console.log('error found: ', data['error']);
				history.push('/settings')
				return
			} else {

				// getting customer list from Company "show" function call, instead of listCustomers
				var customer_list = data.customers.map(customer => {
					return {
						id: customer.id,
						contact_role: customer.contact_role,
						role_desc: customer.role_desc,
						address: {
							company         : customer.company,
							name            : customer.name,
							phone           : customer.phone,
							formatted_phone : customer.formatted_phone || customer.phone,
							address         : customer.address,
							address_2       : customer.address_2 || '',
							city            : customer.city,
							state           : customer.state,
							zip             : customer.zip,
							county          : customer.county,
							email           : customer.email
						}
					}
				})

				setId(data.id);
				setName(data.name);

				if ( data.config_settings ) {
					setConfig({
						...config,
						...JSON.parse(data.config_settings)
					});
				}
				
				setContactId(data.contact_id || 0);
				setReview(data.review_required);
				setNoArchive(data.archive_length == -1 ? true : false);
				setArchiveLength(data.archive_length);
				setArchiveDuration(data.archive_duration);
				setReferenceLabel(data.reference_label);
				setAutofillLabel(data.autofill_label);
				setCustomStyle(data.custom_style || '');

				setStats(data.stats);
				setUsers(data.user_list);

				setServiceTypes([
					...data.service_types.filter(data => data.status == 'active')
				]);
				setCustomers([
					...customer_list
				]);
				setIsLoading(false);
				
			}
			
		})

	}, [])

	const changeTab = tab => {
		if ( activeTab !== tab ) {
			switch (tab) {
				case 'stats': break; // stats load when page first opened
				case 'settings': break; // settings load when page first opened
				case 'services':
					if ( !service_types ) {
						// no service types loaded yet... get company service types
						console.log('no service_types... get service_types')
					}
					break;
				case 'customers':
					if ( !customers ) {
						// no company customers loaded yet... get company customers (auto-fill users)
						console.log('no customers... get customer data')
					}
					break;
			}
			setActiveTab(tab);
			setShowMessage(false);
		}
	}

	const handleSubmit = event => {
		setUnsavedChanges(false);
		setIsSaving(true);

		let company_data = {
			'name'              : name,
			// 'review_required'   : review,
			'config_settings'	: config,
			'archive_length'    : !no_archive ? archive_length : -1, // if no_archive is unchecked, apply archive_length value
			'archive_duration'  : !no_archive ? archive_duration : 'day', // if no_archive is unchecked, apply archive_duration value
			'reference_label'   : reference_label,
			'autofill_label'    : autofill_label,
			'custom_style'      : custom_style,
			'contact_id'        : contact_id
		}
		// console.log('company_data', company_data);
		
		updateCompany(id, company_data)
			.then(data => {

				let config_opts = JSON.parse(data.config_settings);

				setName(data.name);
				// setReview(data.review_required);
				setNoArchive(data.archive_length == -1 ? true : false);
				
				setConfig(JSON.parse(data.config_settings));
				setArchiveLength(data.archive_length);
				setArchiveDuration(data.archive_duration);
				setReferenceLabel(data.reference_label);
				setAutofillLabel(data.autofill_label);
				setCustomStyle(data.custom_style || '');
				setContactId(data.contact_id || 0);
				
				setShowMessage(true);
				setIsSaving(false);
			})
	}

	const handleCheckboxChange = event => {
		const target = event.target
		const { value, name, checked, type } = target

		let arch_len = archive_length
		let arch_dur = archive_duration
		if ( name == "no_archive" ) {
			arch_dur = 'day', arch_len = !checked ? -1 : 0
		}

		setNoArchive(!checked ? true : false);
		setArchiveLength(arch_len);
		setArchiveDuration(arch_dur);
		showChanged();

	}

	const showChanged = () => {
		setUnsavedChanges(true);
		setShowMessage(false);
	}

  /* ************************************************************************ */
  /* ***** Service Type Functions ************************************* ***** */
  /* ************************************************************************ */
	const toggleServiceModal = () => {
		setServiceModal(!serviceModal);
	}

	const changeService = event => {
		const target = event.target
		const { name, checked, type } = target
		var { value } = target

		if ( name == 'lien_direct' ) {
			value = checked ? 1 : 0
		}
		setNewService({
			...new_service,
			[name]: value
		})
	}

	const addNewService = e => {
		// clear/open customer modal, with a blank form
		setNewService({
			'id': 0,
			'text': '',
			'lien_direct': 0,
			'default': 0
		})
		setServiceModal(true);
	}

	const editService = e => {
		const { target } = e

		// get this specific customer's ID from the data-cust-id attribute on the "Edit" button
		var service_id = parseInt(target.dataset.serviceId)
		var edit_service = service_types.find(data => data.id === service_id)

		// clear the add customer modal
		setNewService({
			...edit_service
		})
		setServiceModal(true);
	}

	const deleteService = e => {
		const { target } = e

		// get this specific customer's ID from the data-cust-id attribute on the "Edit" button
		var service_id = parseInt(target.dataset.serviceId)
		removeService(service_id).then(oResp => {
			var new_service_list = service_types.filter(type => type.id !== oResp.service.id);
			if ( oResp.status == "success" ) {
				setServiceTypes([
					...new_service_list
				]);
			}
		})
	}

	const saveServiceData = () => {
		if ( new_service.id == 0 ) {

			addService(new_service).then(oResp => {
				setServiceTypes([
					...service_types,
					{ ...oResp }
				])
				setNewService({
					'id': 0,
					'text': '',
					'lien_direct': 0,
					'default': 0
				})
				setServiceModal(false);
			})

		} else {

			updateService(new_service).then(oResp => {
				// update service list
				var new_service_list = service_types.map(service => {
					if (service.id == oResp.id) {
						return oResp
					} else {
						return service
					}
				})
				setServiceTypes(new_service_list);
				setServiceModal(false);
			})

		}
	}

	/* ************************************************************************ */
	/* ***** Customer Functions ***************************************** ***** */
	/* ************************************************************************ */
	const toggleCustomerModal = () => {
		setCustomerModal(!customerModal);
	}

	const changeCustomer = event => {
		const target = event.target
		const { value, name } = target

		var label = name.split('.')

		setEditCustomer({
			...editCustomer,
			address: {
				...editCustomer.address,
				[ label[2] ]: value
			}
		})
		setUnsavedChanges(true);
		setShowMessage(false);
	}

	const role_update = e => {
		const { target } = e;
		const { name, value } = target;

		// var choice = parseInt(target.getAttribute('data-choice'))

		setEditCustomer({
			...editCustomer,
			contact_role: value
		})
	}

	const addNewCustomer = e => {
		// clear/open customer modal
		setEditCustomer({
			id: 0,
			contact_role: 0,
			address: {
				company   : "",
				name      : "",
				phone     : "",
				address   : "",
				address_2 : "",
				city      : "",
				state     : "",
				zip       : "",
				county    : "",
				email     : ""
			}
		})
		setCustomerModal(true);
	}

	const saveCustomerData = () => {

		/*
		let submitMessage = document.querySelector('#submitBtnMsg')
		if ( submitMessage ) {
			submitMessage.style.display = 'none';
		}
		*/

		if ( editCustomer.id == 0 ) {
			
			// saving new customer
			addCustomer( editCustomer ).then(oResp => {
				// upon completion, append editCustomer to customers array
				setCustomers([
					...customers,
					{
						id: oResp.id,
						contact_role: oResp.contact_role,
						role_desc: oResp.role_desc,
						address: {
							company   : oResp.company,
							name      : oResp.name,
							phone     : oResp.phone,
							formatted_phone : oResp.formatted_phone || oResp.phone,
							address   : oResp.address,
							address_2 : oResp.address_2,
							city      : oResp.city,
							state     : oResp.state,
							zip       : oResp.zip,
							county    : oResp.county,
							email     : oResp.email
						}
					}
				])
				setCustomerModal(false);
				setEditCustomer({
					id: 0,
					contact_role: 0,
					role_desc: '',
					address: {
						company   : "",
						name      : "",
						phone     : "",
						address   : "",
						address_2 : "",
						city      : "",
						state     : "",
						zip       : "",
						county    : "",
						email     : ""
					}
				})
				
			}) // end addCustomer()

		} else {

			// saving changes to existing record
			updateCustomer(editCustomer).then(oResp => {
				// upon completion, loop through customers, and replace array instance with editCustomer
				var new_cust_list = customers.map(customer => {
					if ( customer.id == oResp.id ) {
						return {
							id: oResp.id,
							contact_role: oResp.contact_role,
							role_desc: oResp.role_desc,
							address: {
								company   : oResp.company,
								name      : oResp.name,
								phone     : oResp.phone,
								address   : oResp.address,
								address_2 : oResp.address_2,
								city      : oResp.city,
								state     : oResp.state,
								zip       : oResp.zip,
								county    : oResp.county,
								email     : oResp.email
							}
						}
					} else {
						return customer
					}
				})
				setCustomers([ ...new_cust_list ]);
				setCustomerModal(false);
				setEditCustomer({
					id: 0,
					contact_role: 0,
					role_desc: '',
					address: {
						company   : "",
						name      : "",
						phone     : "",
						address   : "",
						address_2 : "",
						city      : "",
						state     : "",
						zip       : "",
						county    : "",
						email     : ""
					}
				})
			}) // end updateCustomer()

		}
	}

	const editCustomerInfo = e => {
		const { target } = e

		// get this specific customer's ID from the data-cust-id attribute on the "Edit" button
		var customer_id = parseInt(target.dataset.custId)
		var cust_info = customers.find(customer => customer.id === customer_id)
		setEditCustomer({ ...cust_info });
		setCustomerModal(true);
	}

	const deleteCustomerInfo = e => {
		const { target } = e

		// get this specific customer's ID from the data-cust-id attribute on the "Delete" button
		var customer_id = target.dataset.custId

		// delete the selected customer
		deleteCustomer(customer_id).then(oResp => {
			if ( oResp.status == "success" ) {
				var new_list = customers.filter(customer => customer.id !== parseInt(customer_id));
				setCustomers(new_list);
			}
		}) // end deleteCustomer()
	}

	const uploadCustFile = event => {
		const target = event.target;
		const { files } = target;

		setCustomerFileLabel(files[0].name);
		setUploadModal(true);
		setUploadText('Loading Customer records found in "' +files[0].name+ '"');

		let formData = new FormData()
		formData.append('file', files[0]);

		uploadCustomerFile(formData).then(oResp => oResp.json()).then(oResp => {

			// getting customer list from Company "show" function call, instead of listCustomers
			var customer_list = oResp.customers.map(customer => {
				return {
					id: customer.id,
					contact_role: customer.contact_role,
					role_desc: customer.role_desc,
					address: {
						company         : customer.company,
						name            : customer.name,
						phone           : customer.phone,
						formatted_phone : customer.formatted_phone || customer.phone,
						address         : customer.address,
						address_2       : customer.address_2 || '',
						city            : customer.city,
						state           : customer.state,
						zip             : customer.zip,
						county          : customer.county,
						email           : customer.email
					}
				}
			})
			setCustomers([
				...customers,
				...customer_list
			])
			setCustomerFileLabel('Choose File');
			setUploadText(oResp.upload_count +' Customer records added');

		}).catch(err => {
			console.log('File upload failed!', err);
		});

	}
  
	const toggleUploadModal = () => {
		setUploadModal(false);
		setUploadText('');
	}

	const handleConfigChange = (e) => {
		const { name, value } = e.target;
		setConfig({
			...config,
			[name]: value
		});
		showChanged();
	}

	const handleEmailSettings = (e) => {
		const { type, name, value, checked } = e.target;
		if ( type == "checkbox" ) {
			setConfig({
				...config,
				send_reminders: checked
			})
		} else {
			setConfig({
				...config,
				[name]: type == 'number' ? parseInt(value) : value
			});
		}
		showChanged();
	}

	let {
		from,
		subject,
		body,
	  	send_reminders,
	  	first_reminder,
		between_reminders,
		request_expires,
		warn_sender
	} = config;

    let {
      open, pending, submitted, deleted, archived, total
    } = stats

	return (
		<div>
			{/* can(['super'], user) && (
				<Link to="/admin">
					Back to Admin Page
				</Link>
			) */}

			<Alert color="danger" id="submitBtnMsg" className="mr-2" style={{ display: 'none' }}></Alert>

			<Modal isOpen={uploadModal} toggle={toggleUploadModal}>
				<ModalBody>
					<div>{uploadText}</div>
				</ModalBody>
			</Modal>

			<Modal isOpen={serviceModal} toggle={toggleServiceModal}>
				<Form onSubmit={saveServiceData}>
					<ModalHeader toggle={toggleServiceModal}>
						<span>Add New Service Type</span>
					</ModalHeader>
					<ModalBody>
						
						<div className="form-row">
							<label htmlFor="new_service">Service Description</label>
							<input type="text" name="text" value={new_service['text']} className="form-control" onChange={changeService} />
						</div>

						<div className="dropdownListInline">
							<div style={{ display: 'inline-block' }}>
								<input type="checkbox" name="lien_direct" value="1" onChange={changeService} checked={new_service['lien_direct'] == 1 ? true : false} style={{ marginRight: '10px' }} />
								<label htmlFor="lien_direct" className="form-check-label">Lien Direct</label>
							</div>
						</div>

						<cite className="mt-3" style={{ color: 'red' }}>* indicates a required field</cite>
					</ModalBody>
					<ModalFooter>
						<Button type="button" color="danger" onClick={toggleServiceModal}>Cancel</Button>
						<Button color="primary" className="ml-3">Save</Button>
					</ModalFooter>
				</Form>
			</Modal>

			<Modal isOpen={customerModal} toggle={toggleCustomerModal} style={{ 'maxWidth': '1024px' }}>
				<Form onSubmit={saveCustomerData}>
					<ModalHeader toggle={toggleCustomerModal}>
						<span>Add New Customer</span>
					</ModalHeader>
					<ModalBody>
						<ListDropDown
							className="form-group input-wrapper"
							label="Contact Role"
							description="User's Role in the Project"
							name="assoc_role"
							selected={editCustomer.contact_role}
							onChange={role_update}
							items={user_roles}
							readOnly={false}
						/>
						<Place
							hasContact={true}
							isRequired={0}
							label="Customer Name"
							onChange={changeCustomer}
							value={editCustomer}
							parent={editCustomer.id}
							type_id={null}
							readOnly={false}
						/>
					</ModalBody>
					<ModalFooter>
						<Button type="button" color="danger" onClick={toggleCustomerModal}>Cancel</Button>
						<Button color="primary" className="ml-3">Save</Button>
					</ModalFooter>
				</Form>
			</Modal>

			<div className="page-navbar d-flex justify-content-between pt-3">
				<Nav tabs className="border-0 pt-2">
					<NavLink className={classnames( 'jobTabs__tab', (activeTab === tabs.SETTINGS) ? 'activeTab' : '' )}
							onClick={() => changeTab(tabs.SETTINGS)}>
						Settings
					</NavLink>
					{can(['super'], user) && (
						<NavLink className={classnames( 'jobTabs__tab', (activeTab === tabs.SERVICES) ? 'activeTab' : '' )}
								onClick={() => changeTab(tabs.SERVICES)} disabled={isLoading}>
							Services
						</NavLink>
					)}
					<NavLink className={classnames( 'jobTabs__tab', (activeTab === tabs.CUSTOMERS) ? 'activeTab' : '' )}
							onClick={() => changeTab(tabs.CUSTOMERS)} disabled={isLoading}>
						Customers
					</NavLink>
					{can(['super'], user) && (
						<NavLink className={classnames( 'jobTabs__tab', (activeTab === tabs.STATS) ? 'activeTab' : '' )}
								onClick={() => changeTab(tabs.STATS)} disabled={isLoading}>
							Statistics
						</NavLink>
					)}
					{can(['data_export'], user) && (
						<NavLink className={classnames( 'jobTabs__tab', (activeTab === tabs.DATA_EXPORT) ? 'activeTab' : '' )}
								onClick={() => changeTab(tabs.DATA_EXPORT)} disabled={isLoading}>
							Data Export
						</NavLink>
					)}
				</Nav>
			</div>

			<div className="padded-content">
			{isLoading ? (
				<div className="p-3 rounded" style={{ border: 'solid 1px #CACACA', width: '400px' }}>
					<i className="fas fa-sync-alt fa-spin mr-1" ></i> Loading Company Settings
				</div>
			) : (
				<TabContent activeTab={activeTab}>
					{activeTab === tabs.SETTINGS && <TabPane tabId={tabs.SETTINGS}>
						<h4 className="mb-3">Company Settings</h4>
						{showMessage && (
							<Alert color="success">
								SUCCESS! Company Settings have been updated!
							</Alert>
						)}

						<div style={{ paddingLeft: '20px' }}>
							<Form onSubmit={handleSubmit}>
								<div className="form-group">

									<div className="form-row">
										<div className="col-4">
											<label htmlFor="name">Company Name:</label>
											<input type="text" name="name" id="name" value={name} 
												onChange={(e) => {
													setName(e.target.value); 
													showChanged()
												}} className="form-control" />
										</div>
									</div>

									<hr />

									<div className="form-row">
										<div className="col-4">
											<label>Company Contact Person</label>
											<div className="checkbox">
												<label>
													<select name="contact_id" className="form-control" value={contact_id} 
														onChange={(e) => {
															setContactId(e.target.value); 
															showChanged()
														}}>
														{users.map((user, index) => {
															return (
																<option key={index} value={user.id}>{user.name} ({user.email})</option>
															)
														})}
													</select>
												</label>
											</div>
										</div>
									</div>

									{/*<!-- div className="form-row">
									<div className="col-4">
										<h4>Job Review</h4>
										<div className="checkbox">
										<label>
											<input
										    type="checkbox"
										    name="review"
										    value="1"
										    checked={review == 1 ? true : false}
										    onChange={this.handleCheckboxChange}
										    />
											Require Review
										</label>
										</div>
									</div>
									</div -->*/}
										
									<hr />

									<div className="form-row py-3">
										<div className="col-4">
											<div className="checkbox">
												<label>
													<input
														type="checkbox"
														name="no_archive"
														value="1"
														checked={!no_archive ? true : false}
														onChange={handleCheckboxChange}
													/>
													Archive Jobs
												</label>
											</div>
											<div className="input-group">
												<div className="input-group-prepend">
													<input type="number" name="archive_length" id="archive_length" size="2" maxLength="2" 
														value={archive_length >= 0 ? archive_length : 0} onChange={(e) => {
															setArchiveLength(e.target.value); 
															showChanged()
														}} 
														readOnly={no_archive ? true : false}
														className="form-control pl-3" />
												</div>
												<select name="archive_duration" className="form-control" value={archive_duration} onChange={(e) => {
													setArchiveDuration(e.target.value); 
													showChanged()
												}} disabled={no_archive ? true : false}>
													<option value="day">Day(s)</option>
													<option value="week">Week(s)</option>
													<option value="month">Month(s)</option>
												</select>
											</div>
										</div>
									</div>

									<hr />
									
									<h4>Add Job Form Customization</h4>

									<div className="form-row py-3">
										<div className="col-4">
											<label>Reference Number Label</label>
											<div className="input-group">
												<input type="text" name="reference_label" className="form-control" value={reference_label} onChange={
													(e) => {
														setReferenceLabel(e.target.value); 
														showChanged()
													}
												} placeholder="Customer Reference #" />
											</div>
										</div>
									</div>

									<div className="form-row py-3">
										<div className="col-4">
											<label>Customer Auto-fill label</label>
											<div className="input-group">
												<input type="text" name="autofill_label" className="form-control" value={autofill_label} onChange={(e) => {
													setAutofillLabel(e.target.value); 
													showChanged()
												}} placeholder="Customer Auto-fill" />
											</div>
										</div>
									</div>

									<hr />

									{custom_style !== '' && (
										<Fragment>
											<div className="form-row py-3">
												<div className="col-4">
													<h4>Custom Stylesheet</h4>
													<label>Filename</label>
													<div className="input-group">
														<input type="text" name="custom_style" className="form-control" value={custom_style} onChange={(e) => {
															setCustomStyle(e.target.value); 
															showChanged()
														}} />
													</div>
												</div>
											</div>
											<hr />
										</Fragment>
									)}

									<h4>Email Notification Settings</h4>
									<div className="form-row py-3">
										<div className="col-4">
											
										<h5 className="mb-3">Share Job | Email Defaults</h5>
											<div>
												<div>
													<label htmlFor="from">From</label>
													<input type="text" name="from" value={from} onChange={handleEmailSettings} className="form-control" />
												</div>
												<div>
													<label htmlFor="subject">Subject</label>
													<input type="text" name="subject" value={subject} onChange={handleEmailSettings} className="form-control" />
												</div>
												<div>
													<label htmlFor="body">Body</label>
													<textarea name="body" defaultValue={body} onChange={handleEmailSettings} rows={10} className="form-control" />
												</div>
											</div>
											
											<h5 className="my-3">Reminders</h5>
											<div>
												<div className="form-check form-check-inline">
													<input type="checkbox" id="send_reminders" name="send_reminders" value="Y" onChange={handleEmailSettings} checked={send_reminders} className="form-check-input mr-3" />
													<label className="form-check-label" htmlFor="send_reminders">Send automatic reminders</label>
												</div>
												<div>
													<label htmlFor="first_reminder">Days before sending first reminder</label>
													<input type="number" name="first_reminder" value={first_reminder} onChange={handleEmailSettings} className="form-control" readOnly={!send_reminders} />
												</div>
												<div>
													<label htmlFor="between_reminders">Days between reminders</label>
													<input type="number" name="between_reminders" value={between_reminders} onChange={handleEmailSettings} className="form-control" readOnly={!send_reminders} />
												</div>
											</div>

											<h5 className="my-3">Notifications</h5>
											<div>
												<div>
													<label htmlFor="request_expires">Days before reminder expires</label>
													<input type="number" name="request_expires" value={request_expires} onChange={handleEmailSettings} className="form-control" readOnly={!send_reminders} />
												</div>
												<div>
													<label htmlFor="warn_sender">Warn email creator # of days before reminder expiration</label>
													<input type="number" name="warn_sender" value={warn_sender} onChange={handleEmailSettings} className="form-control" readOnly={!send_reminders} />
												</div>
											</div>
										</div>
									</div>
									<hr />

									<div className="form-row pt-3">
										<div className="col-12">
											<Button type="submit" disabled={(!unsavedChanges ? true : false)} color="primary">
												{isSaving ? 'Saving...' : 'Save Changes'}
											</Button>
										</div>
									</div>
								</div>
							</Form>
						</div>
					</TabPane>}

					{can(['super'], user) && 
						activeTab === tabs.SERVICES && <TabPane tabId={tabs.SERVICES}>
						<h4>
							Service Types Available
							{can(['services'], user) && (
								<Button size="sm" color="primary" className="ml-3" onClick={addNewService}>Add New</Button>
							)}
						</h4>
						{service_types.length > 0 ? (
							<Table responsive className="mt-3">
								<thead>
									<tr>
										<th>Count</th>
										<th>Service Type Option</th>
										<th style={{ textAlign: 'center' }}>Lien Direct</th>
										<th style={{ textAlign: 'center' }}>Default</th>
										{can(['services'], user) && (<th>&nbsp;</th>)}
									</tr>
								</thead>
								<tbody>
									{service_types.map((service, index) => (
										<tr key={service.id}>
											<td>{index + 1}</td>
											<td>{service.text}</td>
											<td style={{ textAlign: 'center' }}>
												{service.lien_direct == 1 && (
													<i className="far fa-check-circle"></i>
												)}
											</td>
											<td style={{ textAlign: 'center' }}>
												{service.default == 1 && (
													<i className="far fa-check-circle"></i>
												)}
											</td>
											{can(['services'], user) && (
												<td>
													<Button size="sm" color="secondary" data-service-id={service.id} onClick={editService}>Edit</Button>
													<Button size="sm" color="danger" data-service-id={service.id} className="ml-3" onClick={deleteService}>Delete</Button>
												</td>
											)}
										</tr>
									))}
								</tbody>
							</Table>
						) : (
							<div className="m-3 p-3" style={{ border: 'solid 1px #CACACA', backgroundColor: '#EAEAEA' }}>
								<p>No Custom Service types setup.</p>
								<p>All projects will import with a Service Type of "Notice :: Premium"</p>
								<p>Contact an Admin if you would like to setup additional Service Types.</p>
							</div>
						)}
					</TabPane>}

					{activeTab === tabs.CUSTOMERS && <TabPane tabId={tabs.CUSTOMERS}>
					
						{can(['super'], user) && (
							<div className="float-right" style={{ width: '600px' }}>
								<div className="custom-file" style={{ marginRight: '100px' }}>
									<input type="file" name="cust_list" value="" className="custom-file-input" onChange={uploadCustFile} />
									<label className="custom-file-label" htmlFor="cust_list">{customer_file_label}</label>
								</div>
							</div>
						)}

						<h4>
							Customer Autofill Presets
							<Button size="sm" color="primary" className="ml-3" onClick={addNewCustomer}>Add New</Button>
						</h4>
					
						{customers.length > 0 && (
							<div className="input-group">
								<Table responsive className="mt-3">
									<thead>
										<tr>
											<th>&nbsp;</th>
											<th>User Role</th>
											<th>Company Name</th>
											<th>Contact Name</th>
											<th>Phone</th>
											<th>Address</th>
											<th>Email</th>
											<th>&nbsp;</th>
										</tr>
									</thead>
									<tbody>
										{customers.map((customer, index) => {
											var display_addr = customer.address.address +", "+ 
												(customer.address.address_2 != '' ? customer.address.address_2 + ", " : '')+ 
												customer.address.city +", "+ customer.address.state +" "+ customer.address.zip;
											return (<tr key={index}>
												<td>{index + 1}</td>
												<td>{customer.role_desc}</td>
												<td>{customer.address.company}</td>
												<td>{customer.address.name}</td>
												<td>{customer.address.formatted_phone}</td>
												<td>{display_addr}</td>
												<td>{customer.address.email}</td>
												<td>
													<Button size="sm" color="secondary" data-cust-id={customer.id} onClick={editCustomerInfo}>Edit</Button>
													<Button size="sm" color="danger" data-cust-id={customer.id} className="ml-3" onClick={deleteCustomerInfo}>Delete</Button>
												</td>
											</tr>)
										})}
									</tbody>
								</Table>
							</div>
						)}
					</TabPane>}

					{can(['super'], user) && 
						activeTab === tabs.STATS && <TabPane tabId={tabs.STATS}>
						<h4>Statistics (coming soon)</h4>
					
						{showMessage && (
							<Alert color="success">
								SUCCESS! Company Settings have been updated!
							</Alert>
						)}

						<div className="row my-3">

							<div className="col-md-6">
								<div className="row my-3">
									<div className="col">
										<h5 className="stats_title">Jobs</h5>
										<ul className="job_stats">
											<li><label>Open</label>{open}</li>
											<li><label>Pending</label>{pending}</li>
											<li><label>Submitted</label>{submitted}</li>
											{/*
											<li><label>Archived</label>{archived}</li>
											<li><label>Deleted</label>{deleted}</li>
											*/}
											<li><label>Total</label>{total - deleted}</li>
										</ul>
									</div>
								</div>
								<div className="row my-3">
									<div className="col">
										<h5 className="stats_title">Job Data Points (example stats)</h5>
										<ul>
											<li>Total Workflow Average</li>
											<li>Response Time</li>
											<li>Most Common Customer Role</li>
											<li>Contract Amount Stats</li>
											<li>Claim Amount Stats</li>
											<li>Earliest Furnishing Date</li>
											<li>Most Recent Furnishing Date</li>
										</ul>
									</div>
								</div>
							</div>

							<div className="col-md-6">
								<h5 className="stats_title">Users (stats coming soon)</h5>
								<Table responsive>
									<thead>
										<tr>
											<th>Name</th>
											<th>Logins</th>
											<th>Jobs Entered</th>
										</tr>
									</thead>
									<tbody>
										{users.map(user => (
											<tr key={user.id}>
												<td>{user.name}</td>
												<td>{user.login_count}</td>
												<td>{user.job_count}</td>
											</tr>
										))}
									</tbody>
								</Table>
							</div>
						</div>
					
					</TabPane>}

					{can(['data_export'], user) && 
						activeTab === tabs.DATA_EXPORT && <TabPane tabId={tabs.DATA_EXPORT}>
					
						<h4>Export Job Data</h4>
						<div className="row my-3">
							<div className="col">
								<p className="pl-2">
									Click the button below to export all currently stored Job Data in a .csv file.
								</p>
								<form action="/api/v1/data/export" method="POST" target="_blank">
									<input type="hidden" name="token" value={accessToken} />
									<button type="submit" className="btn btn-primary">
										Export All Job Data ({total - deleted} total)
									</button>
								</form>
							</div>
						</div>
					
					</TabPane>}

				</TabContent>
			)}
			</div>
      	</div>
	)
}
export default CompanyPage;