import React,{ Component } from 'react'

import formatDate from "./formatDate";
import PropTypes from 'prop-types'

import { orderBy } from "lodash-es";

import Highlighter from "./Highlighter";
import Pagination from "./Pagination";

export default class SortableTable extends Component{
	constructor(props){
		super(props)

		this.paginationRef = React.createRef()

		this.state = {
			view:        props.view ? props.view : 100,
			start:       1,
			end:         props.all ? props.info.length : props.view ? props.view : 100,
			sort:        props.startSort ? props.startSort : '',
			sortBy:      'asc',
			selected:    [],
			selectedAll: false,
			colTotals:   null
		}
	}

	changeSort(variable) {
		const newSortBy = this.state.sortBy === 'desc' ? 'asc' : 'desc'
		if ( this.state.sort === variable ) {
			this.setState({
				sortBy: newSortBy
			})
		} else {
			this.setState({
				sort: variable,
				sortBy: 'desc'
			})
		}
	}

	sortDirection(variable) {
		if ( this.state.sort === variable ) {
			return this.state.sortBy === 'desc' ? <span className={'fas fa-sort-down'}/> : <span className={'fas fa-sort-up'}/>
		} else {
			return <span className={'fas fa-sort'}/>
		}
	}

	multiRow(values,row) {
		return values.map((val, key) => (
			<div key={'multiRow'+key+(row[this.props.id?this.props.id:Math.random()])}>
				{key>0?<hr style={{margin:'10px'}}/>:''}
					<Highlighter check={row[val]}>
						<span style={key>0?{color:'#8f8f8f'}:{}}>{row[val]?row[val]:'None'}</span>
					</Highlighter>
			</div>
		))
	}

	renderRow = (row) => {

		let rowProps = {};
		if( typeof this.props.rowProps === "function") {
			rowProps = this.props.rowProps(row);
		} else if (this.props.rowProps) {
			rowProps = this.props.rowProps
		}

		return <tr key={this.props.id ? row[this.props.id] : Math.random()} {...rowProps}>
			{this.props.headers.map((header)=> (
				<td key={'tableData'+(header.name)+(this.props.id?row[this.props.id]:Math.random())} width={header.width ? header.width+'%' : 'auto'}>
					{header.custom ?
						header.custom(Array.isArray(header.value)?header.value.map(val=>{return row[val]}):header.value === true ? row : row[header.value])
						:
						header.date ?
							formatDate(row[header.value])
							:
							Array.isArray(header.value) ?
								this.multiRow(header.value, row)
								:
								<Highlighter check={row[header.value]}><span>{row[header.value]}</span></Highlighter>
					}
				</td>
			))}
			{this.props.rowTotals &&
				<td>
					{this.props.headers.map(header=>(
						!header.noTotal && row[header.value]
					)).reduce((partial_sum, a) =>
						partial_sum + a
					)}
				</td>
			}
		</tr>
	}

	render(){

		if (this.props.info.length === 0) {
			return '';
		}
		
		const orderedData = orderBy(
			this.props.info,
			Array.isArray(this.state.sort) ? this.state.sort : [this.state.sort], 
			[this.state.sortBy]
		)

		let grandTotal = 0
		const colTotals = this.props.colTotals &&
			<tr style={{backgroundColor: "#fbfbfb"}}>
				{this.props.headers.map((header,key)=>{
					return <td key={header.value}>
						{header.noTotal ?
							key === 0 ?
								'Totals'
								:
								'None'
							:
							this.props.info.map(row => (
								parseInt(row[header.value])
							)).reduce((partial_sum, a, index) => {
								grandTotal = grandTotal + (index === 1 ? partial_sum + a : a)
								return partial_sum + a
							})
						}
					</td>
				})}
				{this.props.rowTotals &&
					<td>{grandTotal}</td>
				}
			</tr>

		return (
			<div className="table-responsive">
				<table
					className={this.props.className ? this.props.className:'table table-hover'}
					// style={this.props.style ? {...this.props.style}:''}
				>
					<thead>
						<tr>
							{this.props.checkbox &&
								<th>
									<input
									type={'checkbox'}
									className={'checkbox'}
									checked={this.state.selectedAll || this.state.selected.length === this.props.info.length}
									onChange={()=>{this.setState(prevState=>{return {selectedAll:!prevState.selectedAll,selected:[]}})}}
									/>
								</th>
							}
							{this.props.headers.map(header=>(
								header.name.length > 0 ?
									header.sort !== false ?
									<th
										key={header.value}
										onClick={()=>{this.changeSort(header.value)}}
										className={"pointer"}
										style={{whiteSpace:'nowrap'}}
									>
										<span>{header.name} {this.sortDirection(header.value)}</span>
									</th>
									:
									<th
										key={header.value}
										style={{whiteSpace:'nowrap'}}
									>
										<span>{header.name}</span>
									</th>
									:
									<th key={header.value}>{header.name}</th>
							))}
							{this.props.rowTotals && <th>Total</th>}
						</tr>
					</thead>

					<tbody>
						{this.props.all ?
							orderedData.map(row=>this.renderRow(row))
							:
							<Pagination info={orderedData} render={this.renderRow} renderAfter={colTotals} view={this.state.view} table/>
						}
					</tbody>
				</table>
			</div>
		)
	}
}

SortableTable.propTypes = {
	headers: PropTypes.array.isRequired,
	info: PropTypes.array.isRequired,
	startSort: PropTypes.string,
	view: PropTypes.number,
	all: PropTypes.bool
}