import React, { useState, useEffect, useContext } from 'react'
import { useHistory, Link } from 'react-router-dom';
import { JobAppContext } from '../../GlobalState';

import { Button } from 'reactstrap';
import { Form, Input } from '../partials';

const SignInForm = (props) => {

	const { isAuthenticated, auth, error, clearError } = useContext(JobAppContext);

	const [ email, setEmail ] = useState('');
	const [ password, setPassword ] = useState('');

	const history = useHistory();

	useEffect(() => {
		if (isAuthenticated === true) {
			history.push('/jobs')
		}
		clearError()
	}, [isAuthenticated])

	const handleSubmit = () => {
    	clearError()
		auth({ email, password });
	}

	return (
		<Form onSubmit={handleSubmit} className="mt-3 mb-5">
			<h1>Login</h1>
			{error && (
				<div className="alert alert-danger my-4">
					The email and password combination did not match.
				</div>
			)}

			<div className="form-group">
				<div className="form-row my-3">
					<div className="col">
						<Input
							label="Email"
							name="email"
							onChange={(e) => setEmail(e.target.value)}
							type="email"
							value={email}
						/>
					</div>
				</div>
				<div className="form-row my-3">
					<div className="col">
						<Input
							label="Password"
							name="password"
							onChange={(e) => setPassword(e.target.value)}
							type="password"
							value={password}
						/>
					</div>
				</div>
			</div>

			<Button type="submit" color="primary" disabled={!(email !== '' && password !== '')}>
				Submit
			</Button>
			<Link to="/reset" className="float-right">Forgot Your Password</Link>
		</Form>
	)
}
export default SignInForm;
