import React from 'react'
import { Button } from 'reactstrap'

import { delay } from '../../utils/method-helpers'
import { classnames } from '../../utils/classnames'
import { Form, Checkbox, Textarea } from '../partials'

export const FieldChoices = ({ choices, addChoice, removeChoice }) => {
  var choice = '';

  return <Form
    onSubmit={() => {
      addChoice(choice.value)
      choice.value = ''
    }}
  >
    <div className="addChoices">
      <div className="addChoicesTitle">Add Choices</div>
      <input
        type="text"
        className="Input--input"
        ref={text => (choice = text)}
      />
      <Button onClick={() => {
          if (choice.value) {
            addChoice(choice.value)
            choice.value = ''
          }
        }}
      >
        Add
      </Button>
    </div>
    {choices.map((choice, key) => (
      <div key={choice.id} className="choiceItem">
        {choice.name}
        <div className="deleteChoice" onClick={() => removeChoice(choice)}>
          x
        </div>
      </div>
    ))}
    {/* <div>
      <Checkbox name="other" value={''} label="Allow Other" />
    </div> */}
  </Form>
}
