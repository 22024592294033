import React, { useState, useEffect, useContext } from 'react'
import { JobAppContext } from '../../GlobalState'
import { can } from '../../utils/can'

import {
	// getPermissions,
	// getWorkflows,
	getRoles,
	deleteWorkflow,
	updateWorkflow,
	createWorkflow,
	updateOrder,
	getKeys,
} from '../../api'

import { Modal, ModalHeader, ModalBody, ModalFooter, Table, Button } from 'reactstrap'
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
// import { classnames } from '../../utils/classnames'
import { /* Checkbox, Input, */ Form } from '../../components'
import { ListDropDown } from '../partials/ListDropDown'
import { Workflow } from './Workflow'

const grid = 4

const getItemStyle = (isDragging, draggableStyle) => ({
	userSelect: 'none',
	background: isDragging ? 'lightgreen' : 'grey',
	...draggableStyle,
})

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list)
	const [removed] = result.splice(startIndex, 1)
	result.splice(endIndex, 0, removed)
	return result
}

const Workflows = (props) => {

	const { user } = useContext(JobAppContext);

	const [ workflows, setWorkflows ] = useState([]);
	const [ roles, setRoles ] = useState([]);

	const [ value, setValue ] = useState('');
	const [ selected, setSelected ] = useState([]);
	const [ users, setUsers ] = useState(false);
	const [ forms, setForms ] = useState(false);
	const [ projects, setProjects ] = useState(false);
	const [ addingWorkflow, setAddingWorkflow ] = useState(false);
	const [ workflow_id, setWorkflowId ] = useState(0);
	const [ confirmDelete, setConfirmDelete ] = useState(false);
	// const [ canSubmit, setCanSubmit ] = useState(false);

	useEffect(() => {
		/*
		getRoles().then(data => {
			this.setState({
			roles: data,
			})
		});


		getWorkflows().then(data => {  
			let userList = this.props.users.users;
			// go through workflows, and append users from props.users, if they are in the workflow
			for(let i=0; i<data.length; i++) {
			userList.forEach(user => {
				if (user.role_id == data[i].role_id) {
				data[i].user = user;
				return;
				}
			});

			}

			this.setState({
			workflows: data,
			value: data[0].role.id,
			})
		})
		*/
	})

	useEffect(() => {
		if ( props.workflows.length !== workflows.length || props.roles.length !== roles.length ) {
			setWorkflows(props.workflows);
			setRoles(props.roles);
			setValue(props.roles[0].id);
	   	}
	}, [props.workflows])

	/*
	static getDerivedStateFromProps(newProps, state) {
		if ( newProps.workflows.length !== state.workflows.length || newProps.roles.length !== state.roles.length ) {
			return {
				workflows: newProps.workflows,
				roles: newProps.roles,
				value: newProps.roles[0].id,
			};
		}
		return null;
	}
	*/

	const toggleModal = () => {
		setConfirmDelete(!confirmDelete);
	}

	const toggleAddModal = () => {
		setAddingWorkflow(!addingWorkflow);
	}

	/*
	handleChange = event => {
		const { target } = event;
		const { name, value } = target;

		this.setState({
			[name]: value,
		})
	}
	*/

	const handleDelete = () => {
		deleteWorkflow(confirmDelete, value);
		setWorkflows(workflows.filter(
			workflow => workflow.id !== confirmDelete
		));
		setConfirmDelete(false);
	}

	const handleSubmit = () => {
		const data = {
			role_id: value
		}

		if ( workflow_id === 0 ) {
			createWorkflow(data).then(res => {
				console.log(res)
				setAddingWorkflow(false);
				setWorkflows([ ...workflows, res ])
			})
		} else {
			updateWorkflow(workflow_id, data).then(res => {
				setAddingWorkflow(false);
				setWorkflowId(0);
				setWorkflows(workflows.map(workflow => (workflow.id === res.id ? res : workflow)));
			})
		}
	}

	const onDragEnd = result => {
		if (!result.destination) {
			return
		}

		const workflowOpts = reorder(
			workflows,
			result.source.index,
			result.destination.index,
		)

		// console.log(workflows)

		updateOrder({ workflows: workflowOpts }).then(data => console.log(data))

		setWorkflows(workflowOpts)
	}

	const getLastWorkflow = () => 
		workflows.length && workflows[workflows.length - 1].role.name

	return (
		<div>
				
			<Modal isOpen={confirmDelete != false} toggle={toggleModal} className={props.className} style={{ 'maxWidth': '650px' }}>
				<ModalHeader toggle={toggleModal}>Delete Step</ModalHeader>
				<ModalBody>
					{workflows.length == 1 && (
						<div>Sorry, you can't delete the only step.</div>
					)}
					{workflows.length !== 1 && (
						<div>
							<p>Move all users in this role to:</p>
							<ListDropDown
								name="value"
								selected={value}
								onChange={(e) => setValue(e.target.value)}
								limit={0}
								items={workflows
									.map(workflow => workflow.role)
									.filter(role => role.id !== confirmDelete)}
							/>
						</div>
					)}
				</ModalBody>
				<ModalFooter>
					<Button className="mr-2" onClick={handleDelete} disabled={workflows.length === 1}>
						Confirm Delete
					</Button>
					<Button onClick={() => setConfirmDelete(false)}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>

			<Modal isOpen={addingWorkflow} toggle={toggleAddModal} className={props.className}>
				<Form onSubmit={handleSubmit}>
				<ModalHeader toggle={toggleAddModal}>
					<span>{workflow_id === 0 ? 'New' : 'Edit'} Workflow Step</span>
				</ModalHeader>
				<ModalBody>
					<div className="form-group">
						<div className="form-row my-3">
							<div className="col">
								<ListDropDown
									name="value"
									label="Role"
									selected={value}
									onChange={(e) => setValue(e.target.value)}
									limit={0}
									items={[ ...roles ]}
								/>
							</div>
						</div>
						<div className="form-row">
							<div>
							Note: All Users in the selected Role, will be notified when a job is 
							assigned to this Workflow Step.
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button className="mr-2" disabled={!value}>
						Save
					</Button>
					<Button onClick={() => {
						setAddingWorkflow(false);
						setWorkflowId(0);
					}}>
						Cancel
					</Button>
				</ModalFooter>
				</Form>
			</Modal>

			<h4 style={{ backgroundColor: '#EAEAEA', padding: '20px' }}>
				Workflow Steps
				{can(['workflows'], user) &&
					<Button color="primary" size="sm" className="ml-2" onClick={() => setAddingWorkflow(true)}>
						Add Step
					</Button>
				}
			</h4>
			<div className="m-3" style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
						
				<div className="workflow_instr">
					Define the workflows/steps a job goes through your organization.
					{/* canSubmit && (
						<div className="Workflow--note">
							In your setup, {getLastWorkflow()} will be the last step in submitting the form to NCS.
						</div>
					) */}
				</div>

				<Table responsive style={{ maxWidth: '1200px' }}>
					<thead>
						<tr>
							<th>Workflow Step</th>
							<th>Assigned Role</th>
							{can(['workflows'], user) &&
								<th>Actions</th>
							}
						</tr>
					</thead>
					<tbody>
						{workflows.map((workflow, index) => (
							<Workflow 
								key={workflow.id} 
								role={workflow.role} 
								canEdit={can(['workflows'], user)}
								onEdit={() => {
									setAddingWorkflow(true);
									setWorkflowId(workflow.id);
								}}
								onDelete={() => setConfirmDelete(workflow.id)}
								count={index+1}
							/>
						))}
					</tbody>
				</Table>
          
				<div className="mb-4">
					<span className="font-weight-bold">Important Notes:</span>
					<ul>
						<li>Newly-created jobs are always assigned to the first Workflow Step</li>
						<li>Only users assigned to the role in the last Workflow Step can import jobs to NCS Online Services (for notice creation, tracking, etc).</li>
					</ul>
				</div>

			</div>
		</div>
	)
}
export default Workflows;