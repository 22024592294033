import React from 'react'
import { classnames } from '../../utils'
import { Link as RouterLink, Route } from 'react-router-dom'

const Link = ({ children, className, to, highlight, exact = true }) => (
	<Route path={to} exact={exact}>
		{({ match }) => {
			return (
				<RouterLink
					className={classnames(className, match ? 'active' : '')}
					to={to}
				>
					{children}
				</RouterLink>
			)
		}}
	</Route>
)

export default Link
