import React, { Fragment, useState, useContext, useEffect } from 'react';
import formatDate from '../partials/formatDate';

import { JobAppContext } from '../../GlobalState'

import { email_external, resendEmail, optOutEmail } from '../../api'

import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Button, Row, Col, Table, Tooltip
} from 'reactstrap';
import { Form, Input, Textarea } from '../../components'
import { first } from 'lodash-es';

const EmailRecord = ({ email, openEmail, updateRec, config, showOptOut, optOut }) => {
	
	const [ isSending, setIsSending ] = useState(false);
	const [ isDisabled, setDisabled ] = useState(false);

	const [ show, setShow ] = useState(false);
	const [ emailInfo, setEmailInfo ] = useState(null);

	useEffect(() => {
		if ( config ) {
			showReminderDetails();
		}
	}, [])

	const resendEmailBtn = (id) => {
		if ( email.uuid !== '' ) {
			
			// console.log('counting down from 5')
			setIsSending(true);
			setTimeout(() => {
				// console.log(' ---> executing...')
				resendEmail({
					email_id: email.id,
					uuid: email.uuid,
					job_id: email.job_id
				}).then(oResp => {
					// console.log(' ---> done.')
					setIsSending(false);
					if ( oResp.status == "success" ) {
						updateRec(oResp.email);
					} else {
						alert('Error sending email: ' + oResp.error)
					}
				})
			}, 3000)

		} else {
			console.log('id', id, 'email', email)
			alert('no valid uuid value found')
		}
	}

	const reminderOptOut = (id) => {	
		
		// add 1/2 second delay to prevent doubleclick.
		setDisabled(true);
		setTimeout(() => {
			// function only needs id of email record since opt_out value is just being flipped 0 -> 1 -> 0
			optOutEmail({ id }).then(oResp => {
				if ( oResp.status == "success" ) {
					optOut(id);
				} else {
					alert('Error updating Optout option for email reminders. Contact Support')
				}
				setDisabled(false);
			})
		}, 500)

	}

	const showReminderDetails = () => {

		Date.prototype.addDays = function(days) {
			var date = new Date(this.valueOf());
			date.setDate(date.getDate() + days);
			return date;
		}

		Date.prototype.subDays = function(days) {
			var date = new Date(this.valueOf());
			date.setDate(date.getDate() - days);
			return date;
		}

		var currentDate = new Date();
		var formattedDate = new Date(email.created_at);
		var firstReminderDate = formattedDate.addDays(config.first_reminder);
		var expireDate = formattedDate.addDays(config.request_expires);
		var warningDate = expireDate.subDays(config.warn_sender);

		let firstReminderSoon = currentDate < firstReminderDate ? true : false;
		let warningSoon = currentDate > warningDate & currentDate < expireDate ? true : false;
		
		setEmailInfo({
			currentDate: currentDate.toISOString().slice(0, 10),
			formattedDate: formattedDate.toISOString().slice(0, 10),
			firstReminderDate: firstReminderDate.toISOString().slice(0, 10),
			warningDate: warningDate.toISOString().slice(0, 10),
			expireDate: expireDate.toISOString().slice(0, 10)
		})

		// return {
		// 	currentDate: currentDate.toISOString().slice(0, 10),
		// 	formattedDate: formattedDate.toISOString().slice(0, 10),
		// 	firstReminderDate: firstReminderDate.toISOString().slice(0, 10),
		// 	warningDate: warningDate.toISOString().slice(0, 10),
		// 	expireDate: expireDate.toISOString().slice(0, 10)
		// }

	}

	let resend_uuids = email?.resend_uuids?.length > 0 ? JSON.parse(email?.resend_uuids) : [];
	let expired = emailInfo?.currentDate > emailInfo?.expireDate;

	return (
		<>
			{showOptOut && expired && (
				<Tooltip
					isOpen={show}
					toggle={() => setShow(!show)}
					target={'email_' +email.id}
				>
					<div>
						{/* First Reminder Send Date: {formatDate(emailInfo?.firstReminderDate, 'L')}<br />
						Warning Notice Send Date: {formatDate(emailInfo?.warningDate, 'L')}<br /> */}
						Expired on {formatDate(emailInfo?.expireDate, 'L')}
					</div>
				</Tooltip>
			)}
			<tr>
				<td>
					<div>
						{formatDate(email.created_at, 'L')}<br />
						{formatDate(email.created_at, 'LT')}
					</div>
				</td>
				<td>
					<div className="pl-3">
						<span className="font-weight-bold">Recipient:</span> {email.to}<br />
						<span className="font-weight-bold">Subject:</span> {email.subject}
					</div>
				</td>
				<td>
					{email.status}
					{resend_uuids?.length > 0 && (
						<> ( {resend_uuids?.length + 1} )</>
					)}
				</td>
				{showOptOut && (
					<>
						<td>
							<input 
								title="Check to send reminders"
								type="checkbox" name="opt_out" className="ml-3"
								checked={!email.opt_out}
								onChange={() => reminderOptOut(email.id)} disabled={isSending || isDisabled || expired} />
							{email.last_reminder_date && (
								<span className="ml-2" style={{ fontSize: '.8rem' }}>Last Sent: {formatDate(email.last_reminder_date, 'L')}</span>
							)}
							{expired && (
								<span id={'email_' +email.id} className="ml-2" style={{ fontSize: '.8rem', color: 'red' }}>Expired</span>
							)}
						</td>
						{/* <td>
							<div>
								First Reminder Send Date: {formatDate(emailInfo?.firstReminderDate, 'L')}<br />
								Warning Notice Send Date: {formatDate(emailInfo?.warningDate, 'L')}<br />
								Reminder Expiration Date: {formatDate(emailInfo?.expireDate, 'L')}
							</div>
						</td> */}
					</>
				)}
				<td>
					<Button size="sm" onClick={() => openEmail(email.id)} disabled={isSending || isDisabled}>View</Button>
					{email.uuid && (
						<Button color="secondary" size="sm" className="ml-3" onClick={() => resendEmailBtn(email.id)} disabled={isSending || isDisabled}>
							{isSending ? 'Resending...' : 'Resend'}
						</Button>
					)}
				</td>
			</tr>
		</>
	)
}

const filterValues = (val) => {
	var newValue = val;


	
	return newValue;
}

const EmailLinkHistory = ( prop ) => {

	const { user } = useContext(JobAppContext);

	const company = user.memberships[0].company;
	const config_settings = company.config_settings ? JSON.parse(company.config_settings) : false;

	// these fields should be filtered for display to the end user.
	// Other fields will be evaluated and replaced on the server side (EmailController@email_external)
	const filter_values = (val) => {
	
		const merge_fields = {
			'company_name': company.name, 
			'job_name': prop.jobName, 
			'user_name': user.name, 
			'user_email': user.email
		}
		var filtered_value = val;
		for ( const fld in merge_fields ) {
			filtered_value = filtered_value.replace('[' +fld+ ']', merge_fields[fld]);
		}
		
		return filtered_value;
	}

	var val = config_settings?.subject ?? '[company_name] | [job_name]';
	var proc_subject = filter_values(val);

	var val = config_settings?.body ?? 'Hi,\n\nPlease fill out the form located at the link below:\n\n[job_name]\n[link address]\n\nThanks,\n[user_name]\n[user_email]';
	var proc_body = filter_values(val);

  /* setup state for this compoenent **************************************** */
  	const defaultEmailForm = {
		to: '',
		from: config_settings?.from ?? 'Job App Admin <admin@jobinfo.app>',
		subject: proc_subject,
		body: proc_body,
		uuid: null,
		opt_out: 0
	}
  const [ isSending, setSending ]        = useState(false);
  const [ formStatus, setFormStatus ]    = useState('new');

  const [ readonly, setReadOnly ]        = useState(false);
  const [ id, setJobID ]                 = useState(prop.id);
  const [ email_instr, setInstructions ] = useState(false);
  const [ emailModal, toggleEmailModal ] = useState(false);
  const [ email, setEmail ]              = useState(defaultEmailForm);
  const [ email_history, setEmailList ]  = useState([ ...prop.email_list ]);

  useEffect(() => {
    setEmailList([ ...prop.email_list ]);
  }, [prop.email_list])

  /* open/close modal and instruction section ******************************* */
  const openCloseModal = () => {
    toggleEmailModal(!emailModal);
  }
  const openCloseInstr = () => setInstructions(!email_instr);

  /* handle field changes *************************************************** */
  const handleChange = (event) => {
    const { type, name, value, checked } = event.target;

    setFormStatus('edit');
	if ( type == "checkbox" ) {
		setEmail({ ...email, [name]: checked ? 0 : 1 });
	} else {
		setEmail({ ...email, [name]: value });
	}
    
  }

  /* change state of email form ********************************************* */
  const cancelEmail = () => {
    setEmail(defaultEmailForm);
    setFormStatus('new');
    toggleEmailModal(false);
  }

  const handleSubmit = () => {
    
    // validate user-entered emails with regex
    var emails = email.to.split(',');

    var error_found = false;
    if ( emails.length > 0 ) {
      for (let i=0; i<emails.length; i++) {
        // console.log('checking email [' +i+ ']:', emails[i])
        if ( !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test( emails[i].trim() )) {
          error_found = true;
          break;
        }
      }

      if ( error_found ) {
        alert('Error in email(s) entered. Please check input and try again.');
        return false;
      } else {

        setSending(true);
        let data = { id, ...email };
        email_external(data).then(oResp => {
          setSending(false);
          if ( oResp.status == "complete" ) {
            oResp.results.forEach(result => {
              prop.addNew(result.email);
            })
            setFormStatus('sent');
          } else {
            alert('Error sending email: ' + oResp.error)
          }
        })
		
      }
    }

  }

  /* open/view previously-sent emails *************************************** */
  const compose_new = () => {
    setReadOnly(false);
    openCloseModal();
  }
  
	const viewEmail = (id) => {
		var chosen_email = email_history.find(email => email.id == id);
		setFormStatus(chosen_email.status);
		setEmail({
			id,
			to: chosen_email.to,
			subject: chosen_email.subject,
			body: chosen_email.body,
			uuid: chosen_email.uuid ?? null
		})
		setReadOnly(true);
		openCloseModal();
	}

	const optOutEmail = (id) => {
		
		console.log('opting out email', id);
		setEmailList(email_history.map((em) => {
			if (em.id === id) {
			  return { ...em, opt_out: !em.opt_out };
			} else {
			  return em;
			}
		}))

	}

  return (
    <div>
      <Modal isOpen={emailModal} toggle={openCloseModal}>
        <Form onSubmit={handleSubmit}>
          <ModalHeader toggle={openCloseModal}>
            {!readonly ? (
              <Fragment>
                Compose Email
                <i className="far fa-question-circle mx-3" style={{ cursor: 'pointer' }} title="Click for Instructions" onClick={openCloseInstr}></i>
              </Fragment>
            ) : (
              <Fragment>
                View Email
              </Fragment>
            )}
          </ModalHeader>
          <ModalBody>
            {email_instr && (
              <div className="my-3">
                <p>To send a customized email including a link to this job, follow the instructions below:</p>
                <ol className="share_instructions" style={{ paddingLeft: '5px' }}>
                  <li>
                    Enter your intended Recipient email(s) into the field labeled "To:"<br />
                    <cite style={{ fontSize: '.9rem' }}>Separate emails with a comma<br />(ex. email@company.com, email2@company.com)</cite>
                  </li>
                  <li>
                    Update the Subject and/or Body.<br />
                    <cite style={{ fontSize: '.9rem' }}>Note: The [link address] text denotes where the custom job link will be located in the email message body</cite>
                  </li>
                  <li>Click the "Send Email" button</li>
                </ol>
              </div>
            )}
            <div className="shareForm">
              <div className="form-row">
                <div className="col-md">

                  <label htmlFor="to">
                    To: <span className="required">*</span>{' '}
                  </label>
                  {!readonly ? (
                    <Fragment>
                      <input
                        autoFocus={true}
                        name="to"
                        onChange={handleChange}
                        type="email"
                        value={email.to}
                        className="form-control"
                        placeholder="Recipients"
                        required
                        multiple
                      />
                      <div className="input--description">
                        Separate email addresses with a comma
                      </div>
                      <div className="invalid-feedback" />
                    </Fragment>
                  ) : (
                    <span>{email.to}</span>
                  )}

                </div>
              </div>
              <div className="form-row mt-3">
                <div className="col-md">
                  <label>From:</label>
                  	<span>
						{user.memberships[0].company?.id == 93 ? (
							<code>Blue Diamond Materials &lt;BlueDiamond@jobinfo.app&gt;</code>
						) : (
							<code>Job App Admin &lt;admin@jobinfo.app&gt;</code>
						)}
					</span>
                </div>
              </div>
              <div className="form-row mt-3">
                <div className="col-md">
                  <Input
                    autoFocus={true}
                    name="subject"
                    onChange={handleChange}
                    label="Subject:"
                    type="text"
                    value={email.subject}
                    isRequired={1}
                    readOnly={readonly}
					maxLength={200}
                  />
                </div>
              </div>
              <div className="form-row mt-3">
                <div className="col-md">
                  <Textarea
                    onChange={handleChange}
                    name="body"
                    value={email.body}
                    label="Body:"
                    rows={9}
                    readOnly={readonly}
                  />
                </div>
              </div>
			  {config_settings.send_reminders && (formStatus == 'new' || formStatus == 'edit') && (
					<div className="form-row mt-3">
						<div className="col-md">
							<input
								type="checkbox"
								name="opt_out"
								value={1}
								checked={!email.opt_out}
								onChange={handleChange}
							/>
							<label htmlFor="opt_out" className="d-inline pl-3">Send Reminder Emails</label>
						</div>
					</div>
				)}
              {!readonly && (
                <div className="mt-3">
                  <cite style={{ fontSize: '.9rem' }}>
				  	<i className="fas fa-exclamation-circle mr-2"></i>The following merge fields can be included in the Subject and/or Body:
					<ul>
						<li>[link address] - custom link to this job</li>
						<li>[job number]</li>
						<li>[customer name]</li>
						<li>[project name]</li>
					</ul>
                  </cite>
                </div>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            {!readonly ? (
              <Fragment>
                <Button color="danger" size="sm" onClick={cancelEmail} style={{ position: 'absolute', left: 0, marginLeft: '20px' }}>
                  Cancel
                </Button>
                {formStatus == 'sent' && (
                  <i className="far fa-check-circle"></i>
                )}

                {(email.to.trim() == '' || email.subject.trim() == '' || email.body.trim() == '' || isSending || formStatus == 'sent') ? (
                  <Button type="submit" color="secondary" size="sm" disabled={true} outline>
                    {isSending ? 'Sending...' : 'Send Email'}
                  </Button>
                ) : (
                  <Button type="submit" color="success" size="sm" disabled={false}>
                    Send Email
                  </Button>
                )}

              </Fragment>
            ) :(
				<Button color="danger" size="sm" onClick={cancelEmail}>
					Close
				</Button>
            )}            
          </ModalFooter>
        </Form>
      </Modal>

      <h3 style={{ padding: '10px 20px', margin: '10px 0', backgroundColor: '#EAEAEA' }}>
        Email Job Link
        <Button color="primary" size="sm" onClick={compose_new} className="ml-3">Compose</Button>
      </h3>

      
        <Row>
          <Col>
            
            {email_history.length > 0 ? (
              <div className="historyList">
                <h5>Email History</h5>
                <Table responsive size="sm">
                  <thead>
                    <tr>
                      <th>Sent</th>
                      <th>Details</th>
                      <th>Status</th>
					  	{config_settings.send_reminders && (
							<>
								<th>
									Reminders
									{/* <i className="far fa-question-circle ml-1" style={{ cursor: 'pointer' }} 
										title="Uncheck to opt-out of sending reminders" 
										onClick={() => console.log('show config settings')}></i> */}
								</th>
								{/* <th>
									Info
								</th> */}
							</>
						)}
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
					{email_history.map(email_log => 
						<EmailRecord 
							key={email_log.id} 
							email={email_log} 
							updateRec={prop.updateRec} 
							openEmail={(id) => viewEmail(id)}
							config={config_settings}
							showOptOut={config_settings.send_reminders}
							optOut={(id) => optOutEmail(id)} />
					)}
                  </tbody>
                </Table>
              </div>
            ) : (
              <div style={{ border: 'dotted 1px #CACACA', padding: '20px', margin: '20px' }}>
                Click the "Compose" button above, to prepare and send an email containing a job link.
              </div>
            )}
          </Col>
        </Row>
      
    </div>
  )
}

export default EmailLinkHistory;