import React, { useState, useEffect, useContext } from 'react';
import { JobAppContext } from '../../GlobalState';

export const ContactField = ({
	value = "0",
	change,
	readOnly
}) => {
	
	const { contacts, loadingContacts, update_locations } = useContext(JobAppContext);

	const [ selectedContact, setSelectedContact ] = useState(value);
	const [ contact_label, setContactLabel ] = useState('');

	// handle changes made to contacts/contact
	useEffect(() => {
		if ( selectedContact ) {
			updateContactLabel(selectedContact);
		}
	}, [contacts])

	useEffect(() => {
		if ( selectedContact ) {
			updateContactLabel(selectedContact);
			if ( selectedContact !== 0 ) {
				update_locations(selectedContact);
			}
		}
	}, [selectedContact]);

	useEffect(() => {
		setSelectedContact(value);
	}, [value])

	const updateContactLabel = (cnt_id) => {
		var cont_label = '';
		if ( cnt_id == 0 ) {
			cont_label = '< No Contact Selected >';
		} else {
			let found = contacts.find(cont => cont.CNT_ID == cnt_id);
			cont_label = found ? found.CONTACT : 'Description Not Found (' +cnt_id+ ')';
		}
		setContactLabel(cont_label);
	}

	const onChange = (e) => {
		setSelectedContact(e.target.value);
		change(e);
	}

	return (
		<div className="form-row mb-3">
			<div className="col">
				{loadingContacts ? (
					<>
						<i className="fas fa-sync-alt fa-spin mr-1" ></i> 
						Loading Contacts
					</>
				) : (
					<div className="Input">
						<label>
							Contact
							<i className="far fa-question-circle ml-2" title="This is the user who will be the contact for this project in OLS"></i>
						</label>
						{readOnly ? (
							<span>{contact_label}</span>
						) : (
							<select
								name="selectedContact"
								value={selectedContact}
								className="selectInput form-control"
								onChange={e => onChange(e)}
							>
								<option value="0">{'< No Contact Set >'}</option>
								{contacts?.map((contact, index) => (
									<option key={index} value={contact.CNT_ID}>
										{contact.CONTACT}
									</option>
								))}
							</select>
						)}
					</div>
				)}
			</div>
		</div>
	)
}