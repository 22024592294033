import React from 'react'
import { classnames } from '../../utils/classnames'

export const Textarea = ({
	onChange,
	name,
	value = '',
	className,
	onBlur,
  label = '',
  rows=1,
  readOnly = false
}) => (
	<div className="Input">
		{label && <div className="Input--label">{label}</div>}
    {readOnly ? (
      <textarea
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        className={classnames('form-control', className)}
        rows={rows}
        readOnly
      />
    ) : (
      <textarea
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        className={classnames('form-control', className)}
        rows={rows}
      />
    )}
    
	</div>
)
