import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Modal, ModalHeader, ModalBody, ModalFooter, Table, Button } from 'reactstrap'

import { can } from '../../utils/can'
import { get_user } from '../../user_info';

import { Form, Input } from '../../components'
import { getCompanies, createCompany } from '../../api'

const AdminPage = (props) => {
	
	const [ companies, setCompanies ] = useState([]);
	const [ addCompanyModal, setAddCompanyModal ] = useState(false);
	const [ company, setCompany ] = useState('');
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isSaving, setIsSaving ] = useState(false);

	useEffect(() => {
		// user must have the "super" admin permission, to access
		const user = get_user();
		if ( !can(['super'], user) ) {
			props.history.push('/jobs')
		}
		getCompanies().then(data => {
			setCompanies([ ...data ]);
			setIsLoading(false);
		})
	}, [])

	const handleChange = event => {
		const { value } = event.target
		setCompany(value);
	}

	const handleSubmit = () => {
		setIsSaving(true)
		createCompany({ company }).then(data => {
			setIsSaving(false);
			setAddCompanyModal(false);
			setCompany('');
			setCompanies([
				...companies,
				data
			])
		});
	}

	const openCompanyModal = () => {
		setAddCompanyModal(true)
	}

	const closeCompanyModal = () => {
		setCompany('');
		setAddCompanyModal(false);
	}

	const toggleModal = () => {
		addCompanyModal ? closeCompanyModal() : openCompanyModal();
	}

    return (
      <div>

        <Modal isOpen={addCompanyModal} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Add Company</ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit}>
              <div className="form-group">
                <div className="form-row">
                  <div className="col">
                    <Input
                      label="Company Name"
                      name="company"
                      onChange={handleChange}
                      type="text"
                      value={company}
                      placeholder="New Company Name"
                    />
                  </div>
                </div>
              </div>
              <div className="my-3">
                <p>&#42; Creating a company will also create the following configuration</p>
                <ul style={{ fontSize: '.75rem' }}>
                  <li>Roles: Admin, Standard User</li>
                  <li>Workflow step for the Standard User</li>
                  <li>Role Permissions: Admin (all), Standard User (Projects and Project History)</li>
                  <li>A generic "Admin" user (ex. admin@[company-name].com)</li>
                </ul>
              </div>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button className="mr-2" color="primary" disabled={isSaving}
							      onClick={() => handleSubmit()}>
				{isSaving ? `Saving...` : `Create Company`}
			</Button>
            <Button onClick={closeCompanyModal}>Cancel</Button>
          </ModalFooter>
        </Modal>

        <h4 className="Title">Administration Page</h4>

        <hr />

        <div style={{ paddingLeft: '20px' }}>
              <h5 className="mb-3">
                Companies
                <Button className="ml-3" onClick={openCompanyModal}>Add</Button>
              </h5>

              {isLoading ? (
                <div><i className="fas fa-sync-alt fa-spin mr-1" ></i> Loading Company Information</div>
              ) : (
                companies.length && (
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Company Name</th>
						<th>Pages</th>
                        <th>Status</th>
                        <th>Contact User</th>
                        <th>Users</th>
                        <th>Jobs</th>
                      </tr>
                    </thead>
                    <tbody>
                      {companies.map( (company, index) => {
                        return (
							<tr key={index}>
								<td>{company.id}</td>
								<td>{company.name}</td>
								<td>
									<Link to={"/admin/company/" + company.id} className="mx-2">Settings</Link>
									<Link to={"/admin/users/" + company.id} className="mx-2">Users</Link>
									<Link to={"/admin/forms/" + company.id} className="mx-2">Forms</Link>
								</td>
								<td>{company.status}</td>
								<td>{company.contact_id}</td>
								<td>{company.memberships.length}</td>
								<td>{company.jobs.length}</td>
							</tr>
						);
                      })}
                    </tbody>
                  </Table>
                )
              )}          
        </div>

        <hr />

      </div>
    )

}
export default AdminPage;