import React from 'react'

import '../../assets/css/styles.css'

export const Checkbox = ({ label, name, onChange, defaultChecked, value }) => (
	<div className="checkbox">
		<label>
			<input name={name} onChange={onChange} type="checkbox" defaultChecked={defaultChecked} checked={value} />
			{label}
		</label>
	</div>
)
