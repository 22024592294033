import React, { useState } from 'react';

import moment from 'moment';
import {
  updateHistory
} from '../../api';

import { Button } from 'reactstrap';

const SharedHistory = ( prop ) => {

  const [ oHistory, setHistory ] = useState( prop.history );
  const [ job_form, setForm ] = useState( prop.form );
  const [ copied, setCopied ] = useState( false );

  const handleUpdateHistory = () => {
    updateHistory({
      id: oHistory.id,
      status: oHistory.status === 'open' ? 'closed' : 'open',
    }).then(data => {
      
      // console.log('history link updated', data)
  
    }, error => console.error(error))

    var new_status = oHistory.status === 'open' ? 'closed' : 'open';
    setHistory({ ...oHistory, status: new_status });
  }

  const showModal = (label) => {
    var full_url = '';
    if ( oHistory.url.indexOf('link/') >= 0 ) {
      full_url = `${window.app_url}` + "/link/" + oHistory.uuid;
    } else {
      full_url = oHistory.url;
    }
    prop.toggleModal(label, full_url);
  }

  const copyLink = () => {
    let textArea = document.createElement('textarea');
		textArea.setAttribute('style','width:1px;border:0;opacity:0;');
		document.body.appendChild(textArea);
    if ( oHistory.url.indexOf('link/') >= 0 ) {
      textArea.value = `${window.app_url}` + "/link/" + oHistory.uuid;
    } else {
      textArea.value = oHistory.url;
    }
		textArea.select();
		textArea.focus();
		document.execCommand('copy');
    document.body.removeChild(textArea);
    
    setCopied(true);
  }

  return (
    <tr className={oHistory.status}>
      <td>{moment( oHistory.created_at ).format('MM/DD/YYYY')}</td>
      <td>{oHistory.shareNote}</td>
      <td>
        {oHistory.sections.length === job_form.sections.length && (
          <span className="historySectionTitle">All Sections</span>
        )}
        {oHistory.sections.length !== job_form.sections.length && oHistory.sections.map((section, index) => (
          <span key={section.id} className="historySectionTitle">
            {section.title}
          </span>
        ))}
      </td>
      <td>{Math.floor(oHistory.progress)}%</td>
      <td>
        <input type="hidden"
          name={"formLink_" + oHistory.id}
          value={`${window.app_url}` + "/link/" + oHistory.uuid}
          readOnly={true}
        />
        <Button onClick={() => showModal(oHistory.shareNote)} disabled={oHistory.status != 'open' ? true : false}>
          <i className="far fa-folder-open font-weight-bold" title="Show"></i>
        </Button>&nbsp;&nbsp;
        <Button onClick={() => copyLink()} disabled={oHistory.status != 'open' ? true : false}>
          {copied ? (
            <i className="far fa-check-circle font-weight-bold" title="Copied"></i>
          ) : (
            <i className="far fa-copy font-weight-bold" title="Copy"></i>
          )}
           
        </Button>
        <Button onClick={() => handleUpdateHistory()} className="ml-3">
          {oHistory.status != 'open' ? 'Enable' : 'Disable'}
        </Button>
        {oHistory.status == 'complete' && (
          <i className="far fa-check-circle" style={{ color: 'green', fontSize: '24px', fontWeight: 'bold', marginLeft: '10px', verticalAlign: 'middle' }}
              title={"Finalized on " + moment(oHistory.updated_at).format('L')}></i>
        )}
      </td>
    </tr>
  )
}

export default SharedHistory;