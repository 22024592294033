import React, { useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { withTransaction } from '@elastic/apm-rum-react'

import { JobAppContext } from '../../GlobalState'
import SignInForm from '../../components/partials/SignInForm'
import { Jumbotron, Row, Col } from 'reactstrap'
import { Footer } from '../layout/Footer'

const SignInPage = (props) => {

	const { isAuthenticated } = useContext(JobAppContext);
	const history = useHistory();

	useEffect(() => {
		if (isAuthenticated === true) {
			history.push('/jobs')
		}
	}, [isAuthenticated])

	return (
		<div style={{ maxWidth: '800px', margin: '40px auto' }}>
			<div style={{ backgroundColor: '#20213b', color: '#FFFFFF', borderRadius: '20px 20px 0 0' }} className="py-1">
				<h4 className="Title">
					<div className="headerLogo text-center">
						<img src="/NCS_Logo.png" alt="NCS Job App" style={{ maxWidth: '100%', maxHeight: '125px' }} />
					</div>
				</h4>
			</div>
			<Row>
				<Col>
					<div className="my-3 mx-0 p-5">
						<SignInForm />
					</div>
				</Col>
			</Row>
			<Footer />
		</div>
	)
}
export default withTransaction('SignInPage', 'component')(SignInPage);
