import React, { Fragment, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { classnames } from '../../utils/classnames'

import {
	saveSectionOrder,
	getFormSchema,
	uploadLogo,
	updateFormData,
} from '../../api';

import {
	Modal, ModalHeader, ModalBody, ModalFooter, Button, Table
} from 'reactstrap'

import { Form, Input, Textarea } from '../partials'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Form_Variant } from '../Form/Form_Variant'
import Section from '../Form/Section'

/*
const getItemStyle = (isDragging, draggableStyle) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: 'none',

	// change background colour if dragging
	background: isDragging ? 'lightgreen' : 'grey',

	// styles we need to apply on draggables
	...draggableStyle,
})
*/

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list)
	const [removed] = result.splice(startIndex, 1)
	result.splice(endIndex, 0, removed)

	return result
}

const form_config_options = [
	{ "name": "project_name", 	"label": "Project Name", 			"active": true, 	"req": true  },
	{ "name": "reference_no", 	"label": "Customer Reference #", 	"active": false, 	"req": false }
	/*
	{ "name": "first_furnishing", 	"label": "First Furnishing", 		"active": false, 	"req": false },
	{ "name": "last_furnishing", 	"label": "Last Furnishing Date", 	"active": false, 	"req": false },
	{ "name": "total_contract_amt", "label": "Total Contract Amount",	"active": false, 	"req": false },
	{ "name": "total_claim_amt", 	"label": "Total Claim Amount", 		"active": false, 	"req": false }
	*/
]

const FormBuilder = (props) => {

	const history = useHistory();

	const [ draggedItemIndex, setDraggedItemIndex ] = useState(null);
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isSaving, setIsSaving ] = useState(false);
	const [ isUploading, setIsUploading ] = useState(false);

	const [ id, setId ] = useState(null);
	const [ name, setName ] = useState('');
	const [ title, setTitle ] = useState('');
	const [ message, setMessage ] = useState('');
	const [ mapped, setMapped ] = useState(0);
	const [ status, setStatus ] = useState('');
	const [ footnote, setFootnote ] = useState('');

	const [ config, setConfig ] = useState({
		"fields": [
			{ "name": "project_name", "label": "Project Name", "active": true, "req": true }
		], "allow_none": true
	});

	const [ sections, setSections ] = useState(null);

	const [ modal, setModal ] = useState(false);
	/*
	const [ delete, setDelete ] = useState({
		sections: [],
		fields: []
	});
	*/

	const [ form_variant, setFormVariant ] = useState(null);

	const [ variant_modal, setVariantModal ] = useState(false);
	const [ variant_id, setVariantId ] = useState(0);
	const [ variant_title, setVariantTitle ] = useState('');
	const [ variant_uuid, setVariantUuid ] = useState('');
	const [ variant_logo, setVariantLogo ] = useState('');
	const [ file_id, setFileId ] = useState(0);
	const [ files, setFiles ] = useState([]);

	const [ editUuid, setEditUuid ] = useState(false);
	const [ tempUuid, setTempUuid ] = useState('');

	useEffect(() => {
		if (props.match.params.id) {
			getFormSchema({ id: props.match.params.id }, localStorage.getItem('accessToken'))
			.then(data => {
				var new_config_flds = config;
				if ( data.config != null && Object.entries(data.config).length > 0 ) {
					new_config_flds = JSON.parse(data.config);
				}
				setId(data.id);
				setName(data.name);
				setTitle(data.title);
				setMessage(data.message);
				setMapped(data.mapped);
				setStatus(data.status);
				setFootnote(data.footnote);
				setSections([ ...data?.sections ]);
				setFormVariant([ ...data?.form_variant ]);
				setConfig(new_config_flds);
				
				/*
				this.setState({
					...data,
					config: new_config_flds
				})
				*/
			})
			.catch(error => console.error(error))
		} else {
			history.push('/forms')
		}
	}, [])

  /*
  actually do the upload / response, called from:
    -> attachFile (click and select file(s) from browse window)
  
  initiateUpload = ( formData ) => {
    
    uploadAttachment(formData)
      .then(data => data.json())
      .then(data => {
        
        // Clear "file" input element
        document.getElementById('attachFile').value = ''

        if ( data['error'] ) {
          this.setState(prevState => ({
            isUploading: false,
            filesUploaded: true,
            messageStatus: 'danger',
            uploadResult: "Error: " +data['error'],
            attachments: [...data, ...prevState.attachments],
          }))
        } else {
          // Update state value of attachments, to show "completed" uploads
          this.setState(prevState => ({
            isUploading: false,
            filesUploaded: true,
            messageStatus: 'success',
            uploadResult: 'Upload completed successfully!',
            attachments: [...data, ...prevState.attachments],
          }))
        }
      })

  }

  handleFileSubmit = accepted => {
    let formData = new FormData()
    formData.append('file', accepted[0])
    formData.append('form_id', this.state.id)

    uploadFile(formData)
      .then(data => data.json())
      .then(data => {
        this.setState(prevState => ({
          files: [data, ...prevState.files],
        }))
      })
  }

  handleFileRemove = removeVariant => {
    deleteVariant(removeFile).then(data => {
      this.setState(prevState => ({
        files: prevState.files.filter(file => {
          return file.id !== removeFile
        }),
      }))
      console.log(data)
    })
  }
  */

	/* ***************************************************************************
	General Functions
	*************************************************************************** */
	const selectFile = () => {
		document.getElementById('attachFile').click();
	}

	const toggleModal = () => {
		setModal(true);
	}

	const toggleVariant = () => {
		setVariantModal(!variant_modal);
		setVariantId(0);
		setVariantTitle('');
		setVariantUuid('');
		setTempUuid('');
		setVariantLogo('');
		setFileId(0);
		setFiles([]);
		setEditUuid(false);
	}

	const handleConfigChange = event => {
		const { target } = event
		const { name, value, type, checked } = target

		var field_name = name;
		if ( type == 'checkbox' ) {
			var field_name = name.substring(0, name.length - 4);
		}

		// handle "allow none" checkbox option
		if ( field_name == 'allow_none' ) {
			setConfig({
				fields: [ ...config.fields ],
				allow_none: checked
			})
			return;
		}

		// handle all field configuration options for form setup
		var fldIndex = -1;
		var field = config?.fields.find(fld => fld.name === field_name);
		if ( !field ) {
			field = form_config_options.find(opt => opt.name == field_name)
		} else {
			fldIndex = config.fields.findIndex(fld => fld.name === field_name);
		}

		if ( type == 'checkbox' ) {

			var field_type = name.substr(name.length - 4);
			if ( checked ) {
				if ( field_type == '_act' ) field.active = true;
				if ( field_type == '_req' ) field.req = true;
			} else {
				if ( field_type == '_act' ) field.active = false;
				if ( field_type == '_req' ) field.req = false;
			}

		} else {
			field.label = value;
		}

		var new_fields = [ ...config.fields ];
		if ( fldIndex >= 0 ) {
			new_fields[fldIndex] = field;
		} else {
			new_fields.push(field);
		}
		setConfig({
			...config,
			fields: [
				...new_fields
			]
		})
	}

	/* ***************************************************************************
	Form Actions
	*************************************************************************** */
	const handleSave = () => {
		setIsSaving(true)
		updateFormData({
			type: 'message', message, name, status, mapped, form_id: id, config, footnote
		}).then(() => {
			setIsSaving(false);
		})
	}

	/* ***************************************************************************
	Form Variant (Logo) Actions
	*************************************************************************** */
	const handleFileChange = event => {
		const { target } = event
		const { files } = target

		if ( files.length > 0 ) {
			setIsUploading(true);
			setVariantLogo(URL.createObjectURL(files[0]));
			setFiles([ ...files ]);
		} else {
			setVariantLogo('');
			setFileId(0);
			setFiles([]);
		}
	}

	const createUpdateVariant = () => {

		let formData = new FormData()
		if ( files.length != 0 ) {
			formData.append('file', files[0]);
		}
		formData.append('title', variant_title);
		formData.append('uuid', variant_uuid);
		formData.append('form_id', id);
		formData.append('file_id', file_id);
		formData.append('variant_id', variant_id);

		setEditUuid(false);
		uploadLogo(formData).then(data => data.json())
			.then(data => {

				var found = false;
				var new_variant_list = [];
				for( var i=0; i<form_variant.length; i++) {
					let variant = form_variant[i];
					if ( variant.id == data.id ) {
						new_variant_list.push(data); found = true;
					} else {
						new_variant_list.push(variant);
					}
				}
				if ( !found ) {
					new_variant_list.push(data);
				}
				
				setFormVariant([
					...new_variant_list
				]);
				setVariantModal(false);
				setVariantId(0);
				setVariantTitle('');
				setVariantUuid('');
				setTempUuid('');
				setVariantLogo('');
				setFiles([]);
				setIsUploading(false);
				
			});
	}

	/* ***************************************************************************
	Section Actions
	*************************************************************************** */
	const handlePushedChanges = (data, key) => {
		if (data.title.length) {
			updateFormData({
				type: 'section',
				section: data,
				form_id: id,
			}).then(response => {
				
				setSections(
					sections.map((section, index) => {
						if (index === key) {
							return {
								...section,
								id: response.id,
								title: response.title,
							}
						}
						return section
					})
				)
				/*
				this.setState(prevState => ({
					sections: prevState.sections.map((section, index) => {
						if (index === key) {
							return {
								...section,
								id: response.id,
								title: response.title,
							}
						}
						return section
					}),
				}))
				*/
			})
		}
	}

	const createSection = () => {
		var next_seq = sections.length;
		updateFormData({
			type: 'section',
			section: {
				id: 'section_1',
				title,
				order: next_seq
			},
			form_id: id,
		}).then(data => {
			// console.log(data)
			setSections([...sections, data]);
			setModal(false);
			setTitle('');
		})
	}

	// 
	const onDragEnd = result => {
		if (!result.destination) {
			return
		}
		const sections = reorder(
			sections,
			result.source.index,
			result.destination.index
		)
		saveSectionOrder({ sections: sections }).then(data => console.log(data))
		setSections(sections);
	}

	const handleRemoveSection = data => {
		// console.log('handleRemoveSection', data);
		if ( confirm('Are you sure you want to delete this section?') ) {
			updateFormData({
				type: 'delete_section',
				section: data,
				form_id: id,
			}).then(response => console.log(response))
	
			setSections(sections.filter(
				(section, index) => data.id !== section.id
			))
		}
	}

	const clearUuidChanges = () => {
		setVariantUuid(tempUuid);
		setTempUuid('');
		setEditUuid(false);
	}
	const changeUuid = () => {
		setEditUuid(true);
		setTempUuid(variant_uuid);
	}

	/* ************************************************************************ */
	return (
		<div className="p-4">

			<div className="border rounded d-flex">
				<div className="p-3" style={{ borderRight: 'solid 1px #CACACA' }}>
					<div className="form-row">
						<div className="col">
							<Input
								label="Name"
								value={name}
								onChange={(e) => { setName(e.target.value) }}
								placeholder="Form Title"
								name="name"
							/>
						</div>
					</div>
					<div className="form-row">
						<div className="col">
							<label form="status">Status</label>
							<select name="status" value={status} onChange={(e) => { setStatus(e.target.value) }} className="form-control">
								<option value="pending">Pending</option>
								<option value="active">Active</option>
							</select>
						</div>
						<div className="col">
							<label form="mapped">Mapped</label>
							<select name="mapped" value={mapped} onChange={(e) => { setMapped(e.target.value) }} className="form-control">
								<option value={1}>Yes</option>
								<option value={0}>No</option>
							</select>
						</div>
					</div>
					<div className="form-row">
						<div className="col">
							<Textarea
								value={message || ''}
								className="formBuilderMessage"
								onChange={(e) => { setMessage(e.target.value) }}
								label="Message"
								name="message"
							/>
						</div>
					</div>
					<div className="form-row">
						<div className="col">
							<Textarea
								value={footnote || ''}
								className="formBuilderMessage"
								onChange={(e) => { setFootnote(e.target.value) }}
								label="Footnote"
								name="footnote"
							/>
						</div>
					</div>

					<div className="form-row">
						<div className="col">
							{form_config_options.map((dconfig, idx) => {
								let found;
								found = config?.fields?.find(fc => fc.name == dconfig.name) || [];
								return (
									<div key={idx} className="input-group my-3">
										<div className="input-group-prepend">
											<div className="input-group-text">
												<input type="checkbox" name={dconfig?.name + "_act"} checked={found?.active || false} onChange={handleConfigChange} title="active" className="mr-3" />
												<input type="checkbox" name={dconfig?.name + "_req"} checked={found?.req || false} onChange={handleConfigChange} title="required" disabled={!found?.active} />
											</div>
										</div>
										<input type="text" name={dconfig?.name} value={found?.label || ''} className="form-control" placeholder={dconfig?.label} onChange={handleConfigChange} disabled={!found?.active} />
									</div>
								)
							})}
							<div>
								<div>
									<label htmlFor="allow_none_opt">Allow None/Unknown?</label>
									<input type="checkbox" name="allow_none_opt" checked={config?.allow_none} onChange={handleConfigChange} />
								</div>
							</div>
						</div>
					</div>

					<div className="form-row">
						<div className="col py-2 pb-sm-0">
							<Button className="Button--black" onClick={handleSave} disabled={isSaving}>
								{isSaving ? 'Saving...' : 'Save'}
							</Button>
							<cite style={{ fontSize: '.8em', display: 'block', marginTop: '10px' }}>
								* Note: To be available for use when adding jobs into the system, Forms must be marked "Active" and "Mapped".
							</cite>
						</div>
					</div>
				</div>

				<div className="p-3">
					<h3 className="my-3">
						Form Logo
						<Button onClick={() => setVariantModal(true)} color="secondary" size="sm" className="ml-3">
							Add New Logo
						</Button>
					</h3>

					{ !form_variant ? (
						<div>
							<i className="fas fa-sync-alt fa-spin mr-1" ></i> 
							Loading Form Logos
						</div>
					) : (
					
					form_variant.length > 0 ? (
						<Table responsive>
							<thead>
								<tr>
									<th>Logo</th>
									<th>Title</th>
									<th>
										New Job Link UUID
										<i className="fas fa-info-circle ml-2" title="jobinfo.app/new/[uuid]"></i>
									</th>
									<th>Created</th>
									<th>&nbsp;</th>
								</tr>
							</thead>
							<tbody>
								{form_variant.map((variant, index) => (
									<Form_Variant key={variant.id} variant={variant}  
										onEdit={() => {
											setVariantModal(true);
											setVariantId(variant.id);
											setVariantTitle(variant.title);
											setVariantUuid(variant.uuid);
											setTempUuid(variant.uuid);
											setVariantLogo(variant.file ? '/api/v1/' + variant.file.location : '');
											setFileId(variant.file_id);
										}}
										onDelete={() => setConfirmDelete(variant.id)}
									/>
								))}
							</tbody>
						</Table>
					) : (
						<span>Click the "Add New Form Logo" button above to upload a form logo, etc.</span>
					)

					)}

				</div>
			
			</div>
			
			<Modal isOpen={modal} toggle={toggleModal} className={props.className}>
				<Form onSubmit={createSection}>
					<ModalHeader toggle={toggleModal}>New Section</ModalHeader>
					<ModalBody>
						<p>Create a section, after creation you can add fields</p>
						<div>
							<Input
								label="Section Title"
								value={title}
								onChange={(e) => {setTitle(e.target.value)}}
								name="title"
								className="Input--wide"
							/>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button className="mr-2">Save</Button>
						<Button onClick={() => setModal(false)}>
							Cancel
						</Button>
					</ModalFooter>
				</Form>
			</Modal>

			<Modal isOpen={variant_modal} toggle={toggleVariant} className={props.className}>
				<Form onSubmit={createUpdateVariant}>
					<ModalHeader toggle={toggleVariant}>
						{(variant_id != 0 ? "Edit" : "Add New") + " Logo"}
					</ModalHeader>
					<ModalBody>
						<div className="mb-3">
							{variant_logo != '' ? (
								<Fragment>
									<i className="fas fa-times delete-file" title="Delete File"
										onClick={() => { setVariantLogo(''); setFileId(0); setFiles([]); }}
									></i>
									<img src={variant_logo} alt="form logo" style={{ maxWidth: '460px', border: 'solid 1px #000', marginTop: '-10px' }} />
								</Fragment>
							) : (
								<Fragment>
									<input type="file" name="attachFile" id="attachFile" onChange={handleFileChange} className="form-control-file" />
								</Fragment>
							)}
						</div>
						<div>
							<Input
								label="Title"
								value={variant_title}
								onChange={(e) => setVariantTitle(e.target.value)}
								name="variant_title"
								className="form-control mb-3"
							/>
						</div>
						{variant_id !== 0 && (
							<div>
								<label htmlFor="variant_uuid">
									Custom UUID for this Variant
									<i className="fas fa-info-circle ml-2" title="Unique identifier for this variant of the form"></i>
								</label>
								<input
									name="variant_uuid"
									value={variant_uuid}
									className="form-control"
									onChange={(e) => setVariantUuid(e.target.value)}
									readOnly={editUuid ? false : true}
									style={{ width: '90%', display: 'inline-block' }}
								/>
								{editUuid ? (
									<span className="fas fa-times ml-2" style={{ cursor: 'pointer' }} onClick={() => { clearUuidChanges() }} />
								) : (
									<span className="fas fa-pencil-alt ml-2" style={{ cursor: 'pointer' }} onClick={() => { changeUuid() }} />
								)}	
							</div>
						)}
					</ModalBody>
					<ModalFooter>
						<Button color="danger" onClick={toggleVariant}>
							Cancel
						</Button>
						<Button type="submit" color="primary" className="mr-2" disabled={variant_title == ''}>Save</Button>
					</ModalFooter>
				</Form>
			</Modal>


			<div className="row mt-4 mx-0">
				<div className="col-12 col-xl-10">
					<Button onClick={() => toggleModal()}>
						<i className="fas fa-plus mr-2"></i>
						Add Section
					</Button>
				</div>
			</div>

			<div className="row p-4">
				<div id="form-page" className="col-12 col-xl-10">

					<section>
						{ !sections ? (
							<div>
								<i className="fas fa-sync-alt fa-spin mr-1" ></i> 
								Loading Form Sections
							</div>
						) : (
							<DragDropContext onDragEnd={onDragEnd}>
								<Droppable droppableId="droppable" direction="vertical">
									{(provided, snapshot) => (
										<div ref={provided.innerRef} {...provided.droppableProps}>

											{sections.length !== 0 && sections.map((section, key) => (
												<Draggable 
													key={'section' +section.id} 
													draggableId={'section' +section.id} 
													index={key}
												>
													{(provided, snapshot) => (
														<div 
															className={classnames( 
																snapshot.isDragging && 'isDragging' 
															)}
															ref={provided.innerRef} 
															{...provided.draggableProps} 
															{...provided.dragHandleProps}
														>
															<Section
																key={section.id}
																section={section}
																formId={id}
																// onDragOver={this.handleOnDragOver}
																// onDragStart={this.handleOnDragStart}
																// onDrop={this.handleOnDrop}
																pushChanges={data => 
																	handlePushedChanges(data, key)
																}
																removeSection={data => 
																	handleRemoveSection(data)
																}
															/>
														</div>
													)}
												</Draggable>
											))}
										</div>
									)}
								</Droppable>
							</DragDropContext>
						)}
					</section>
				</div>
			</div>

			<div className="row mb-4 mx-0">
				<div className="col-12 col-xl-10">
					<Button onClick={() => toggleModal()}>
						<i className="fas fa-plus mr-2"></i>
						Add Section
					</Button>
				</div>
			</div>
		</div>
	)
}
export default FormBuilder;
