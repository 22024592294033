import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/styles.css'

import React from 'react'
import { render } from 'react-dom'

import Root from './Root'
// import registerServiceWorker from './registerServiceWorker'

import GlobalState from './GlobalState';

require('isomorphic-fetch');

window.AppVersion = '1.0.1'
render(
  <GlobalState>
      <Root />
  </GlobalState>,
document.getElementById('root'))

// registerServiceWorker()
