// Pagination Component for adding pagination to a list
// Example at bottom of page

import React,{ Component } from 'react'
import ScrollableContainer from "./ScrollableContainer";

export default class Pagination extends Component{
	constructor(props){
		super(props)

		this.myRef = React.createRef()
		this.scrollContainerRef = React.createRef()
		
		this.state = {
			start: 0,
			end:   props.view ? props.view - 1 : 99,
			view:  props.view ? props.view : 100,
			page:  1
		}
	}

	componentDidMount() {
		this.props.onRef && this.props.onRef(this)
	}
	componentWillUnmount() {
		this.props.onRef && this.props.onRef(undefined)
	}

	resetPage() {
		this.setState({
			start: 0,
			end: this.state.view - 1,
			page: 1
		})
	}

	renderComponent = () => {
		if(this.props.info.length < this.state.start) {
			this.setState({
				start: 0,
				end: this.state.view - 1,
				page: 1
			})
		}

		let render = [];
		for(let i = this.state.start; i <= this.state.end; i++) {
			const row = this.props.info[i]
			if(row) {
				render.push(row);
			}
		}
		const renderComponent = render.map((result, i) => (this.props.render(result, i)))

		if(this.props.table){
			return <React.Fragment>
				{renderComponent}
				{this.props.renderAfter}
				<tr><td colSpan={'100%'}>{this.buttons()}</td></tr>
			</React.Fragment>
		}
		else if(this.props.scrollableContainer){
			return <ScrollableContainer ref={this.scrollContainerRef}>
				<div ref={this.myRef}/>
				{renderComponent}
				{this.buttons()}
				{this.props.renderAfter}
			</ScrollableContainer>
		}
		else{
			return <React.Fragment>
				<div ref={this.myRef}/>
				{renderComponent}
				{this.props.renderAfter}
				{this.buttons()}
			</React.Fragment>
		}
	}
	start = () => {
		this.setState({
			start:0,
			end:this.state.view - 1,
			page:1
		})

		this.scroll()
	}
	end = () => {
		this.setState({
			start: this.state.view * this.avg(),
			end: this.props.info.length,
			page: this.avg() + 1
		})

		this.scroll()
	}
	next = () => {
		const end = this.state.end+this.state.view
		this.setState(prevState=>({
			start:this.state.start+this.state.view,
			end: end > this.props.info.length ? this.props.info.length : end,
			page:prevState.page+1
		}))

		this.scroll()
	}
	previous = () => {
		let end = this.state.end - this.state.view
		let start = this.state.start - this.state.view
		if(this.state.end === this.props.info.length){
			// console.log('same as end',this.state.end,this.props.info.length)
			end = this.avg() * this.state.view - 1
			start = this.avg() * this.state.view - this.state.view
		}
		// console.log('start',start,'end',end)
		this.setState(prevState=>({
			start,
			end,
			page:prevState.page-1
		}))

		this.scroll()
	}
	avg(){
		return Math.floor(this.props.info.length/this.state.view)
	}
	pages(){
		const totalPages = this.avg()+1
		let pages = []
		for(let i = 1;i<=totalPages;i++){
			pages.push(<option value={i} key={'pageOption'+i}>{i}</option>)
		}
		return pages;
	}
	selectPage(page){
		this.setState({
			page,
			start:(page-1)*this.state.view,
			end:page*this.state.view>this.state.length?this.state.length:page*this.state.view-1
		})

		this.scroll()
	}
	scroll(){
		// console.log('scrolling',this.scrollContainerRef.current)
		if(this.props.scrollableContainer) {
			const scrollId = this.scrollContainerRef.current.state.id
			document.getElementById(scrollId).scrollTo(0, this.myRef.current.offsetTop)
		}
		else if(this.myRef.current){
			window.scrollTo(0,this.myRef.current.offsetTop)
		}
	}
	buttons = () => {
		return this.props.info.length > 0 && <div className="form-inline">
			<div className="btn-group">
				<button className={"btn btn-sm btn-default"} disabled={this.state.start <= 0}
						onClick={this.start}>
					<span className="fas fa-arrow-to-left"/>
				</button>
				&nbsp;
				<button className={"btn btn-sm btn-default"} disabled={this.state.start <= 0}
						onClick={this.previous}>
					<span className="fas fa-arrow-left"/>
				</button>
			</div>
			&nbsp;
			{this.state.start + 1} - {this.state.end >= this.props.info.length ? this.props.info.length : this.state.end+1} ({this.props.info.length})&nbsp;
			<div className="btn-group">
				<button className={"btn btn-sm btn-default"} disabled={(this.state.end+1) >= this.props.info.length}
						onClick={this.next}>
					<span className="fas fa-arrow-right"/>
				</button>
				&nbsp;
				<button className={"btn btn-sm btn-default"} disabled={(this.state.end+1) >= this.props.info.length}
						onClick={this.end}>
					<span className="fas fa-arrow-to-right"/>
				</button>
			</div>
			&nbsp;
			<select
				className="form-control input-sm"
				onChange={(e) => {
					let view = Number(e.target.value);
					this.setState({ view, start: 0, end: view - 1, page: 1 })
					this.scroll()
				}}
				disabled={this.props.info.length < 100}
				value={this.state.view}
			>
				<option value={100}>100</option>
				<option value={500}>500</option>
				<option value={1000}>1000</option>
			</select>
			&nbsp;
			<select
				className="form-control input-sm"
				onChange={(e) => {this.selectPage(e.target.value)}}
				disabled={this.avg() !== 1}
				value={this.state.page}
			>
				{this.pages()}
			</select>
		</div>
	}

	render(){
		return(
			<React.Fragment>
				{this.renderComponent()}
			</React.Fragment>
		)
	}
}




// EXAMPLE

class Example extends Component {
	resetPagination() {
		this.pagination.resetPage();  // Calling this resets page to 1
	}
	render() {
		<Pagination
			onRef =	{ref => (this.pagination = ref)}  	// Gets ref for calling child methods
			info = {data}								// Array of data objects that will be mapped over
			render = { (row, i) => {					// Function the map should execute for each data object in info array
				return <div>{row.label}</div>
			}}
		/>
	}
}