import React from 'react'
import { Close } from '../partials/Close'

import './styles/Workflow.css'

export const Workflow = ({ role, canEdit, onDelete, onEdit, count }) => (
	<tr>
		<td>{count}</td>
		<td>{role.name}</td>
		{/*
		<td className="permCol">
			{role.permissions.map(permission => (
			<span key={permission.id}>{permission.name}</span>
				))}
		</td>
		*/}
		{canEdit && 
			<td>
			<span className="fas fa-pencil-alt" onClick={onEdit} />
				&nbsp; &nbsp;
				<Close onClick={onDelete} color="black" />
			</td>
		}
	</tr>
)
