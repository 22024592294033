
import React, { useContext } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { can } from './utils/can'

import { Container, Row } from 'reactstrap'

import { init } from '@elastic/apm-rum';
import { ApmRoute } from '@elastic/apm-rum-react';

var environ = window.ncs_api.split("/");
let setEnv = "LOCAL";
switch ( environ[environ.length - 1] ) {
	case "LOCAL":       setEnv = "LOCAL";       break;
	case "DEVELOPMENT": setEnv = "DEVELOPMENT"; break;
	case "TESTING":     setEnv = "TESTING";     break;
	case "PRODUCTION":  setEnv = "PRODUCTION";  break;
}
var apm = init({
	// Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
	serviceName: 'JobInfo-javascript',
	// Set the version of your application
	distributedTracingOrigins: ['https://api.ncscredit.com/apm/'],
	// Used on the APM Server to find the right sourcemap
	serviceVersion: '0.90',
	// Set custom APM Server URL (default: http://localhost:8200)
	serverUrl: 'https://api.ncscredit.com/apm/',
	// distributedTracingOrigins: ['http://localhost:8080'],
	environment: setEnv,
	pageLoadTransactionName: 'src/index.js',
	breakdownMetrics: true
})

// import pages
// import GuestPage from './components/pages/GuestPage'
import LeftNav from './components/layout/LeftNav'

import SignInPage from './components/pages/SignInPage'
// import SignUpPage from './components/pages/SignUpPage'
import ResetPage from './components/pages/ResetPage'

import Forms from './components/pages/FormsPage'
import FormBuilder from './components/pages/FormBuilder'

import JobsPage from './components/pages/JobsPage'
import ShowJob from './components/pages/ShowJob'

import UsersPage from './components/pages/UsersPage'
import ProfilePage from './components/pages/ProfilePage'
import SettingsPage from './components/pages/SettingsPage'
import CompanyPage from './components/pages/CompanyPage'
import AdminPage from './components/pages/AdminPage'
import AdminReportPage from './components/pages/AdminReportPage'

import ShowForm from './components/pages/ShowForm'
import { JobAppContext } from './GlobalState'

//  private router
let PrivateRoute = ({ component: Component, ...rest }) => {
	const { user, isAuthenticated } = useContext(JobAppContext);
	const { permission } = rest

	// check if user is logged in... must be for "private" routes
	if ( !user || !isAuthenticated ) {
		return <Redirect to="/" />
	}

	return (
		<Route
			{...rest}
			render={props =>
				// user-only is a hard-coded permission where being logged in is the only permission needed
				permission == 'user-only' || can([permission], user) ? (
					<Component {...props} />
				) : (
					<Redirect to="/" />
				)
			}
		/>
	)
}

const NoMatch = ({ location }) => <Redirect to="/" />

const Root = () => {
	const { user, isAuthenticated, signOut } = useContext(JobAppContext);

	const location = window.location.pathname;
	const showLoggedInInfo = isAuthenticated && (location.indexOf('/link') < 0 && location.indexOf('/new') < 0)

	return (
		<Router>
			<Container fluid>
				<Row id="page-top">
					{showLoggedInInfo && <LeftNav />}
					<main id={!showLoggedInInfo ? "guest" : ""} role="main">
						<div className="content"> 
							
							<Switch>
								<Route path="/" exact render={props => <Redirect to="/login" />} />
								<Route path="/link/:uuid" exact component={ShowForm} />
								<Route path={["/new/:appid", "/new/:appid/:cntid"]} exact>
									<ShowForm />
								</Route>
							
								<Route path="/login" exact component={SignInPage} />
								{/* <Route path="/register" exact component={SignUpPage} /> */}
								<Route path="/reset/:code?" component={ResetPage} />

								<PrivateRoute path="/profile" exact permission="user-only" component={ProfilePage} />
								<PrivateRoute path="/settings" exact permission="user-only" component={SettingsPage} />
								
								<PrivateRoute path="/jobs/:list?" permission="user-only" component={JobsPage} />

								<PrivateRoute path="/job/:id" permission="user-only"  component={ShowJob} />
								
								<PrivateRoute path="/forms" exact permission="user-only" component={Forms} />

								<PrivateRoute path="/company/:id" permission="company" component={CompanyPage} />
								<PrivateRoute path="/form/:id"    permission="forms" component={FormBuilder} />
								<PrivateRoute path="/users" exact permission="users" component={UsersPage} />

								<PrivateRoute path="/admin" permission="super" exact component={AdminPage} />
								<PrivateRoute path="/admin/company/:id" permission="super" component={CompanyPage} />
								<PrivateRoute path="/admin/users/:id" permission="super" component={UsersPage} />
								<PrivateRoute path="/admin/report" permission="super" component={AdminReportPage} />
								
								<Route path="*" component={NoMatch} />
							</Switch>
						</div>
					</main>
				</Row>
			</Container>
		</Router>
	)
}
export default Root;
