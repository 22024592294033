import React, { useState, useEffect, useContext } from 'react'
import { JobAppContext } from '../../GlobalState'

// display
import { Button } from 'reactstrap'

import { Form, Input, Select } from '../partials'
import Link from '../partials/Link'

import { can } from '../../utils/can'

// functionality
import { changePassword, saveUserConfig } from '../../api'

const list_options = [
	{ value: 100, text: '100' },
	{ value: 500, text: '500' },
	{ value: 1000, text: '1000' },
]

const column_choices = [
	{ text: 'Form Logo', value: 'form_variant' },
	{ text: 'Location', value: 'location' },
]

const SettingsPage = (props) => {

	const { user, setAccessToken } = useContext(JobAppContext);

	const [ isLoading, setIsLoading ] = useState(true);
	const [ passwordSaved, setPasswordSaved ] = useState(false);

	const [ current, setCurrent ] = useState('');
	const [ new_pass, setNewPass ] = useState('');
	const [ confirm_new, setConfirmNew ] = useState('');
	const [ error, setError ] = useState('');

	const [ column_choice, setColumnChoice ] = useState('location');
	const [ list_count, setListCount ] = useState(100);

	const [ optChanged, setOptChanged ] = useState(false);
	const [ userOptSaved, setUserOptSaved ] = useState(false);
	const [ isSaving, setIsSaving ] = useState(false);

	useEffect(() => {
		setListCount(user.list_count);
		setColumnChoice(user.column_choice);
	}, [])

	const handleOptChange = event => {
		const target = event.target
		const { value, name } = target

		setOptChanged(true);
		setUserOptSaved(false);
		if ( name == 'list_count' ) {
			setListCount(value);
		} else if ( name == 'column_choice' ) {
			setColumnChoice(value);
		}
	}

	const saveUserOptions = () => {
		setIsSaving(true);
		saveUserConfig({ list_count, column_choice }).then(data => {
			if ( data.result == 'success' ) {
				setOptChanged(false);
				setUserOptSaved(true);
				setIsSaving(false);
			}
		})
	}

	const handlePasswordSubmit = () => {
		setPasswordSaved(false);
		setError('');

		if ( new_pass !== confirm_new ) {
			
			setPasswordSaved(false);
			setError('Your new password does not match the Confirm New Password field');
			setCurrent('');
			setNewPass('');
			setConfirmNew('');

		} else {

			changePassword({ current, new_pass }).then(data => {
				setPasswordSaved(true);
				setError(data.error !== undefined ? data.error : '');
				setCurrent('');
				setNewPass('');
				setConfirmNew('');

				if (data.token !== undefined) {
					localStorage.setItem('accessToken', data.token)
					setAccessToken(data.token);
				}
			})
		}
	}

	const passwordReadySave = (current != '' && new_pass != '' && new_pass == confirm_new) ? true : false

	return (
		<>
			<h4 className="Title">Settings</h4>
			
			<hr />
			
			<div className="ml-3 my-5">
				<div className="row">
					<div className="col-1">Name:</div>
					<div className="col-11"><Link to="/profile">{user.name}</Link></div>
				</div>
				<div className="row">
					<div className="col-1">Email:</div>
					<div className="col-11">{user.email}</div>
				</div>
				{user.memberships.map((member) => (
					<div className="row" key={member.id}>
						<div className="col-1">Company:</div>
						<div className="col-11">
							{can(['company'], user) ? (
								<Link to={`/company/${member.company.id}`}>{member.company.name}</Link>
							) : (
								member.company.name
							)}
						</div>
					</div>
				))}
				<div className="row">
					<div className="col-1">Contact ID:</div>
					<div className="col-11">
						{user?.cnt_id}
					</div>
				</div>
				<div className="row">
					<div className="col-1">
						API Key 
						<i className="far fa-question-circle ml-2" title="An API key must be set, to Import projects to NCS"></i>
					</div>
					<div className="col-11">
						{user.api_key ? (
							<i className="fas fa-check" style={{ color: 'green' }}></i>
						) : (
							<i className="far fa-times-circle" style={{ color: 'red' }}> Not Set</i>
						)}
					</div>
				</div>
			</div>

			<hr />

			<div className="ml-3 my-5">
				<h5>Job List Options</h5>
				<Form onSubmit={saveUserOptions}>
					<div className="form-row mb-3">  
						<div className="col-md-4 col-lg-3 col-xl-2">
							<Select
								label="Column Options"
								onChange={handleOptChange}
								name="column_choice"
								value={column_choice}
								children={column_choices.map((opt, index) => (
									<option key={index} value={opt.value}>{opt.text}</option>
								))}
							/>
						</div>
					</div>

					<div className="form-row mb-3">  
						<div className="col-md-4 col-lg-3 col-xl-2">
							<Select
								label="Default List Count"
								onChange={handleOptChange}
								name="list_count"
								value={list_count}
								children={list_options.map((opt, index) => (
									<option key={index} value={opt.value}>{opt.text}</option>
								))}
							/>
						</div>
					</div>

					<div className="form-row">
						<div className="col-md-4 col-lg-3 col-xl-2">
							<Button type="submit" color={optChanged ? 'primary' : 'default'} disabled={!optChanged || isSaving}>
								{isSaving ? 'Saving...' : 'Save Options'}
							</Button>
							{userOptSaved && (
								<i className="far fa-check-circle" 
									style={{ color: '#007bff', fontSize: '24px', fontWeight: 'bold', marginLeft: '10px', verticalAlign: 'middle' }} 
									title="Saved!"></i>
							)}
						</div>
					</div>
				</Form>
			</div>

			<hr />

			<div className="ml-3 my-5">
				<h5>Change password</h5>
				<Form onSubmit={handlePasswordSubmit}>
					<div className="form-row mb-3">
						<div className="col-md-4 col-lg-3 col-xl-2">
							<Input
								name="current"
								label="Current password"
								onChange={(e) => { setPasswordSaved(false); setCurrent(e.target.value) }}
								type="password"
								value={current}
							/>
						</div>
					</div>

					<div className="form-row mb-3">
						<div className="col-md-4 col-lg-3 col-xl-2">
							<Input
								name="new_pass"
								label="New password"
								onChange={(e) => { setPasswordSaved(false); setNewPass(e.target.value) }}
								type="password"
								value={new_pass}
							/>
						</div>
					</div>

					<div className="form-row mb-3">  
						<div className="col-md-4 col-lg-3 col-xl-2">
							<Input
								name="confirm_new"
								label="Confirm New password"
								onChange={(e) => { setPasswordSaved(false); setConfirmNew(e.target.value) }}
								type="password"
								value={confirm_new}
							/>
						</div>
					</div>

					<div className="form-row">
						<div className="col-md-4 col-lg-3 col-xl-2">
							<Button type="submit" color={passwordReadySave ? 'primary' : 'default'} disabled={!passwordReadySave}>Change Password</Button>
							{error && (
								<span style={{ color: 'red', fontWeight: 'bold', marginLeft: '10px' }}>{error}</span>
							)}
							{passwordSaved && (
								<i className="far fa-check-circle" 
									style={{ color: '#007bff', fontSize: '24px', fontWeight: 'bold', marginLeft: '10px', verticalAlign: 'middle' }} 
									title="Saved!"></i>
							)}
						</div>
					</div>
				</Form>
			</div>

			<hr />
		</>
	)
}
export default SettingsPage;