import React, { useState, useEffect } from 'react'

import { api, getLocations, getContacts, getLocationsByCNTID } from './api/ncs';
import {
	authLogin, authRegister, requestResetLink, authReset, 
	getJobList, addJob, getMappedFields
} from './api'

export const JobAppContext = React.createContext({
	isLogginIn: false,
	isSigningOut: false,
	isAuthenticated: localStorage.getItem('accessToken') ? true : false,
	accessToken: localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : null,
	user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
	error: '',
})

export default function GlobalState(props){

	const [ isLogginIn, setIsLogginIn ] = useState(false);
	const [ isSigningOut, setIsSigningOut ] = useState(false);
	const [ isAuthenticated, setIsAuthenticated ] = useState(localStorage.getItem('accessToken') ? true : false);
	const [ accessToken, setAccessToken ] = useState(localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : null);
	const [ user, setUser ] = useState(localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null);
	const [ error, setError ] = useState('');

	const [ jobsPage, setJobs ] = useState({
		open: [],
		pending: [],
		submitted: [],
		archived: [],
		// isFetching: false, 
		error: ''
	});
	const [ list, setList ] = useState('open'); // job list user is viewing

	const [ isLoading, setLoading ] = useState(true);
	const [ list_count ] = useState(100);

	const [ mapped_types, setMappedTypes ] = useState([
		{
			id: 0,
			value: null,
			name: "No Type ID",
			reference: ''
		}
	]);
	const [ address_types, setAddressTypes ] = useState([
		{
			id: 0,
			value: null,
			name: "Customer",
			reference: ''
		},
		{
			id: 1,
			value: 2,
			name: "Job Location",
			reference: ''
		}
	]);

	const [ states, setStates ] = useState([]);

	const [ loadingContacts, setLoadingContacts ] = useState(true);
	const [ contacts, setContacts ] = useState([]);
	
	const [ loadingLocations, setLoadingLocations ] = useState(true);
	const [ locations, setLocations ] = useState([]);

	const apiKey = user?.api_key != '' ? user?.api_key : user.memberships[0].company.contact_user.api_key;

	const localAccessToken = localStorage.getItem('accessToken')
	useEffect(() => {
		if(localAccessToken){
			setAccessToken(localAccessToken);
		} else {
			setAccessToken(null);
			setIsAuthenticated(false);
			setUser(null);
		}
	}, [localAccessToken])

	useEffect(() => {

		// Get all the valid "states" from NCS API
		api.get(`${api.root}/ols/nml/1/state-list/json`, 'Gq8jRsnu11h26vGb981v4uUbY7tmfMqQqcMozKOM')
		.then((data) => {
			setStates([
				{
					state_code: "",
					state_desc: "",
					ctry_code: ""
				},
				...data
			]);
		}).catch(err => console.log('ERROR: getStates', err))

		if ( isAuthenticated ) {
			
			/*
			axios.get(`${ROOT_URL}/maintenance/json`)
				.then(({data}) => {
					setEmployees(data)
					setLoading(false)
				})
			*/
			
			if ( typeof user !== "undefined" && user !== null ) {

				getMappedFields().then(resp => {
					setMappedTypes([
						...mapped_types,
						...resp.mapped_types,
					])
					setAddressTypes([
						...address_types,
						...resp.address_types
					]);
				})

				/*
				api.get(`${api.root}/cli/jobinformation/1/assoc_types`, user.api_key).then(data => {
					console.log("Success: assoc_types", data);
					setAssocTypes(data);
				}).catch(err => console.log('ERROR: assoc_type', err));
				*/

				if (apiKey) {
					getLocations(apiKey).then(locs => {
						setLoadingLocations(false);
						setLocations(locs);
	
						getContacts(locs[0].DIV_ID, apiKey).then(conts => {
							setLoadingContacts(false);
							setContacts(conts);
						}).catch(err => console.log('ERROR: getContacts', err));
	
					}).catch(err => console.log('ERROR: getLocations', err));
				}
				// fetchJobList(list);

			}
		}

	}, [isAuthenticated])

	const update_locations = (cnt_id = 0) => {
		
		setLoadingLocations(true);
		if ( cnt_id !== 0 ) {
			
			// get all locations available for this user (based on their Contact ID)
			getLocationsByCNTID(cnt_id, apiKey).then(divs => {
				setLocations(divs);
				setLoadingLocations(false);
			});

		} else {

			// get all locations available for the apiKey user (either currently logged in, or the company contact)
			getLocations(apiKey).then(divs => {
				setLocations(divs);
				setLoadingLocations(false);
			});

		}
		
	}

	const update_contacts = (div_id) => {
		setLoadingContacts(true);
		getContacts(div_id, apiKey).then(conts => {
			setContacts(conts);
			setLoadingContacts(false);
		})
	}

	const postJob = (data) => (
		
		addJob(data).then(data => {
			setJobs({
				...jobsPage,
				"open": [
					...jobsPage['open'],
					data
				]
			});
			return data;
		})
		.catch(error => {
			
			// TODO: return error and display it on JobsPage
			return { type: 'error', payload: error }
			// dispatch({ type: ADD_JOB.ERROR, payload: 'error' })
			
		})

	)

	const auth = credentials => {
		
		setIsLogginIn(true);
		return authLogin(credentials)
			.then(response => {
				if (response.ok === false) {
					throw Error(response)
				}
				return response.json()
			})
			.then(json => {
				localStorage.setItem('accessToken', json.token)
				localStorage.setItem('user', JSON.stringify(json.user))

				setAccessToken(json.token);
				setUser(json.user);
				setIsAuthenticated(true);
				// dispatch({
				// 	type: LOGIN.SUCCESS,
				// 	payload: json,
				// })
			})
			.catch(error => {
				setError(error.message);
				// dispatch({
				// 	type: LOGIN.ERROR,
				// 	payload: error,
				// })
			})
	}
	
	const signOut = () => {
		setIsSigningOut(true);	
		localStorage.removeItem('accessToken')
		localStorage.removeItem('user')

		setIsAuthenticated(false);
		setAccessToken(null);
		setUser(null);

		// localStorage.removeItem('role')
		// localStorage.removeItem('company')

		setIsSigningOut(false);
	}
	
	const register = data => {

		return authRegister(data).then(response => {
			if (response.ok === false) {
				console.log(response)
				throw Error(response)
			}
			return response.json()
		})
		.then(json => {
			localStorage.setItem('accessToken', json.token)
			localStorage.setItem('user', JSON.stringify(json.user))
			// localStorage.setItem('role', JSON.stringify(json.role))
			// localStorage.setItem('company', JSON.stringify(json.company))

			setAccessToken(json.token);
			setUser(JSON.stringify(json.user));
			setIsAuthenticated(true);

			// dispatch({
			// 	type: REGISTER.SUCCESS,
			// 	payload: json,
			// })
		})
		.catch(error => {
			// console.log(error, error.message)
			setError(error.message);
			// dispatch({
			// 	type: REGISTER.ERROR,
			// 	payload: error,
			// })
		})
	}

	const requestReset = data => {
		return requestResetLink(data).then(response => {
			if (response.ok === false) {
				console.log(response)
				throw Error(response)
			}
			return response;
		})
		.then(json => {
			return json;
		})
		.catch(error => {
			setError(error.message);
		})
	}

	const applyReset = data => {
		return authReset(data).then(response => {
			if (response.ok === false) {
				console.log(response)
				throw Error(response)
			}
			return response.json();
		})
		.then(json => {
			return json;
		})
		.catch(error => {
			setError(error.message);
		})
	}

	const clearError = () => setError('');

	const fetchJobList = (list) => {
		
		setList(list)
		setLoading(true);
		getJobList(list).then(data => {
			setJobs({
				...jobsPage,
				[list]: data.jobs
			})
			setLoading(false);
		})
		.catch(error => {
			return { type: 'error', payload: error }
			// dispatch({ type: GET_JOB_LIST.ERROR, payload: error })
		})
		
	}

	return(
		<JobAppContext.Provider
			value={{
				auth,
				signOut,
				register,
				requestReset, applyReset,
				error,
				clearError,

				isLogginIn,
				isSigningOut,
				isAuthenticated,
				accessToken, setAccessToken,
				user,

				jobsPage,
				fetchJobList,
				postJob,

				isLoading,
				list_count,
				
				mapped_types,
				address_types,

				states,
				
				/*
				selected_contact,
				*/
				loadingContacts,
				contacts, update_contacts,
				/*
				updateContact,
				*/

				/*
				selected_location,
				*/
				loadingLocations,
				locations, update_locations
			}}
		>
			{props.children}
		</JobAppContext.Provider>
	)
}