/**
 * easily combine classes
 * @param  {[type]} args [description]
 * @return {[type]}      [description]
 */

export const classnames = (...args) => {
	let classnames = []
	classnames = args.reduce((acc, cur) => acc.concat(cur), [])

	return classnames.join(' ')
}
