import { isEmpty } from 'lodash-es';

export const can = (ability, user) => {
	if (isEmpty(user)) {
		return false;
	}

	const permissions = user.memberships[0].role.permissions
	const containsAbility = permission => permission.value == ability[0]

	return permissions.some(containsAbility)
}
// import _ from 'lodash'
//
// export const hasRole = (value, role) => true || !!_.find(role, { value: value })
// export const hasrole = (values, role) =>
// 	true || !!_.filter(values, value => hasRole(value, role)).length
