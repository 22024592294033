import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  createHistoryEntry
} from '../../api';

import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Button, Row, Col, Table
} from 'reactstrap';
import { Input, Checkbox } from '../partials';
import SharedHistory from '../partials/SharedHistory';

const ShareLink = ( prop ) => {
  
  const link_sections = [];
  prop.form.sections.map((section) => {
    link_sections.push(section.id.toString())
  })

  const { id } = useParams();
  const [job_form, setForm] = useState(prop.form);
  const [history_list, setHistories] = useState([ ...prop.histories ]);
  const [selected, setSelected]   = useState([ ...link_sections ]);
  const [shareNote, setShareNote] = useState('');
  
  const [build_instr, setInstructions] = useState(false);
  const [buildModal, toggleBuildModal ] = useState(false);
  const [modal, toggleLinkModal]      = useState(false);

  const [ isSubmitting, setSubmitting ] = useState(false);

  const [previewLabel, setLabel]  = useState('');
  const [previewLink, setLink]    = useState('');
  const [copied, setCopied]       = useState(false);

  useEffect(() => {
    setHistories([ ...prop.histories ]);
  }, [prop.histories]);

  /* open/close modals and instruction section ******************************* */
  const openCloseModal = () => toggleBuildModal(!buildModal);
  const openCloseInstr = () => setInstructions(!build_instr);
  const openCloseLinkModal = () => toggleLinkModal(!modal);

  const toggleModal = (linkLabel, url) => {
    setCopied(false);
    setLabel(linkLabel);
    setLink(url);
    toggleLinkModal(!modal);
  }

  /* handle field changes *************************************************** */
  const handleChange = e => {
    const { target } = e
    const { name, value, checked } = target;

    if (checked) {
      setSelected([ ...selected , name]);
    } else {
      let newSelected = selected.filter(val => val !== name);
      setShareNote(value);
      setSelected([ ...newSelected ]);
    }
    
  }

  // TODO
  const handleUpdateHistory = (id) => {
    console.log('handleUpdateHistory for id: ', id);
  }
  
  /* change state of email form ********************************************* */
  const generateLink = () => {

    setSubmitting(true);
    const link_values = {
      job_id: id,
      selected: selected,
      shareNote: shareNote,
    }
    createHistoryEntry(link_values).then(data => {

      prop.addNew(data);

      setSubmitting(false);
      // clear text field, to prepare for next link to be generated
      setShareNote('');
      setSelected([ ...link_sections ]);
      openCloseModal(false);
    })
  }

  const copyLink = (shareLink) => {
    let textArea = document.createElement('textarea');
		textArea.setAttribute('style','width:1px;border:0;opacity:0;');
		document.body.appendChild(textArea);
		textArea.value = shareLink;
		textArea.select();
		textArea.focus();
		document.execCommand('copy');
    document.body.removeChild(textArea);
    
    setCopied(true)
  }

  return (
    <div>

      <Modal isOpen={buildModal} toggle={openCloseModal}>
        <ModalHeader toggle={openCloseModal}>
          Build Custom Link
          <i className="far fa-question-circle mx-3" style={{ cursor: 'pointer' }} title="Click for Instructions" onClick={openCloseInstr}></i>
        </ModalHeader>
        <ModalBody>
          {build_instr && (
            <div className="my-3">
              <p>Share access to this job with those who can fill out the needed information.</p>
              <ol className="share_instructions">
                <li>Enter a Name/Label for the link, select the specific section(s) of the form to share, and click the "Generate Link" button</li>
                <li>Click the "Show" button in the newly-created table row, to see the link in a pop-up dialog window</li>
                <li>Click the "Copy" button in the table below, or the pop-up dialog, and use your favorite email client, to send it to whoever you like</li>
              </ol>
            </div>
          )}
          <div className="shareForm">
            <div className="form-row">
              <div className="col-md">
                <Input
                  autoFocus={true}
                  name="shareNote"
                  onChange={handleChange}
                  label="Name / Label"
                  value={shareNote}
                  isRequired={1}
                  readOnly={false}
                />
              </div>
            </div>
            <p className="my-3"><cite>* Label this so you can remember who you will send it to (maxlength: 100 characters)</cite></p>
            {job_form.sections.map(section => (
              <Checkbox
                onChange={handleChange}
                label={section.title}
                name={section.id}
                key={section.id}
                defaultChecked="checked"
              />
            ))}
            
          </div>
        </ModalBody>
        <ModalFooter>
          {shareNote == '' || isSubmitting ? (
            <Button color='secondary' disabled={true} outline>
              {isSubmitting ? 'Saving...' : 'Generate Link'}
            </Button>
          ) : (
            <Button color='primary' disabled={false} onClick={generateLink}>
              Generate Link
            </Button>
          )}
          
        </ModalFooter>
      </Modal>

      <Modal isOpen={modal} toggle={openCloseLinkModal} style={{ 'maxWidth': '650px' }}>
        <ModalHeader toggle={openCloseLinkModal}>Share Link</ModalHeader>
        <ModalBody>
          <div>
            <label>{previewLabel}</label>
            <div style={{fontSize: '14px'}}>{previewLink}</div>
          </div>
        </ModalBody>
        <ModalFooter>
          {copied && (
            <i className="far fa-check-circle" 
                style={{ color: '#007bff', fontSize: '24px', fontWeight: 'bold', marginLeft: '10px', verticalAlign: 'middle' }} 
                title="Copied!"></i>
          )}
          <Button color="primary" className="Button float-left" onClick={() => copyLink(previewLink)}>
            <i className="far fa-copy font-weight-bold"></i> 
            Copy
          </Button>
          <Button color="secondary" className="Button float-right" onClick={() => toggleLinkModal(false)}>
            Close Window
          </Button>
        </ModalFooter>
      </Modal>

      <h3 style={{ padding: '10px 20px', margin: '10px 0', backgroundColor: '#EAEAEA' }}>
        Create Custom Job Link
        <Button color="primary" size="sm" onClick={() => { toggleBuildModal(true) }} className="ml-3">Add New</Button>
      </h3>

      <Row>
        <Col>
          
          {history_list.length !== 0 ? (
            <div className="historyList">

              <h5>Job Information Links</h5>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Date Created</th>
                    <th>Link Note</th>
                    <th>Sections</th>
                    <th>Progress</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                {
                history_list.map((history, index) => {
                  // console.log('history', history);
                  return (
                    <SharedHistory
                      key={index}
                      history={history}
                      updateHistory={() => { handleUpdateHistory(history.id) }}
                      toggleModal={toggleModal}
                      index={index}
                      form={job_form}
                    />
                  )})
                }
                </tbody>
              </Table>
            </div>
          ) : (
            <div style={{ border: 'dotted 1px #CACACA', padding: '20px', margin: '20px' }}>
              Click the "Add New" button above to build a custom link to this specific job.
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default ShareLink;