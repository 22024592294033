import moment from 'moment';
import 'moment-timezone';

export default function formatDate(date,format = 'MM/DD/YYYY'){
	if(date) {
		if(date == '0000-00-00'){
			return 'None';
		}
		else if(date == '0000-00-00 00:00:00'){
			return 'TBD';
		}
		else {
			let return_date = moment(date).format(format);
			return return_date == '12/31/1969' ? 'None':return_date;
		}
	}
	else{
		return '';
	}
}