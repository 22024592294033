import React, { Component } from 'react'
import { delay } from '../../utils/method-helpers'
import { classnames } from '../../utils/classnames'

// import { Form, Input, Checkbox, Textarea } from '../partials'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { Form, Input } from '../partials'
import { Close } from '../partials/Close'
import Field from './Field'

import {
	saveFieldOrder,
} from '../../api'

const TYPE = {
	TEXT: 'text',
	DATE: 'date',
	EMAIL: 'email',
	ADDRESS: 'address',
	ADDRESS_PLUS: 'address_plus',
	CONTACT: 'contact',
	CHOICE: 'choice',
	HIDDEN: 'hidden',
	STATIC_TEXT: 'instructions'
}

/*
const getItemStyle = (isDragging, draggableStyle) => ({
	userSelect: 'none',
	background: isDragging ? 'lightgreen' : 'grey',
	...draggableStyle,
})
*/

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list)
	const [removed] = result.splice(startIndex, 1)
	result.splice(endIndex, 0, removed)

	return result
}

let id = 0

/**
 * [Section description]
 * @extends Component
 */
class Section extends Component {
	constructor(props) {
		super(props)

		this.state = {
			backup: props.section,
			id: props.section.id,
			order: props.section.order,
			title: props.section.title,
			fields: props.section.fields,
			isEditing: true,

			modal: false
		}
	}

	// section title change
	handleChange = event => {
		const { target } = event
		const { name, value } = target

		this.setState({
			[name]: value,
		})
	}

	handleFieldChange = (data, key) => {
		const { state } = this

		delay(
			this.setState({
				fields: state.fields.map((field, index) => {
					if (index === key) {
						return Object.assign(
							{},
							{
								...data,
							}
						)
					}
					return field
				}),
			}),
			0
		).then(() => {
			// this.props.pushChanges(this.state)
		})
	}

	removeField = (data, key) => {
		delay(
			this.setState({
				fields: this.state.fields.filter(
					(field, index) => data.id !== field.id
				),
			}),
			0
		).then(() => this.props.pushChanges(this.state))
	}

	handleSave = () => {
		this.setState({
			isEditing: false,
			modal: false
		})
		if (this.state.title.trim() !== this.state.backup.title) {
			this.props.pushChanges(this.state)
		}
	}

	addField = field => {
		var nextFldSeq = this.state.fields.length;
		this.setState(prevState => ({
			fields: [
				...prevState.fields,
				{
					id: `key_${id++}`,
					type: field,
					new: true,
					description: '',
					title: '',
					order: nextFldSeq,
					section_id: this.state.id,
					required: false,
					isEditing: true,
				},
			],
		}))
	}

	onDragEnd = result => {
		if (!result.destination) {
			return
		}

		const fields = reorder(
			this.state.fields,
			result.source.index,
			result.destination.index
		)

		saveFieldOrder({ fields }).then(data => console.log(data))

		this.setState({
			fields,
		})
	}

  // /*drag*/
  // handleOnDragStart = ev => {
  //   console.log('handleOnDragStart')
  //   this.setState({
  //     draggedItemIndex: ev.target.id,
  //   })
  // }

  // handleOnDragOver = ev => {
  //   ev.preventDefault()
  //   console.log('move')
  //   if (ev.target.id !== this.state.draggedItemIndex) {
  //     ev.dataTransfer.dropEffect = 'move'
  //     ev.target.classList.add('hover')
  //   }
  // }

  // handleOnDragLeave = ev => {
  //   ev.preventDefault()
  //   ev.target.classList.remove('hover')
  //   // console.log('handleOnDragOver', ev)
  // }

  // reorderField = ({ start, end }) => {
  //   const endInt = parseInt(end, 10)
  //   const startInt = parseInt(start, 10)
  //   const movedField = this.state.fields[startInt]

  //   const remainingFields = this.state.fields.filter(
  //     (field, index) => index !== startInt
  //   )

  //   const reorderedItems = [
  //     ...remainingFields.slice(0, endInt),
  //     movedField,
  //     ...remainingFields.slice(endInt),
  //   ]

  //   this.setState(prevState => ({
  //     fields: reorderedItems,
  //   }))

  //   saveFieldOrder({ fields: reorderedItems }).then(data => console.log(data))
  // }

  // handleOnDrop = ev => {
  //   ev.preventDefault()

  //   const droppedItemId = ev.currentTarget.id

  //   if (
  //     this.state.draggedItemIndex !== null &&
  //     this.state.draggedItemIndex !== undefined
  //   ) {
  //     this.reorderField({
  //       start: this.state.draggedItemIndex,
  //       end: droppedItemId,
  //     })
  //   }
  //   ev.currentTarget.classList.remove('hover')

  //   // }

  //   this.setState({
  //     draggedItemIndex: null,
  //   })
  // }

  	toggleModal = () => {
		this.setState(prevState => ({
			modal: !prevState.modal
		}))
	}

	render() {
		const { section } = this.props
		return (
			<>
			<Modal isOpen={this.state.modal} toggle={this.toggleModal}>
				<ModalHeader>Edit Section Title</ModalHeader>
				<ModalBody>
					<Form onSubmit={this.handleSave}>
						<Input
							autoComplete="off"
							value={this.state.title}
							placeholder={this.state.backup.title || 'Section Name'}
							name="title"
							label="Section Title"
							onChange={this.handleChange}
						/>
					</Form>
				</ModalBody>
				<ModalFooter>
					<Button className="mr-2" onClick={() => this.handleSave()}>Save</Button>
					<Button onClick={() => this.setState(prevState => ({
							modal: false,
							title: prevState.backup.title
						}))
					}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>

			<div className="card" id={this.state.id}>

				<div className="card-header">
					<Close onClick={() => this.props.removeSection(this.state)} />
					<span>
						<b>{this.state.title}</b>
						<i className="far fa-edit ml-2" 
							onClick={() => this.toggleModal()}
							style={{ cursor: 'pointer' }}></i>
					</span>
				</div>
				<div className="card-body">
					<h4>Add field</h4>

					<section className="fieldBuilderEdit">
						<div className="formBuilderOptions">
							<Button className="mr-2" onClick={() => this.addField(TYPE.TEXT)}>
								Text
							</Button>
							<Button className="mr-2" onClick={() => this.addField(TYPE.DATE)}>
								Date
							</Button>
							<Button className="mr-2" onClick={() => this.addField(TYPE.EMAIL)}>
								Email
							</Button>
							<Button className="mr-2" onClick={() => this.addField(TYPE.ADDRESS)}>
								Address
							</Button>
							<Button className="mr-2" onClick={() => this.addField(TYPE.ADDRESS_PLUS)}>
								Address +
							</Button>
							<Button className="mr-2" onClick={() => this.addField(TYPE.CHOICE)}>
								Radio Button
							</Button>
							<Button className="mr-2" onClick={() => this.addField(TYPE.STATIC_TEXT)}>
								Static Text
							</Button>
							{/* <Button onClick={() => this.addField(TYPE.HIDDEN)}>
								Hidden Field
							</Button>*/}
						</div>

						{this.state.fields.length !== 0 && <h5>Fields</h5>}

						<DragDropContext onDragEnd={this.onDragEnd}>
							<Droppable droppableId="droppable" direction="vertical">
								{(provided, snapshot) => (
									<div ref={provided.innerRef} {...provided.droppableProps}>
										{this.state.fields.length !== 0 && this.state.fields.map((field, key) => (
											<Draggable 
												key={'field' +field.id} 
												draggableId={'field' +field.id} 
												index={key}
											>
												{(provided, snapshot) => (
													<div 
														className={classnames( 
															snapshot.isDragging && 'isDragging' 
														)}
														ref={provided.innerRef} 
														{...provided.draggableProps} 
														{...provided.dragHandleProps}
													>
														<Field
															key={field.id}
															id={field.id}
															field={field}
															formId={this.props.formId}
															pushChanges={data =>
																this.handleFieldChange(data, key)
															}
															removeField={data =>
																this.removeField(data, key)
															}
														/>
													</div>
												)}
											</Draggable>
										))}
									</div>
								)}
							</Droppable>
						</DragDropContext>
					</section>
				</div>
			</div>
			</>
		)
	}
}

export default Section
