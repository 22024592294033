import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';

import { get_user } from '../../user_info'

import moment from 'moment'
// import './styles/Workflow.css'

export const Form_Variant_Link = (props) => {
	
	const [ isCopied, setIsCopied ] = useState(false);

	const user = get_user();
	const form = props.form;
	const variant = props.variant;

	useEffect(() => {
		if ( isCopied ) {
			setTimeout(() => {
				setIsCopied(false);
			}, 2000)
		}
	}, [isCopied])

	const copyLink = (linkValue) => {
		let textArea = document.createElement('textarea');
		textArea.setAttribute('style','width:1px;border:0;opacity:0;');
		document.body.appendChild(textArea);
		textArea.value = linkValue;
		textArea.select();
		textArea.focus();
		document.execCommand('copy');
		document.body.removeChild(textArea);
		setIsCopied(true);
	}

	let linkValue = `${window.app_url}` + "/new/" +variant.uuid+ (user.cnt_id ? "/" + user.cnt_id : '');

	return (
		<div className="row mb-2">
			<div className="col-2">
				{variant?.file && (
					<img src={"/api/v1/" + variant.file.location} className="mx-2" 
						style={{ maxWidth: '50px' }} title={variant.title} />
				)}
			</div>
			<div className="col-5">
				{linkValue}
			</div>
			<div className="col-5">
				{form.mapped != 0 && (variant.uuid != null && variant.uuid != '') ? (
					<>
						<Button color="secondary" className="btn btn-sm" onClick={() => copyLink(linkValue)} title={linkValue}>
							{isCopied ? (
								<><i className="far fa-check font-weight-bold mr-1"></i>Copied!</>
							) : (
								<><i className="far fa-copy font-weight-bold mr-1"></i>Copy Link</>
							)}
						</Button>
						{/*
						<Button color="primary" className="btn btn-sm ml-2" onClick={() => copyLink(linkValue)} title={linkValue}>
							<i className="far fa-copy font-weight-bold mr-1"></i> Your Custom Link
						</Button>
						*/}
						{/* isCopied && (
							<i className="far fa-check-circle font-weight-bold ml-2" style={{ color: '#007bff', fontSize: '24px', verticalAlign: 'middle' }} title="Copied!"></i>
						) */}
					</>
				) : (
					<cite style={{ fontSize: '.8em' }}>Contact Admin for Copy Link</cite>
				)}
			</div>
		</div>
	)
}

export const Form_Variant = ({
  variant, onDelete, onEdit
}) => (
  <tr key={variant.id}>
    <td>
      {
        variant.file ? (
          <img src={"/api/v1/" + variant.file.location} style={{ maxWidth: '50px' }} />
        ) : (
          ''
        )
      }
    </td>
    <td>{variant.title}</td>
    <td>{variant.uuid || ''}</td>
    <td>{moment(variant.created_at).format('L')}</td>
    <td>
      <span className="fas fa-pencil-alt" onClick={onEdit} />
      {/* TODO - remove ui element until 'status' can be stored on form_variant table
      &nbsp; &nbsp;
      <Close onClick={onDelete} color="black" />
      */}
    </td>
  </tr>
)