import React, { Component, useContext, useEffect, useState, Fragment } from 'react'
import { Button, Table } from 'reactstrap'

import { get_user } from '../../user_info'

import { Form, Input } from '../partials'
import Link from '../partials/Link'
import { Close } from '../partials/Close'
import { Form_Variant_Link } from '../Form/Form_Variant';

import { saveBuilderData, getAllForms, deleteForm } from '../../api'
import moment from 'moment'

import { can } from '../../utils/can'

function CreateForm(props) {

	const [ isSubmitting, setIsSubmitting ] = useState(false);
	const [ name, setName ] = useState('');

	function onChange(e) {
		const { target } = e
		const { value } = target

		setName(value)
	}

  	function handleSubmit() {
		const accessToken = localStorage.getItem('accessToken')
		if (name.trim().length) {
			setIsSubmitting(true)
			saveBuilderData({ name, name }, accessToken).then(data => {
        		props.onComplete(data)
      		}).catch(error => console.error(error))
		}
	}

  	return (
    	<div>
			{can(['forms'], props.user) && (
				<div className="border p-4">
					<div>
						<h4>Create a Form</h4>
						<p>Create custom forms to collect the data you need. Forms can have many sections to collect Sub, Owner, Contractor, or any other information you need.</p>
						<p>After data has been collected via the form, reviewed, and submitted to NCS. It will be mapped into the OnlineServices portion of the NCS website for the next step in your processing.</p>
					</div>
					<Form onSubmit={handleSubmit} className="my-3">
						<div className="form-row">
							<div className="col-md-3">
								<Input
									name="name"
									onChange={onChange}
									autofocus={true}
									placeholder="Enter the form name"
									type="text"
									value={name}
									autoComplete='off'
								/>
							</div>
							<div className="col-md-3">
								<Button type="submit" color="primary" disabled={ isSubmitting || name.trim() === '' } onClick={() => handleSubmit()}>
									{isSubmitting ? `Submitting...` : `Create Form`}
								</Button>
							</div>
						</div>
					</Form>
				</div>
			)}
		</div>
  	)
}

const Form_Record = (props) => {
	
	const user = props.user;
	const form = props.form;

	function handleDelete(form_id) {
		if ( confirm('Are you sure you would like to delete this Form?') ) {
			deleteForm(form_id).then(data => {
				setFormList(data);
			})
		}
	}
	
	return (
		<Fragment>
			<tr>
				<td>
					{can(['forms'], user) ? (
						<Link to={`/form/${form.id}`}>
							<span className="font-weight-bold">{form.name}</span>
						</Link>
					) : (
						<span className="font-weight-bold">{form.name}</span>
					)}
					{form.form_variant.length > 0 && (
						<div className="py-2 px-3 ml-4 mt-3" style={{ border: 'solid 1px #CACACA'}}>
							<div className="row mb-3">
								<div className="col-2">
									<span className="font-weight-bold">Logo</span>
								</div>
								<div className="col-5">
									<span className="font-weight-bold">Guest Form Link</span>
								</div>
								<div className="col-5">
									<span className="font-weight-bold">Actions</span>
									<i className="far fa-question-circle ml-2" title="Copy this link to your clipboard"></i>
								</div>
							</div>
							{form.form_variant.map(variant => {
								return (
									<Form_Variant_Link key={variant.id} form={form} variant={variant} />
								)
							})}
						</div>
					)}
				</td>
				<td>{moment( form.created_at ).format('MM/DD/YYYY')}</td>
				{can(['forms'], user) && (
					<Fragment>
						<td>{form.status}</td>
						<td>{form.mapped ? 'Yes' : 'No'}</td>
						<td><Close color="black" onClick={() => handleDelete(form.id)} /></td>
					</Fragment>
				)}
			</tr>
		</Fragment>
	)
}

export default function Forms(props) {
	const user = get_user();
	
	const [ isLoading, setIsLoading ] = useState(true);
	const [ forms, setFormList ] = useState([]);

	useEffect(() => {
		getAllForms().then(
			data => {
				setFormList(data);
				setIsLoading(false);
			}, error => console.error(error),
		)
	}, [])

  	function handleComplete(data) {
		props.history.push(`/form/${data.id}`)
  	}

  	return (
		<div className="p-5">
			<CreateForm onComplete={handleComplete} user={user} />
			<Table responsive className="mt-3" id="forms-table">
				<thead>
					<tr>
						<th>Name</th>
						<th>Created At</th>
						{can(['forms'], user) && (
							<Fragment>
								<th>Status</th>
								<th>Mapped</th>
								<th>&nbsp;</th>
							</Fragment>
						)}
					</tr>
				</thead>
				<tbody>
					{
						forms.map(form => {
							if ( !can(['forms'], user) && (form.mapped != 1 || form.status != 'active') ) { return; }
							return (
								<Form_Record user={user} form={form} key={form.id} />
							)
						})
					}
					{isLoading ? (
						<tr>
							<td>
								<i className="fas fa-sync-alt fa-spin mr-1"></i> 
								Loading Forms...
							</td>
						</tr>
					) : (
						<tr>{!forms.length && <td>No forms found</td>}</tr>
					)}
				</tbody>
			</Table>
		</div>
	)
}