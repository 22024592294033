import React from 'react'
import { classnames } from '../../utils/classnames'

const handleSubmit = onSubmit => event => {
	event.preventDefault()
	onSubmit()
}

export const Form = ({ children, onSubmit, className }) => (
	<form
		action="/"
		className={classnames(className)}
		onSubmit={handleSubmit(onSubmit)}
	>
		{children}
	</form>
)
