import React, { useState, useEffect, useContext } from 'react'
import { JobAppContext } from '../../GlobalState'

// display
import { Modal, Button } from 'reactstrap'

import Link from '../partials/Link'

import { can } from '../../utils/can'

const ProfilePage = (props) => {

	const { user } = useContext(JobAppContext);

	console.log('[ProfilePage] user', user)

	return (
		<div className='p-4'>
			<h4 className="Title">User Profile</h4>
			
			<hr />
			
			<div className="p-4">
				<div className="row">
					<div className="col-1">Name:</div>
					<div className="col-11">
						{user.name}<span style={{ 'display': 'none' }}> ( {user.cnt_id} )</span>
						{' '}( <Link to="/settings">Settings</Link> )
					</div>
				</div>
				<div className="row">
					<div className="col-1">Email:</div>
					<div className="col-11">{user.email}</div>
				</div>
				{user.memberships.map((member) => (
					<div className="row" key={member.id}>
						<div className="col-1">Company:</div>
						<div className="col-11">
							{can(['company'], user) ? (
								<Link to={`/company/${member.company.id}`}>{member.company.name}</Link>
							) : (
								member.company.name
							)}
						</div>
					</div>
				))}
			</div>

			<hr />
			
			<div className="p-4">
				<h4>Coming Soon</h4>
				<ul style={{ listStyleType: 'none' }}>
					<li>Job Completion Statistics</li>
					<li>Assigned Jobs</li>
					<li>Notifications</li>
					<li>...and more</li>
				</ul>
			</div>

		</div>
	)
}
export default ProfilePage;