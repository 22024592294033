import React, { Fragment } from 'react'
import moment from 'moment'

import { Row, Col } from 'reactstrap'

export const Footer = () => (
	<footer className="footer">
		<div className="container">
			<p>
				<span className="font-weight-bold">For more Information:</span> Call <span className="font-weight-bold">800-826-5256</span> or email <span className="font-weight-bold">SecureYourTomorrow@ncscredit.com</span><br />
				Collection Services | UCC Services | Notice &amp; Mechanic's Lien Services | Education &amp; Resources<br />
				729 Miner Road | Cleveland, Ohio 44143 | www.ncscredit.com
			</p>
		</div>
	</footer>
)

export const SimpleFooter = () => (
	<footer style={{ margin: '0 10px' }}>
		<div className="py-3 px-2 copy" style={{ fontSize: '.8em', color: '#888888' }}>
			&copy; {moment().format('YYYY')} | NCS Credit
		</div>
	</footer>
)