import React, { useState, useContext, useEffect } from 'react'
import { JobAppContext } from '../../GlobalState'

import { can } from '../../utils/can'
import {
	getPermissions,
	// getRoles,
	// deleteWorkflow,
	updateRole,
	createRole,
} from '../../api'

import { Modal, ModalHeader, ModalBody, ModalFooter, Table, Button } from 'reactstrap'
import { Checkbox, Input, Form } from '../../components'
import { ListDropDown } from '../partials/ListDropDown'

import '../workflows/styles/Workflow.css'

const Roles = (props) => {

	const { user } = useContext(JobAppContext);

	const [ roles, setRoles ] = useState([]);

	const [ id, setId ] = useState(0);
	const [ name, setName ] = useState('');
	const [ assign_workflow, setAssignWorkflow ] = useState(false);

	const [ permissions, setPermissions ] = useState([]);
	const [ page_permissions, setPagePermissions ] = useState([]);
	const [ action_permissions, setActionPermissions ] = useState([]);

	const [ selected, setSelected ] = useState([]);

	const [ showModal, setShowModal ] = useState(false);
	const [ isSubmitting, setIsSubmitting ] = useState(false);

	useEffect(() => {
		// get all the permissions setup
		getPermissions().then(permissions => {
			let page_permissions = permissions.filter(perm => perm.type == 'page');
			let action_permissions = permissions.filter(perm => perm.type == 'action');

			setPermissions(permissions);
			setPagePermissions(page_permissions);
			setActionPermissions(action_permissions);
		});

		/*
		if ( can(['users'], this.props.auth.user) ) {
			getRoles().then(data => {
				console.log('[Roles] getRoles ->', data)
				this.setState({
					roles: data,
				})
			});
		} else {
			console.log('no "users" permission in Roles page');
		}
		*/

	}, [])

	useEffect(() => {
		if ( props.roles.length !== roles.length ) {
			setRoles(props.roles)
		}
	}, [props.roles])

	/*
	static getDerivedStateFromProps(newProps, state) {
		if ( newProps.roles.length !== state.roles.length ) {
			return {
				roles: newProps.roles,
			};
		}
		return null;
	}
	*/

	const permissionChange = (e) => {
		const target = e.target
		if (target.checked) {
			setSelected([ ...selected, parseInt(target.name, 10) ])
		} else {
			setSelected(selected.filter(item => item !== parseInt(target.name, 10)))
		}
	}

	/*
	handleChange = event => {
		const target = event.target
		const value = target.type === 'checkbox' ? target.checked : target.value

		if (target.type === 'checkbox') {
		
			if ( target.name == 'assign_workflow' ) {
				this.setState({
					[target.name]: value,
				})
			} else {
				if (target.checked) {
					this.setState(prevState => ({
						selected: [...prevState.selected, parseInt(target.name, 10)],
					}))
				} else {
					this.setState(prevState => ({
						selected: prevState.selected.filter(
							item => item !== parseInt(target.name, 10),
						),
					}))
				}
				
			}
		
		} else {
			this.setState({
				[target.name]: value,
			})
		}
	}
	
	const handleDelete = () => {
		console.log('handleDelete')
	}
	*/

	const toggleModal = () => {
		showModal ? closeRoleModal() : openRoleModal()
	}

	const openRoleModal = () => {
		setShowModal(true);
	}

	const closeRoleModal = () => {
		setId(0);
		setName('');
		setAssignWorkflow(false);
		setShowModal(false);
	}

  // add new user, or save new user if no idea is set
	const handleSubmit = () => {
		setIsSubmitting(true);

		const data = {
			name,
			assign_workflow: assign_workflow ? 1 : 0,
			permissions: selected,
		}
		if ( id === 0 ) {
			createRole(data).then(res => {
				console.log('create res:', res);
				setIsSubmitting(false);
				setRoles([ ...roles, res ])
				closeRoleModal();
			})
		} else {
			updateRole(id, data).then(res => {
				setRoles(roles.map(role => ( role.id === res.id ? res : role )))
				setIsSubmitting(false);
				closeRoleModal();
			})
		}
	}

	return (
		<div>
			<Modal isOpen={showModal} toggle={toggleModal}>
				<Form onSubmit={handleSubmit}>
					<ModalHeader toggle={toggleModal}>
						<span>{id === 0 ? 'New' : 'Edit'} Role</span>
					</ModalHeader>
					<ModalBody>
						<div className="form-group">
							<div className="form-row">
								<div className="col">
									<Input
										label="Name"
										name="name"
										onChange={(e) => setName(e.target.value)}
										type="text"
										value={name}
									/>
								</div>
							</div>
							<div className="form-row">
								<div className="col">
									<Checkbox
										label="This Role can be assigned to a Workflow"
										name="assign_workflow"
										onChange={(e) => setAssignWorkflow(e.target.checked ? true : false)}
										type="text"
										value={assign_workflow}
									/>
								</div>
							</div>
							<h4>Permissions</h4>
							<div className="form-row">
								<div className="col-6">
									<label>Page Access</label>
									{page_permissions.map(permission => (
										<Checkbox
											key={permission.id}
											label={permission.name}
											name={permission.id}
											value={selected.includes(permission.id)}
											onChange={e => permissionChange(e)}
										/>
									))}
								</div>
								<div className="col-6">
									<label>User Actions</label>
									{action_permissions.map(permission => (
										<Checkbox
											key={permission.id}
											label={permission.name}
											name={permission.id}
											value={selected.includes(permission.id)}
											onChange={e => permissionChange(e)}
										/>
									))}
								</div>
						
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button className="mr-2" color="primary" disabled={isSubmitting || name.trim() === '' || !selected.length}>
							{isSubmitting ? `Saving` : `Save`}
						</Button>
						<Button onClick={closeRoleModal}>Cancel</Button>
					</ModalFooter>
				</Form>
			</Modal>

			<h4 style={{ backgroundColor: '#EAEAEA', padding: '20px' }}>
				User Roles
				{can(['workflows'], user) &&
					<Button className="ml-4" color="primary" size="sm" onClick={openRoleModal} disabled={isSubmitting}>
						Add Role
					</Button>
				}
			</h4>

			<div className="m-3" style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
				<div className="workflow_instr">
					Define the Roles your Users can be assigned to. These Roles outline the actions/pages your Users will have access to.
				</div>

				<Table responsive style={{ maxWidth: '1200px' }}>
					<thead>
						<tr>
							<th>ID</th>
							<th>Name</th>
							<th>
								Assignable
								<i className="far fa-question-circle ml-2" title="Whether this role can be assigned to a Workflow Step below"></i>
							</th>
							<th>Permissions</th>
							{can(['workflows'], user) &&
								<th>Action(s)</th>
							}
						</tr>
					</thead>
					<tbody>
						{roles.map((role, index) => {
							return (
								<tr key={index}>
									<td>{role.id}</td>
									<td>{role.name}</td>
									<td>{role.assign_workflow == 1 ? 'Yes' : 'No'}</td>
									<td className="permCol">
										{role.permissions.map(permission => (
											<span key={permission.id}>{permission.name}</span>
										))}
									</td>
									{can(['workflows'], user) && 
										<td>
											<span className="fas fa-pencil-alt" onClick={() => {
												setId(role.id);
												setName(role.name);
												setAssignWorkflow(role.assign_workflow == 1 ? true : false);
												setSelected(role.permissions.map(
													permission => permission.id,
												));
												setShowModal(true);
											}} />
										</td>
									}
								</tr>
							)
						})}
					</tbody>
				</Table>
			</div>
		</div>
	)
}
export default Roles;