import React, { useState, useEffect, useContext } from 'react';
import { JobAppContext } from '../../GlobalState';

export const LocationField = ({
	value = "0",
	change,
	readOnly
}) => {
	const { locations, loadingLocations, update_contacts } = useContext(JobAppContext);

	const [ selectedLocation, setSelectedLocation ] = useState(value);
	const [ location_label, setLocationLabel ] = useState('');

	// handle changes made to locations/location
	useEffect(() => {
		if ( selectedLocation ) {
			updateLocationLabel(selectedLocation);
		}
	}, [locations])

	useEffect(() => {
		// console.log('useEffect (location)', location, locations)
		if ( selectedLocation ) {
			updateLocationLabel(selectedLocation);
			if ( selectedLocation !== "0" ) {
				update_contacts(selectedLocation);
			}
		}
	}, [selectedLocation]);

	const updateLocationLabel = (div_id) => {
		var loc_label = '';
		if ( div_id == 0 ) {
			loc_label = '< No Location Selected >';
		} else {
			let found = locations.find(div => div.DIV_ID == div_id);
			// console.log('found', found);
			if ( found ) {
				loc_label = found.cli_no +" - "+ found.COMPANY +" "+ found.COMPANY2 +" (" +found.MAINCITY+ ", " + found.MAINSTATE + ")"
				if ( found.cust1_lbl.length > 0 ) {
					loc_label += " - " +found.cust1_lbl+ " " +found.cust1
				}
			} else {
				loc_label = 'Description Not Found (' +div_id+ ')';
			}
		}
		setLocationLabel(loc_label);
	}

	const onChange = (e) => {
		setSelectedLocation(e.target.value);
		change(e);
	}

	return (
		<div className="form-row mb-3">
			<div className="col">
				{loadingLocations ? (
					<>
						<i className="fas fa-sync-alt fa-spin mr-1" ></i> 
						Loading Locations
					</>
				) : (
					<div className="Input">
						<label>
							Location
							<i className="far fa-question-circle ml-2" title="This is the division this project will display under in OLS"></i>
						</label>
						{readOnly ? (
							<span>{location_label}</span>
						) : (
							<select
								name="selectedLocation"
								value={selectedLocation}
								className="selectInput form-control"
								onChange={(e) => onChange(e)}
							>
								<option value="0">{'< No Location Set >'}</option>
								{locations?.map((location, index) => {
									if (location.ACTIVE == "1") {
										let loc_label = location.cli_no +" - "+ location.COMPANY +" "+ location.COMPANY2 +" (" +location.MAINCITY+ ", " + location.MAINSTATE + ")"
										if ( location.cust1_lbl.length > 0 ) {
											loc_label += " - " +location.cust1_lbl+ " " +location.cust1
										}
										return ( <option key={index} value={location.DIV_ID}>{loc_label}</option> )
									}
								})}
							</select>
						)}
					</div>
				)}
			</div>
		</div>
	)
}