import React, { Component } from 'react';
import './styles/FileDropper.css'

export default class FileDropper extends Component {
  
  constructor(props) {
    super(props);
    this.dragEnterCounter = 0;
  }

  setMouseIsOver(value) {
		if ( this.mouseIsOver != value ) {
      
      this.mouseIsOver = value;
      
      if(this.props.setDragState) {
        this.props.setDragState(this.mouseIsOver);
      }
    }
	}

  dragEnterHandler(ev) {
    this.dragEnterCounter++;
  }

  dragOverHandler(ev) {
    // console.log(ev);
    if (ev.dataTransfer.items) {
      
      console.log('in ev.dataTransfer.items');

      if ( ev.dataTransfer.items[0].kind == 'file' )
        this.setMouseIsOver(true);
    } else {

      console.log('in else of ev.dataTransfer.items');

      if ( ev.dataTransfer.files ) {
        console.log('in ev.dataTransfer.files', ev.dataTransfer.files);

        for (let i = 0; i < ev.dataTransfer.files.length; i++) {
          console.log('  -> files[' +i+ ']: ' + ev.dataTransfer.files[i]);
        }

      }

      if ( ev.dataTransfer.files[0] ) {
        console.log('in ev.dataTransfer.files[0]');
        this.setMouseIsOver(true);
      }
      
    }

    console.log('after condition');
    ev.preventDefault();
  }

  dropHandler(ev) {
    ev.preventDefault();
    this.setMouseIsOver(false);
    this.files = [];
    
    console.log("drop");

    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (ev.dataTransfer.items[i].kind === 'file') {
          this.files.push(ev.dataTransfer.items[i].getAsFile());
        }
      }
      if (this.props.fileRetrieved) {
        this.props.fileRetrieved(this.files);
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.files.length; i++) {
        // console.log(ev.dataTransfer.files[i]);
        this.files.push(ev.dataTransfer.files[i]);
      }
      if (this.props.fileRetrieved) {
        this.props.fileRetrieved(this.files);
      }
    }
    this.dragEnterCounter--;
    
    // Pass event to removeDragData for cleanup
    this.removeDragData(ev);
  }

  clickHandler(ev) {
    this.props.onClick();
  }

  dragLeaveHandler(event) {
    // console.log(event);
    this.dragEnterCounter--;
    if(this.dragEnterCounter == 0) {
      this.setMouseIsOver(false);
    }
  }

  removeDragData(ev) {
		if (ev.dataTransfer.items) {
		  // Use DataTransferItemList interface to remove the drag data
      ev.dataTransfer.items.clear();
      this.files = null;
		} else {
		  // Use DataTransfer interface to remove the drag data
      ev.dataTransfer.clearData();
      this.files = null;
		}
	}

  render() {
    return(
      <div
        id="dropzone" 
        onDragEnter={(event)=>{this.dragEnterHandler(event)}} 
        onDrop={(event) => {this.dropHandler(event)}} 
        onDragOver={(event) => {this.dragOverHandler(event)}} 
        onDragLeave={(event)=>{this.dragLeaveHandler(event)}} 
        onClick={(event)=>{this.clickHandler(event)}} 
        className={this.props.class}
      >
        {this.props.children}
      </div>
    )
  }
}
