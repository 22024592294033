import React, { useState, useEffect, useContext } from 'react'
import { Redirect, useParams } from "react-router-dom";

import {
	Nav, NavLink, NavItem,
	Modal, ModalHeader, ModalBody, ModalFooter, Button
} from 'reactstrap'

import AddJobButton from '../partials/AddJobButton'

import { Form } from '../../components'

import { classnames } from '../../utils/classnames'
import { /* canSubmitJobs, */ searchJobs } from '../../api'

import { JobList } from '../../components/job/JobList'
import { JobAppContext } from '../../GlobalState'

const tabs = {
	OPEN: 'open',
	REVIEW: 'pending',
	SUBMITTED: 'submitted',
	ARCHIVED: 'archived',
	SEARCH: 'search',
}

const JobsPage = (props) => {

	const params = useParams();

	const { 
		user,
		jobsPage, isLoading, fetchJobList, postJob
	} = useContext(JobAppContext);
	
	const [ isSubmitting, setIsSubmitting ] = useState(false);
	const [ jobCreated, setJobCreated ] = useState(false);

	const [ search, setSearch ] 			= useState([]);
	const [ activeTab, setActiveTab ] 		= useState(params?.list ? tabs[params?.list.toUpperCase()] : tabs.OPEN);
	
	const [ searchCount, setSearchCount ] 	= useState(null);
	const [ searchTerm, setSearchTerm ] 	= useState('');

	const [ list_count, setListCount ] 		= useState(user?.list_count ?? 100);
	const [ column_choice, setColumnChoice ] = useState(user?.column_choice ?? 'form_variant');

	const [ linkModal, setLinkModal ] 		= useState(false);
	const [ previewLabel, setPreviewLabel ] = useState('');
	const [ previewLink, setPreviewLink ] 	= useState('');
	
	const [ copied, setCopied ] 			= useState(false);

	// anytime you change tabs, query for an updated list of jobs
	useEffect(() => {
		
		if ( params?.list ) {
			var urlParm = tabs[params.list.toUpperCase()];
			setActiveTab(urlParm);
			fetchJobList(urlParm);
		} else {
			setActiveTab("open");
			fetchJobList(activeTab);
		}

	}, [params?.list])

	const handleSubmit = (formdata) => {
		setIsSubmitting(true);
		postJob({ ...formdata }).then(data => {
			if ( data?.history_record?.previewLink != '' ) {
				setPreviewLabel(data.history_record.previewLabel)
				setPreviewLink(data.history_record.previewLink)
				setLinkModal(true);
			} else {
				setJobCreated(data.id); // setting this value will redirect user to that job
			}
			setIsSubmitting(false);
		});
	}
	
	/* ************************************************************************ */
	/*  Search Functions                                                        */
	/* ************************************************************************ */
	const clearSearch = () => {
		setSearchTerm('');
		setSearch([]);
		setSearchCount(null);
		setActiveTab(tabs.OPEN);
	}

	const searchChange = event => {
		const { target } = event
		const { name, value, checked } = target
		setSearchTerm(value);
	}

	const submitSearch = () => {
		var activeTab = tabs.OPEN;

		let search_term = searchTerm.toLowerCase()
		if ( search_term!= '' ) {
			activeTab = tabs.SEARCH

			setActiveTab(activeTab);
			searchJobs({ 'query': search_term }).then(data => {
				setSearch(data.found_jobs);
				setSearchCount(data.resultCount);
			})
		}
	}
	/* ************************************************************************ */

	const toggleLinkModal = () => {
		setLinkModal(!linkModal);
	}

	const copyLink = (shareLink) => {
		let textArea = document.createElement('textarea');
		textArea.setAttribute('style','width:1px;border:0;opacity:0;');
		document.body.appendChild(textArea);
		textArea.value = shareLink;
		textArea.select();
		textArea.focus();
		document.execCommand('copy');
		document.body.removeChild(textArea);
		setCopied(true);
	}

	const closeLinkModal = () => {
		setCopied(false);
		setLinkModal(false);
	}

	const changeTab = (tab) => {
		console.log('switch to tab: ', tab)
		setActiveTab(tab);
	}
	
	const tab_label = (list) => {
		var label = "Open";
		switch (list) {
			case "open": label = "Open"; break;
			case "pending": label = "Review"; break;
			case "submitted": label = "Submitted"; break;
			case "archived": label = "Archived"; break;
			case "search": label = "Search"; break;
		}
		return label;
	}

	const { open, pending, submitted, archived, error } = jobsPage
	
	const company = user?.memberships[0].company;
	const reference_label = company?.reference_label != '' ? company.reference_label : 'Customer Reference #';

	return (
		<>
			{jobCreated && <Redirect to={`/job/${jobCreated}`} />}
			{!user && <Redirect to="/login" />}

			<Modal isOpen={linkModal} toggle={closeLinkModal} style={{ 'maxWidth': '650px' }}>
				<ModalHeader toggle={closeLinkModal}>Job Created!</ModalHeader>
				<ModalBody>
					<div>
						<label>{previewLabel}</label>
						<div style={{fontSize: '14px'}}>{previewLink}</div>
					</div>
				</ModalBody>
				<ModalFooter>
					{copied && (
						<i className="far fa-check-circle" 
							style={{ color: '#007bff', fontSize: '24px', fontWeight: 'bold', marginLeft: '10px', verticalAlign: 'middle' }} 
							title="Copied!"></i>
					)}
					<Button color="primary" className="Button float-left" onClick={() => copyLink(previewLink)}>
						<i className="far fa-copy font-weight-bold mr-2"></i> 
						Copy
					</Button>
					<Button color="secondary" className="Button float-right" onClick={closeLinkModal}>
						Close Window
					</Button>
				</ModalFooter>
			</Modal>

			<div className="page-navbar d-flex justify-content-between p-3">
				<div>
					<AddJobButton submit={handleSubmit} isSubmitting={isSubmitting} />
				</div>
				<Form onSubmit={submitSearch} className="mr-5">
					<div className="form-row">
						<div id="search_container">
							<input type="text" name="searchField" id="searchField" value={searchTerm} onChange={searchChange} 
									placeholder={isLoading ? "Loading Jobs..." : "Search job lists here"} disabled={isLoading} />
							<div className="bg-light d-inline-block" style={{ width: '27px', visibility: searchTerm != '' ? 'inherit' : 'hidden' }}>
								<span role="button" onClick={clearSearch}>
									<i className="fas fa-times-circle"></i>
								</span>
							</div>
						</div>
						<button className="btn btn-primary ml-2" type="submit" disabled={isLoading ? 'disabled' : ''}>Search</button>
					</div>
				</Form>
			</div>
			
			<div className="padded-content mt-5 pt-5">
				
				<h2>{tab_label(activeTab)} Job List</h2>
				
				{/*
				<Route path={"/" + activeTab}>
					<JobList status={activeTab} jobs={jobsPage[activeTab]} isFetching={isLoading} error={error} options={{
						'list_count': list_count,
						'column_choice': column_choice,
						'reference_label': reference_label
					}} />
				</Route>
				*/}

				{activeTab === tabs.OPEN && (
					<JobList status="open" jobs={open} isFetching={isLoading} error={error} options={{
						'list_count': list_count,
						'column_choice': column_choice,
						'reference_label': reference_label
					}} />
				)}

				{activeTab === tabs.REVIEW && (
					<JobList status="pending" jobs={pending} isFetching={isLoading} error={error} options={{
						'list_count': list_count,
						'column_choice': column_choice,
						'reference_label': reference_label
					}} />
				)}

				{activeTab === tabs.SUBMITTED && (
					<>
						{company.archive_length >= 0 && (
							<cite className="d-block mx-2 my-3">
								Note: Jobs archived after: {company.archive_length + " " + company.archive_duration + "(s)"}
							</cite>
						)}
						<JobList status={"submitted"} jobs={submitted} isFetching={isLoading} error={error} options={{
							'list_count': list_count,
							'column_choice': column_choice,
							'reference_label': reference_label
						}} />
					</>
				)}

				{activeTab === tabs.ARCHIVED && (
					<JobList status={"archived"} jobs={archived} isFetching={isLoading} error={error} options={{
						'list_count': list_count,
						'column_choice': column_choice,
						'reference_label': reference_label
					}} />
				)}

				{activeTab === tabs.SEARCH && (
					<>
						{!isNaN(searchCount) && searchCount > 0 && (
							<div className="pl-3 my-3" style={{ color: '#007bff' }}>
								<a onClick={clearSearch} style={{ cursor: 'pointer' }}>
									<i className="fas fa-times-circle"></i>
									<span className="ml-2">Clear Search Results ({searchCount} found)</span>
								</a>
							</div>
						)}
						<JobList status="search" jobs={search} isFetching={isLoading} error={error} options={{
							'list_count': list_count,
							'column_choice': column_choice,
							'reference_label': reference_label
						}} />
					</>
				)}

				{isLoading && (
					<div className="mt-3 p-3 rounded" style={{ border: 'solid 1px #212529', width: '400px' }}>
						<i className="fas fa-sync-alt fa-spin mr-1" ></i> Loading Jobs
					</div>
				)}
			</div>
		</>
	)
}
export default JobsPage;