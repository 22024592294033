import React, { Fragment } from 'react'
import { classnames } from '../../utils/classnames'

export const ListDropDown = ({
	label,
	items,
	name,
	onChange,
	selected,
	description = '',
	limit = 5,
	empty = 'There are no items to display...',
	className = '',
	isRequired = false,
	readOnly = false,
}) => {
	if ( !readOnly ) {
		if (!items.length)
			return (
				<div className={className}>
					<label htmlFor={name}>{label} {isRequired === 1 && <span className="required">*</span>}{' '}</label>
					{empty}
				</div>
			)

		if (items.length <= limit) {
			return (
				<Fragment>
					<label htmlFor={name}>{label} {isRequired === 1 && <span className="required">*</span>}{' '}</label>
					{description && (
						<div className="input--description">{description}</div>
					)}
					<div className="dropdownListInline">
						{items.map((item, index) => (
							<div key={item.id} style={{ display: 'inline-block' }}>
								{isRequired == 1 && index == 0 ? (
									<input
										type="radio"
										name={name}
										value={item.id}
										checked={parseInt(selected, 10) === item.id}
										onChange={onChange}
										data-choice={item.value}
										data-ap={item.extra}
										style={{ marginRight: '10px' }}
										required={true}
									/>
								) : (
									<input
										type="radio"
										name={name}
										value={item.id}
										checked={parseInt(selected, 10) === item.id}
										onChange={onChange}
										data-choice={item.value}
										data-ap={item.extra}
										style={{ marginRight: '10px' }}
									/>
								)}
								<label key={item.id} className="form-check-label">
									{item.name}
								</label>
							</div>
						))}
						<div className="invalid-feedback" />
					</div>
				</Fragment>
			)
		}

		return (
			<div className={classnames('Input', className)}>
				<label htmlFor={name}>{label} {isRequired === 1 && <span className="required">*</span>}{' '}</label>
				{description && (
					<div className="input--description">{description}</div>
				)}
				<select
					onChange={onChange}
					name={name}
					value={selected ? parseInt(selected, 10) : ''}
					className="selectInput form-control"
					required={isRequired === 1 ? true : false}
				>
					<option value="" data-choice={''} data-ap={0}>&lt; choose an option &gt;</option>
					{items.map(item => (
						<option key={item.id} value={item.id} data-choice={item.value} data-ap={item.extra}>
							{item.name ? item.name : item.title}
						</option>
					))}
				</select>
			</div>
		)
	} else {

		// print selected option in readonly mode
		return (
			<Fragment>
				<label>{label} {isRequired === 1 && <span className="required">*</span>}{' '}</label>
				{items.map(item => {
					if ( parseInt(selected, 10) === item.id ) {
						return (
							<span key={item.id}>{item.name ? item.name : item.title}</span>
						)
					}
				})}
			</Fragment>
		)

	}
  
}

