// Users can freely enter text or select from dropdown list provided
import React from 'react';

const DataList = (props) => {
	const {
		list, 
		id, 
		classNames, 
		options, 
		placeHolder, 
		value, 
		initialFilter, 
		style, 
		onChange, 
		disabled
	} = props;

	return (
		<>
			<input 
				type =        "text"
				list =        { list }  
				className =   { "form-control " + ( classNames ? classNames : "" ) }
				id =          { id            ? id : null }
				placeholder = { placeHolder   ? placeHolder : "" }
				value =       { value ? value : initialFilter ? initialFilter : "" }
				style =       { style         ? style : {} }
				onChange =    { onChange      ? onChange : null }
				disabled =    { disabled      ? disabled : false }
			/>
			<datalist id={list}>
				{options.map((option, i) => {
					return (
						<option 
							key = { i } 
							value = { option.value }
						>
							{ option.alt }
						</option>
					);
				})}
			</datalist>
		</>
	)
}
export default DataList;