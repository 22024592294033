import React, { Component } from 'react'
import { Input } from './Input'
import { classnames } from '../../utils/classnames'

const initialState = {
  items: [],
  active: 0,
  placeAddress: {
    address: '',
    city: '',
    state: '',
    zip: '',
    county: '',
  },
  // bounds: {},
}

const googleComponents = [
  { googleComponent: 'locality', location: 'city', type: 'long_name' },
  {
    googleComponent: 'administrative_area_level_1',
    location: 'state',
    type: 'short_name',
  },
  { googleComponent: 'postal_code', location: 'zip', type: 'long_name' },
  {
    googleComponent: 'administrative_area_level_2',
    location: 'county',
    type: 'long_name',
  },
]

// Bias the autocomplete object to the user's geographical location,
// as supplied by the browser's 'navigator.geolocation' object.
function geolocate() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(function(position) {
      var geolocation = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };
      var circle = new google.maps.Circle(
          {center: geolocation, radius: position.coords.accuracy});
      // autocomplete.setBounds(circle.getBounds());
      
      return circle.getBounds()
      /* this.setState({ bounds: circle.getBounds() }) */
    });
  }
}

class AutocompletePlaces extends Component {
  constructor(props) {
    super(props)
    this.state = initialState
  }

  componentDidMount() {

    /*
    if (!window.google) {
      throw new Error('Google Maps Javascript API must be loaded.')
    }

    if (!window.google.maps.places) {
      throw new Error('Google Maps Places library must be loaded')
    }
    */

    try {
      
      this.autocompleteService = new window.google.maps.places.AutocompleteService()
      this.placeService = new window.google.maps.places.PlacesService(
        document.createElement('div')
      )
      this.autocompleteOK = window.google.maps.places.PlacesServiceStatus.OK

    } catch (e) {
      console.log('Error in Google Maps: ', e.message)
    }
    
    
    // let geoLoc = geolocate()
    /*
    var geoLoc = new google.maps.LatLngBounds(
      new google.maps.LatLng(-33.8902, 151.1759),
      new google.maps.LatLng(-33.8474, 151.2631)
    )
    this.setState({
      bounds: geoLoc,
    })
    */

  }

  handlePredictions = (predictions, status) => {
    if (status !== this.autocompleteOK) {
      this.setState(initialState)
      return
    }

    const formattedSuggestion = text => ({
      name: text.main_text,
      body: text.secondary_text,
    })

    this.setState({
      items: predictions.map((place, index) => ({
        index,
        suggestion: place.description,
        placeId: place.place_id,
        raw: place,
        name: formattedSuggestion(place.structured_formatting),
      })),
    })
  }

  getPredictions = value => {
    // value
    
    /*
    let texas = new google.maps.LatLngBounds(
      new google.maps.LatLng(-33.8902, 151.1759),
      new google.maps.LatLng(-33.8474, 151.2631));
    */
   
    if (value.length) {
      try {
        this.autocompleteService.getPlacePredictions(
          {
            input: value,
            // bounds: texas,
            // bounds: this.state.bounds,
          },
          this.handlePredictions
        )
      } catch(e) {
        console.log('getPlacePredictions error: ', e.message);
      }
    }
  }

  handleChange = onChange => event => {
    event.preventDefault()

    const { value } = event.target

    if (value.length) {
      this.getPredictions(value)
    } else {
      this.setState({
        items: [],
      })
    }

    onChange(event)
  }

  fireEvent = location => {
    const { onChange, name } = this.props
    onChange({
      target: {
        type: 'text',
        name: name + '.address.' + location,
        value: this.state.placeAddress[location],
      },
    })
  }

  selectAddress = (place, index) => {
    const { placeId } = place

    this.placeService.getDetails({ placeId }, (event, status) => {
      // this maps the google value to our send state
      // ugly..
      googleComponents.forEach(component => {
        event.address_components.forEach(addressComponent => {
          if (addressComponent.types[0] === component.googleComponent) {
            this.setState(prevState => ({
              placeAddress: {
                ...prevState['placeAddress'],
                [component.location]: addressComponent[component.type],
              },
            }))
          }
        })
      })

      this.setState(prevState => ({
        placeAddress: {
          ...prevState['placeAddress'],
          address: place.name.name,
        },
      }))

      this.fireEvent('address')
      this.fireEvent('city')
      this.fireEvent('state')
      this.fireEvent('zip')
      this.fireEvent('county')
    })
  }

  handleBlur = event => {
    this.setState({
      items: [],
    })
  }

  handleKeyDown = e => {
    const { active } = this.state
    const { key } = e

    if (key === 'ArrowUp' && active > 0) {
      this.setState(prevState => ({
        active: prevState.active - 1,
      }))
    } else if (key === 'ArrowDown' && active < 4) {
      this.setState(prevState => ({
        active: prevState.active + 1,
      }))
    } else if (key === 'Enter') {
      this.selectAddress(this.state.items[active], active)
      this.setState({
        items: [],
      })
    }
  }

  render() {
    const { name, onChange, value, label = '' } = this.props
    const { items } = this.state

    return (
      <div className="autocomplete">
        <Input
          label={'Street Address'}
          placeholder="Start typing an address..."
          name={name + '.address.address'}
          className="address-autocomplete"
          onChange={this.handleChange(onChange)}
          isRequired={this.props.isRequired}
          type="text"
          value={value?.address ?? ''}
          onBlur={this.handleBlur}
          onKeyDown={this.handleKeyDown}
          autoComplete="off"
          // onFocus={() => { this.setState({ bounds: geolocate() }) }}
        />
        {items.length > 0 && (
          <div
            className={classnames(
              'autocomplete-container',
              this.state.active === 0 && 'afterActive'
            )}
          >
            {items.map((place, index) => (
              <div
                className={classnames('autocomplete-item', this.state.active === index && 'activeComplete')}
                key={place.placeId}
                onMouseOver={() => this.setState({ active: index })}
                onMouseDown={() => this.selectAddress(place, index)}
                onTouchStart={() => console.log('start')}
                onTouchEnd={() => console.log('end')}
              >
                <div className={classnames('autocomplete-address', this.state.active === index && 'activeComplete')}>
                  {place.name.name} {/* index */}
                </div>
                {place.name.body}
              </div>
            ))}

            <div className="autocomplete-item autocomplete-footer">
              Powered by <b>Google</b>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default AutocompletePlaces
