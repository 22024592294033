import React, { Fragment, useState, useEffect, useContext } from 'react';
import { JobAppContext } from '../../GlobalState';

import { getAllForms, listCustomers } from '../../api'

import moment from 'moment'
import Datetime from 'react-datetime'

import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

import { Form, Input } from '../../components'
import { Checkbox } from '../partials'
import { ListDropDown } from '../partials/ListDropDown';
import DataList from '../../components/Form/DataList'

import { ContactField } from '../partials/ContactField';
import { LocationField } from '../partials/LocationField';

const AddJobButton = (props) => {

	const { user, contacts, locations } = useContext(JobAppContext);

	const company = user.memberships[0].company;
	const autofill_label = company.autofill_label != '' ? company.autofill_label : 'Customer Auto-fill';
	const reference_label = company.reference_label != '' ? company.reference_label : 'Customer Reference #';

	const defaultFormValues = {
		name 				: '',
		form_id				: 0,
		form_variant_id		: 0,
		reference_no		: '',
		selectedLocation	: "0",
		selectedContact		: "0",
		searchQuery			: '',
		customer_id			: 0,
		first_furnishing	: moment().format('L'),
		ff_est				: 0,
		last_furnishing		: moment().add(1, 'week').format('L'),
		lf_est				: 0,
		total_contract_amt	: '0.00',
		total_claim_amt		: '0.00'
	};

	const [ openClose, setOpenClose ] = useState(false);
	const [ advanced_options, setAdvOpts ] = useState(false);

	const [ forms, setForms ] = useState([]);
	const [ form_variants, setFormVariants ] = useState([]);

	const [ customers, setCustomers ] = useState([]);

	const [ addJobForm, setAddJobForm ] = useState(defaultFormValues);

	// const [ workflow_id, setWorkflowID ] = useState(0);
	// const [ selected, setSelected ] = useState([]);
	// const [ title, setTitle ] = useState('');

	useEffect(() => {

		var form_id = 0;
		var form_var_id = 0;

		getAllForms().then(data => {
			var mapped_forms = data.filter(data => data.mapped != 0);

			// if forms are found, set the default-selected form (for adding new jobs) to the first one found
			form_id = mapped_forms.length ? mapped_forms[0].id : null
			form_var_id = addJobForm.form_variant_id
			var variant_list = [];

			mapped_forms.map(oData => {
				if ( oData.id == form_id ) {
					variant_list.push( ...oData['form_variant'] )
				}
			})
			if ( variant_list.length ) {
				if ( form_var_id == 0 ) {
					form_var_id = variant_list[0].id
				}
				setFormVariants(variant_list);
			}
			setForms(mapped_forms);
			setAddJobForm({
				...addJobForm,
				form_id: form_id,
				form_variant_id: form_var_id,
				selectedContact: user?.cnt_id > 0 ? user?.cnt_id : contacts && contacts[0]?.CNT_ID,
				selectedLocation: locations.length > 0 && locations[0]?.DIV_ID
			})

		}, error => console.error(error))

	}, [])

	useEffect(() => {
		setAddJobForm({
			...addJobForm,
			selectedContact: user?.cnt_id != 0 ? user?.cnt_id : contacts[0]?.CNT_ID
		});
	}, [contacts])

	useEffect(() => {
		if ( locations.length > 0 ) {
			setAddJobForm({
				...addJobForm,
				selectedLocation: locations[0]?.DIV_ID
			});
		}
	}, [locations])

	// useEffect for opening the advanced options section
	useEffect(() => {
		// if opening the modal's "advanced options" section -> load customers if not already there
		if ( advanced_options ) {
			if ( customers.length === 0 ) {
				listCustomers().then(customers => {
					var customer_list = customers.map(customer => {
						return {
							id: customer.id,
							contact_role: customer.contact_role,
							role_desc: customer.role_desc,
							address: {
								company   : customer.company,
								name      : customer.name,
								phone     : customer.phone,
								address   : customer.address,
								address_2 : customer.address_2,
								city      : customer.city,
								state     : customer.state,
								zip       : customer.zip,
								county    : customer.county,
								email     : customer.email
							}
						}
					});
					setCustomers(customer_list);
				});
			}
		}
	}, [advanced_options]);

	const toggleModal = (openClose) => {
		setOpenClose( !openClose )
	}

	const handleChange = event => {
		const { target } = event;
		const { type, name, value, checked } = target;

		if ( type == "checkbox" ) {
			if ( checked ) {
				setAddJobForm({ ...addJobForm, [name]: true });
			} else {
				setAddJobForm({ ...addJobForm, [name]: false });
			}
		} else {
			setAddJobForm({ ...addJobForm, [name]: value });
		}
		
	}

	const handleSubmit = (sendLink) => {
		
		props.submit({
			...addJobForm,
			createLink: sendLink ?? false
		});
		
		// TODO: clear these fields and close the form AFTER above submit finishes?
		// reset most of the Add Job form fields
		setAddJobForm({
			...addJobForm, 
			name              : '',
			reference_no      : '',
			searchQuery       : '',
			customer_id       : 0,
			first_furnishing  : moment().format('L'),
			ff_est            : 0,
			last_furnishing   : moment().add(1, 'week').format('L'),
			lf_est            : 0,
			total_contract_amt: '0.00',
			total_claim_amt   : '0.00'
		})
		setOpenClose(false);
	}

	const update_first_furnishing = value => {
		let new_date = moment(value);
		if ( value == '' || !new_date.isValid() ) {
			setAddJobForm({ ...addJobForm, first_furnishing: '' });
		} else {
			setAddJobForm({ ...addJobForm, first_furnishing: new_date.format('L') });
		}  
	}

	const update_last_furnishing = value => {
		let new_date = moment(value);
		if ( value == '' || !new_date.isValid() ) {
			setAddJobForm({ ...addJobForm, last_furnishing: '' });
		} else {
			setAddJobForm({ ...addJobForm, last_furnishing: new_date.format('L') });
		}
	}
  

	const findVariants = target_id => {
		var found_variants = []
		forms.map(form => {
			if ( form.id == target_id && form.form_variant.length > 0 ) {
				found_variants.push(...form.form_variant)
			}
		})
		if ( !found_variants.length ) {
			found_variants.push({
				"id":     0,
				"title": 'Default',
				"file_id": 9,
				"form_id": parseInt(target_id)
			})
		}
		return found_variants;
	}

	const updateContact = e => {
		const { value } = e.target;
		setAddJobForm({
			...addJobForm,
			selectedContact: value
		});
	}

	const updateLocation = e => {
		const { value } = e.target;
		
		if ( value == "0" ) {
			setAddJobForm({
				...addJobForm,
				selectedContact: 0,
				selectedLocation: 0
			});
		} else {
			setAddJobForm({
				...addJobForm,
				selectedContact: value
			});
		}
	}

	const createSearchList = () => {
		let list = [];
		if( customers ) {
			customers.forEach(cust => {
				let customer_label = cust.address.company + ' (' +cust.address.name+ ')';
				if (cust.role_desc != '') {
					customer_label += ' - ' + cust.role_desc;
				}
				list.push({
					value: cust.id,
					alt: customer_label
				})
			});
		}
		return list;
	}

	const chooseCustomer = e => {
		const { target } = e
		const { value } = target

		if ( !isNaN(value) ) {
			var cust_opt_label = '';
			var chosen_item = parseInt(value);
			// get the customer's label from the customer's array stored in state
			customers.map(cust => {
				if (cust.id == chosen_item) {
					cust_opt_label = cust.address.company + " (" +cust.address.name+ ")";
					if (cust.contact_role != 0) {
						cust_opt_label += " - " +cust.role_desc;
					}
				}
			});
			setAddJobForm({ ...addJobForm, customer_id: chosen_item, searchQuery: cust_opt_label });
		} else {
			setAddJobForm({ ...addJobForm, customer_id: 0, searchQuery: value });
		}
	}

  	return (
    
    	<Fragment>

			<Button color="primary" disabled={ props.isSubmitting } onClick={() => { setOpenClose(true) }}>
				{props.isSubmitting ? 'Creating Job...' : 'Create Job'}
			</Button>

			{/*
			<Modal isOpen={this.props.jobs.showJobLinkModal} toggle={this.closeLinkModal} style={{ 'maxWidth': '650px' }}>
				<ModalHeader toggle={this.closeLinkModal}>Job Created!</ModalHeader>
				<ModalBody>
					<div>
						<label>{this.props.jobs.linkModal.previewLabel}</label>
						<div style={{fontSize: '14px'}}>{this.props.jobs.linkModal.previewLink}</div>
					</div>
				</ModalBody>
				<ModalFooter>
					{this.state.copied && (
						<i className="far fa-check-circle" 
							style={{ color: '#007bff', fontSize: '24px', fontWeight: 'bold', marginLeft: '10px', verticalAlign: 'middle' }} 
							title="Copied!"></i>
					)}
					<Button color="primary" className="Button float-left" onClick={() => this.copyLink(this.props.jobs.linkModal.previewLink)}>
						<i className="far fa-copy historyShareTitle"></i> 
						Copy
					</Button>
					<Button color="secondary" className="Button float-right" onClick={this.closeLinkModal}>
						Close Window
					</Button>
				</ModalFooter>
			</Modal>
			*/}
    
			<Modal isOpen={openClose} toggle={toggleModal} style={{ 'maxWidth': '650px' }}>
				<Form onSubmit={handleSubmit}>
					<ModalHeader toggle={toggleModal}>
						<span>Add New Job</span>
					</ModalHeader>
					<ModalBody>
						<div className="pr-3">
							<div className="form-row mb-3">
								<div className="col">
									<Input
										autoFocus={true}
										name="name"
										onChange={handleChange}
										label="New Job Name"
										type="text"
										value={addJobForm.name}
										isRequired={1}
									/>
								</div>

								{forms.length > 1 && (
									<div className="col">
										<ListDropDown
											name="form"
											label="Available Forms"
											selected={addJobForm.form_id}
											onChange={e => {
												let newVariants = findVariants(e.target.value);
												setAddJobForm({
													...addJobForm,
													form_id: parseInt(e.target.value),
													form_variant_id: newVariants[0].id
												})
												setFormVariants(newVariants)
											}}
											limit={1}
											items={forms}
										/>
									</div>
								)}
							</div>

							{form_variants.length > 1 && (
								<div className="form-row mb-3">
									<div className="col">
										<ListDropDown
											name="form_variant"
											label="Form Logo"
											selected={addJobForm.form_variant_id}
											onChange={e => setAddJobForm({ ...addJobForm, form_variant_id: parseInt(e.target.value) })}
											limit={0}
											items={form_variants}
										/>
									</div>
								</div>
							)}

							{advanced_options && (<Fragment>

								<Fragment>
									<LocationField value={addJobForm.selectedLocation} change={updateLocation} readOnly={!user.api_key || false} />
									<ContactField value={addJobForm.selectedContact} change={updateContact} readOnly={!user.api_key || false} />
								</Fragment>
								
								<div className="form-row mb-3">
									<div className="col-12">
										<div className="Input">
											<div className="Input--label">{reference_label}</div>
											<Input type="text" name="reference_no" value={addJobForm.reference_no} onChange={handleChange} />
										</div>
									</div>
								</div>

								{customers.length > 0 && (
									<div className="form-row mb-3">
										<div className="col-12">
											<div className="Input--label">{autofill_label}</div>
											<div className="input-group" role="group">
												<DataList
													options = {createSearchList()}
													list = "document-types"
													placeHolder = "Search Customers"
													value = { addJobForm.searchQuery }
													onChange = {chooseCustomer}
													disabled={ addJobForm.customer_id > 0 }
												/>
												<span className="input-group-append">
													<button onClick={() => {
														setAddJobForm({
														...addJobForm,
														searchQuery: "",
														customer_id: 0
														})
													}} type="button" className="btn btn-outline-default">
														<i className="fas fa-times"></i>
													</button>
												</span>
											</div>
										</div>
									</div>
								)}

								<div className="form-row mb-3">
									<div className="col-4">
										<div className="Input" style={{ marginRight:"20px" }}>
										<div className="Input--label">First Furnishing Date</div>
											<Datetime
												defaultValue={addJobForm.first_furnishing}
												dateFormat="MM/DD/YYYY"
												name="first_furnishing"
												onBlur={update_first_furnishing}
												timeFormat={false}
												closeOnSelect={true}
											/>
											<div style={{ marginLeft: '10px' }}>
												<Checkbox
													onChange={handleChange}
													label="Check if estimated"
													name="ff_est"
													value={addJobForm.ff_est == 1 ? true : false}
												/>
											</div>
										</div>
									</div>
									<div className="col-8">
										<div className="Input">
											<div className="Input--label">Last Furnishing Date</div>
											<Datetime
												defaultValue={addJobForm.last_furnishing}
												dateFormat="MM/DD/YYYY"
												name="last_furnishing"
												onBlur={update_last_furnishing}
												timeFormat={false}
												closeOnSelect={true}
											/>
											<div style={{ marginLeft: '10px' }}>
												<Checkbox
													onChange={handleChange}
													label="Check if estimated"
													name="lf_est"
													value={addJobForm.lf_est == 1 ? true : false}
												/>
											</div>
										</div>
									</div>
								</div>

								<div className="form-row mb-3">
									<div className="col-4">
										<div className="Input" style={{ marginRight:"20px" }}>
											<div className="Input--label">Total Contract Amount</div>
											<Input type="text" name="total_contract_amt" value={addJobForm.total_contract_amt} onChange={handleChange} />
										</div>
									</div>
									<div className="col-8">
										<div className="Input">
											<div className="Input--label">Total Claim Amount (Amount currently owed)</div>
											<Input type="text" name="total_claim_amt" value={addJobForm.total_claim_amt} onChange={handleChange} />
										</div>
									</div>
								</div>
							</Fragment>)}
							
							<div className="form-row mb-3">
								<div className="col">
									<a href="#" onClick={() => { setAdvOpts(!advanced_options); }} style={{ fontSize: '14px' }}>
										{advanced_options ? (
											<span>Collapse Advanced Options &#9650;</span>
										) : (
											<span>Expand Advanced Options &#9660;</span>
										)}
									</a>
								</div>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button className="mr-2" color="primary" disabled={!addJobForm.name.length}>
							Save
						</Button>
						<Button type="button" className="mr-2" color="secondary" disabled={!addJobForm.name.length}
							onClick={() => handleSubmit(true)}
						>
							Save & Send
						</Button>
						<Button color="secondary" onClick={() => {
							setOpenClose(false);
								/*
								setWorkflowID(0);
								setSelected([]);
								setTitle('');
								*/
							}}
						>
							Cancel
						</Button>
					</ModalFooter>
				</Form>
			</Modal>

    	</Fragment>
  	)
}
export default AddJobButton;