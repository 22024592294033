import React, { useState, useEffect } from 'react'

import { Table } from 'reactstrap'
import { DateTime } from "luxon";
import DateField from '../partials/DateField'

import { can } from '../../utils/can'
import { get_user } from '../../user_info';

import { loadReport } from '../../api'

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const COMPANY_STATUS = {
	"demo": false,
	"setup": false,
	"beta": true,
	"active": true,
	"inactive": false,
	"hold": false,
	"closed": false
}

const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

const AdminReportPage = (props) => {
	
	const [ companies, setCompanies ] = useState([]);

	const [ isLoading, setIsLoading ] = useState(false);
	
	const [ startDate, setStartDate ] = useState();
	const [ endDate, setEndDate ] = useState();
	const [ companyStatus, setCompanyStatus ] = useState(COMPANY_STATUS);


	useEffect(() => {
		// user must have the "super" admin permission, to access
		const user = get_user();
		if ( !can(['super'], user) ) {
			props.history.push('/jobs')
		}
	}, [])

	const handleDateChange = (field_id, value) => {
		if ( field_id == 'start_date' ) {
			setStartDate(value);
		} else {
			setEndDate(value);
		}
	}

	const handleChange = e => {
		const { type, name, value, checked } = e.target
		if ( type == "checkbox" ) {
			setCompanyStatus({
				...companyStatus,
				[name]: checked
			})
		} else {
			if ( name == 'start_date' ) {
				setStartDate(value);
			} else {
				setEndDate(value);
			}
			
		}
	}

	const handleSubmit = () => {
		setIsLoading(true)

		const keys = Object.keys(companyStatus);
		const filtered = keys.filter((key) => companyStatus[key])

		const data = {
			start_date: startDate,
			end_date: endDate,
			status: filtered
		}
		// console.log('data', data);

		loadReport(data).then(resp => {
			setIsLoading(false);
			// console.log('response', resp)
			setCompanies(resp);
		});
	}

	const exportToCSV = () => {
		// console.log('dump data')
		const ws = XLSX.utils.json_to_sheet(
			companies.map((x) => {
				
				let open_jobs = x.jobs.find(job => job.status == "open");
				let pending_jobs = x.jobs.find(job => job.status == "pending");
				let submitted_jobs = x.jobs.find(job => job.status == "submitted");
				
				return ({
					"id": x.id,
					"Company Name" : x.name,
					"Status": x.status,
					"Open": open_jobs?.count ?? '-',
					"Pending": pending_jobs?.count ?? '-',
					"Submitted": submitted_jobs?.count ?? '-',
					"": x.recent.length > 0 && x.recent[0].count > 0 ? '(+' + x.recent[0].count + ')' : ''
				})
			})
		);
		const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
		const data = new Blob([excelBuffer], { type: fileType });

		var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();

		today = yyyy + '-' + mm + '-' + dd;

		FileSaver.saveAs(data, "Job App Numbers | " +today+ ".xlsx");
	};

	// will format value from database (YYYY-MM-DD) for display to user (MM/DD/YYYY)
	const formatDate = (date) => {
		if (date === "" || date === undefined || date === null || date.startsWith("0000")) return "";
		const newDate = DateTime.fromSQL(date).toLocaleString();
		return newDate;
	}

    return (
		<div>
	  		<h4 className="Title">Admin Report Page</h4>

			<hr />

			<div style={{ paddingLeft: '20px' }}>

				<div className="row">
					<div className="col-3">
						<div className={'form-group'}>
							<DateField
								name={"startDate"}  
								label={"Start Date"}
								field_id={"start_date"}
								onChangeDate={handleDateChange}
								isRequired={true}
								value={formatDate(startDate) ?? ""}
							/>
						</div>
						<div className={'form-group'}>
							<DateField
								name={"endDate"}  
								label={"End Date"}
								field_id={"end_date"}
								onChangeDate={handleDateChange}
								isRequired={true}
								value={formatDate(endDate) ?? ""}
							/>
						</div>
						<div>
							<div>
								<label htmlFor="companyStatus">Company Status</label>
								<div>
									<input type="checkbox" name="demo" value="Y" checked={companyStatus["demo"]} onChange={handleChange} /> Demo<br />
									<input type="checkbox" name="setup" value="Y" checked={companyStatus["setup"]} onChange={handleChange} /> Setup<br />
									<input type="checkbox" name="beta" value="Y" checked={companyStatus["beta"]} onChange={handleChange} /> Beta<br />
									<input type="checkbox" name="active" value="Y" checked={companyStatus["active"]} onChange={handleChange} /> Active<br />
									<input type="checkbox" name="inactive" value="Y" checked={companyStatus["inactive"]} onChange={handleChange} /> Inactive<br />
									<input type="checkbox" name="hold" value="Y" checked={companyStatus["hold"]} onChange={handleChange} /> Hold<br />
									<input type="checkbox" name="closed" value="Y" checked={companyStatus["closed"]} onChange={handleChange} /> Closed
								</div>
							</div>
						</div>
						<div>
							<button type="submit" name="submitBtn" className="btn btn-primary" onClick={handleSubmit}>
								Submit Report
							</button>
							{companies.length > 0 && (
								<button type="button" name="exportBtn" className="btn btn-secondary ml-3" onClick={exportToCSV}>
									Export (.xlsx)
								</button>
							)}
						</div>
					</div>
					<div className="col-9">
						{isLoading ? (
							<div><i className="fas fa-sync-alt fa-spin mr-1" ></i> Loading Company Information</div>
						) : (
							companies.length > 0 && (
							<Table responsive>
								<thead>
								<tr>
									<th>ID</th>
									<th>Name</th>
									<th>Status</th>
									<th>Open</th>
									<th>Pending</th>
									<th>Submitted</th>
									<th></th>
								</tr>
								</thead>
								<tbody>
								{companies.map( (company, index) => {

									var openCount = '-'; var pendingCount = '-'; var submittedCount = '-';
									company?.jobs?.forEach(stat => {
										switch(stat.status) {
											case "open": openCount = stat.count; break;
											case "pending": pendingCount = stat.count; break;
											case "submitted": submittedCount = stat.count; break;
										}
									})

									return (
										<tr key={index}>
											<td>{company.id}</td>
											<td>{company.name}</td>
											<td>{company.status}</td>
											<td>{openCount}</td>
											<td>{pendingCount}</td>
											<td>{submittedCount}</td>
											<td>
												{company.recent.length > 0 && company.recent[0].count > 0 ? (
													<>( +{company.recent[0].count} )</>
												) : ''}
											</td>
										</tr>
									);
								})}
								</tbody>
							</Table>
							)
						)}    
					</div>
				</div>
			</div>
      	</div>
    )

}
export default AdminReportPage;