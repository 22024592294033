// For use when you want a scrollable div that goes to the bottom of the screen window.
// This dynamically calculates the max-height this div needs to be by doing the following:
// max-height = window.innerHeight - headerHeight - footerHeight [ - props.otherHeight(optional) ]

import React, { Component } from 'react';

export default class ScrollableContainer extends Component {
	constructor(props){
		super(props)

		this.state = {
			id: "scrollableContainer"+Math.random()
		}
	}
    static defaultProps = {
        className:  "",
        // id:         "scrollableContainer"+Math.random(),
        style:      {},
		// bottom: 300,
		hasError:false
    }

    componentDidMount() {
		this.forceUpdate();
		window.addEventListener('resize', ()=>{this.forceUpdate()});
    }

    componentWillUnmount() {
        window.removeEventListener('resize', ()=>{this.forceUpdate()});
    }

    render() {
        const { className, style, children, extra } = this.props;

        var body = document.body.clientHeight
		var windowHeight = window.innerHeight
		var start = windowHeight - 100 < body ? body : windowHeight;

		var div = document.getElementById(this.state.id);
		var footer = document.getElementById('footer')
		footer = footer?footer.clientHeight + 10 : 40

		var top = div ? div.getBoundingClientRect().top : 0;
		// var minHeight = bottom - top
		var extraHeight = extra?extra:0

		var maxHeight = div ? windowHeight - top - footer  : 'auto';
		maxHeight = maxHeight < 400 ? 400 : maxHeight

		// console.log('body height',)
        // console.log({maxHeight,top,footer,windowHeight,body,extraHeight});

        return (
            <div
                className = {className}
                id =        { this.state.id }
                style =     { {...style, maxHeight: maxHeight ? maxHeight : 400, overflowY: "auto"} }
            >
                { children }
            </div>
             
        )
    }
}