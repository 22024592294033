import React, { Fragment, useState, useEffect, useContext } from 'react'
import { useHistory, useParams, Link } from 'react-router-dom'

import { JobAppContext } from '../../GlobalState'
import { Row, Col, Button, Alert } from 'reactstrap'

import { Footer } from '../layout/Footer'

import { Form, Input } from '../partials';

const ResetPage = (props) => {
	
	const { isAuthenticated, requestReset, applyReset, error, clearError } = useContext(JobAppContext);
	const history = useHistory();
	const params = useParams();

	var param_value, param_code, param_email;
	if ( params?.code ) {
		param_value = params?.code.split('|');
		if ( param_value.length == 2 ) {
			param_code = param_value[0];
			param_email = param_value[1];
		} else {
			// error! missing value
			history.push('/login')
		}
	}

	const [ form, setForm ] = useState('email');

	const [ email, setEmail ] = useState(param_email ?? '');
	const [ reset_code, setResetCode ] = useState(param_code ?? '');

	const [ sendingLink, setSendingLink ] = useState(false);
	const [ linkSent, setLinkSent ] = useState(false);

	const [ new_pass, setNewPassword ] = useState('');
	const [ confirm_pass, setConfirmPassword ] = useState('');
	const [ resetError, setResetError ] = useState(null);
	
	useEffect(() => {
		if (isAuthenticated === true) {
			history.push('/jobs')
		}
	}, [isAuthenticated])

	useEffect(() => {
		if ( reset_code !== '' ) {
			setForm('verify');
		}
	}, [reset_code])

	const handleSubmit = () => {
    	clearError()
		setResetError(null);
		setLinkSent(false);
		
		if ( form === "email" ) {
			
			setSendingLink(true)
			requestReset({ email }).then((message) => {
				setSendingLink(false);
				setLinkSent(true);
			})

		} else {
			
			applyReset({ email, reset_code, new_pass, confirm_pass }).then((resp) => {
				if (resp.success) {
					history.push('/' + resp.redirect)
				} else {
					setResetError(resp.message)
					setForm('email');
					history.push('/reset')
				}
			});

		}
		
	}

	return (
		<div style={{ maxWidth: '800px', margin: '40px auto' }}>
			<div style={{ backgroundColor: '#20213b', color: '#FFFFFF', borderRadius: '20px 20px 0 0' }} className="py-1">
				<h4 className="Title">
					<div className="headerLogo text-center">
						<img src="/NCS_Logo.png" alt="NCS Job App" style={{ maxHeight: '125px' }} />
					</div>
				</h4>
			</div>
			<Row>
				<Col>
					<div className="my-3 mx-0 p-5">
						<Form onSubmit={handleSubmit} className="mt-3 mb-5">
							{form == 'email' ? (
								<>
									<h1>Request Password Reset Link</h1>
									{resetError !== null && (
										<Alert color="danger">{resetError}</Alert>
									)}
									{linkSent && (
										<Alert color="success">If this email exists a message with reset link will be sent to it soon.</Alert>
									)}
									<div className="form-group">
										<div className="form-row my-3">
											<div className="col">
												<Input
													label="Email"
													name="email"
													onChange={(e) => setEmail(e.target.value)}
													type="email"
													value={email}
												/>
											</div>
										</div>
									</div>
									<Button type="submit" color="primary" disabled={email === '' || sendingLink}>
										Send Link
									</Button>
									{sendingLink && (
										<><i className="fas fa-sync-alt fa-spin mx-2" ></i> Sending reset link</>
									)}
								</>
							) : (
								<>
									<h1>Reset Password</h1>
									{resetError !== null && (
										<Alert color="danger">{resetError}</Alert>
									)}
									<div className="form-group">
										<div className="form-row my-3">
											<div className="col">
												<Input
													label="New Password"
													name="new_pass"
													onChange={(e) => setNewPassword(e.target.value)}
													type="password"
													value={new_pass}
												/>
											</div>
										</div>
										<div className="form-row my-3">
											<div className="col">
												<Input
													label="Confirm New Password"
													name="confirm_pass"
													onChange={(e) => setConfirmPassword(e.target.value)}
													type="password"
													value={confirm_pass}
												/>
											</div>
										</div>
									</div>
									<input type="hidden" name="reset_code" value={reset_code} />
									<Button type="submit" color="primary" disabled={new_pass !== confirm_pass}>
										Submit Update
									</Button>
								</>
							)}
							<Link to="/login" className="float-right">Back to Login Page</Link>
						</Form>
					</div>
				</Col>
			</Row>
			<Footer />
		</div>
	)
}

export default ResetPage
