import React, { Fragment, useState, useEffect, useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { JobAppContext } from '../../GlobalState'

import {
	updateStatus,
	updateJob,
	jobSettingValues,
	saveUserData,
	clearUserData
} from '../../api'

import { DateTime } from "luxon";
import {
	Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap'
import { Checkbox, Input } from '../partials'
// import DateRange from '../partials/DateRange'
import DateField from '../partials/DateField'

import { ContactField } from '../partials/ContactField';
import { LocationField } from '../partials/LocationField';

const formatMoney = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	minimumFractionDigits: 2
})

const JobSettings = (props) => {

	const { user } = useContext(JobAppContext);

	const params = useParams();
	const history = useHistory();

	// const [ jobSettings, setJobSettings ] = useState({});

	const [ id, setId ] = useState(params.id);
	
	const [ status, setStatus ] = useState('');

	const [ directNo, setDirectNo ] = useState('');
	const [ created_at, setCreatedAt ] = useState('');
	const [ updated_at, setUpdatedAt ] = useState('');

	const [ status_display, setStatusDisplay ] = useState('Open');
	const [ progress, setProgress ] = useState(0);

	const [ saved, setSaved ] = useState(false);
	const [ isSaving, setIsSaving ] = useState(false);

	const [ isLoading, setIsLoading ] = useState(true);
	const [ isBlocking, setIsBlocking ] = useState(false);
	const [ readonly, setReadonly ] = useState(props?.readonly ?? true);

	const [ form_variant_id, setFormVariantId ] = useState(0);
	const [ form, setForm ] = useState({
		form_variant: [
			{
				"id":     0,
				"title": 'Default',
				"file_id": 9,
				"form_id": props.id,
				file: {}
			}
		]
	});

	/*
	const [ logoModal, setLogoModal ] = useState(false);
	const [ logo_updated, setLogoUpdated ] = useState(false);
	const [ logo_location, setLogoLocation ] = useState('');
	*/

	const [ name, setName ] = useState('');
	const [ reference_no, setReferenceNo ] = useState('');
	const [ job_user, setJobUser ] = useState({});

	const [ contact, setContact ] = useState(0);
	const [ location, setLocation ] = useState(0);

	const [ first_furnishing, setFirstFurnishing ] = useState('');
	const [ ff_est, setFfEst ] = useState(0);
	const [ last_furnishing, setLastFurnishing ] = useState('');
	const [ lf_est, setLfEst ] = useState(0);

	const [ total_claim_amt, setTotalClaimAmt ] = useState('0.00');
	const [ total_contract_amt, setTotalContractAmt ] = useState('0.00');

	const [ user_submitted, setUserSubmitted ] = useState({});

	const [ prev_variant_id, setPrevVariantId ] = useState(0);
	const [ prev_location, setPrevLocation ] = useState('');

	const [ errorFound, setErrorFound ] = useState(false);
	const [ errorMsg, setErrorMsg ] = useState('');

	useEffect(() => {

		jobSettingValues(id).then(settings => {

			setName(settings.name);
			setStatus(settings.status);

			let status_display = '';
			switch(settings.status) {
				case "open": status_display = "Open"; break;
				case "pending": status_display = "Review"; break;
				case "submitted": status_display = "Submitted"; break;
				case "archived": status_display = "Archived"; break;
			}
			setStatusDisplay(status_display);

			// setDirectNo(settings.directNo);
			// setCreatedAt(settings.created_at);
			// setUpdatedAt(settings.updated_at);

			setReferenceNo(settings.reference_no);
			setJobUser(settings?.user ?? {
				id: 0, name: "Guest"
			});

			setForm(settings.form);

			setFormVariantId(settings.form_variant_id);
			// 
			// var logo_loc = '';
			// settings.form.form_variant.map(variant => {
			// 	if ( variant.id == settings.form_variant_id && variant.file ) {
			// 		logo_loc = window.app_api+ "/" +variant.file.location
			// 	}
			// })
			// setLogoLocation(logo_loc);
			// 

			setLocation(settings.location ?? 0); // will trigger useEffect
			setContact(settings.contact ?? 0); // will trigger useEffect

			setFirstFurnishing(settings.first_furnishing);
			setFfEst(settings.ff_est);
			setLastFurnishing(settings.last_furnishing);
			setLfEst(settings.lf_est);

			setTotalClaimAmt(settings.total_claim_amt);
			setTotalContractAmt(settings.total_contract_amt);

			setUserSubmitted({
				...settings.user_submitted
			})

			setIsLoading(false);
			if ( settings.contact == 0 || settings.location == 0 ) {
				setErrorFound(true);
				setErrorMsg('Set the Contact and Location values below, to avoid error during Import')
			} else {
				setErrorFound(false);
				setErrorMsg('');
			}

		})

	}, [])

	/* ************************************************************************ */
	/* Field Change Actions                                                     */
	/* ************************************************************************ */

	// Only used for text fields on the Job's "Settings" tab
	const handleChange = () => {
		setIsBlocking(true);
		setSaved(false);
		setErrorFound(false);
		setErrorMsg('');
	}

	// when contact is changed, find the locations that they're setup for
	const changeContact = e => {
		const { value } = e.target
		setIsBlocking(true);
		if ( value == "0" ) {
			setContact("0");
		} else {
			setContact(value);
		}
	}

	const changeLocation = e => {
		const { value } = e.target;
		setIsBlocking(true);
		if ( value == "0" ) {
			setContact(0);
			setLocation(0);
		} else {
			setLocation(value);
		}
	}

	const handleDateChange = (field_id, value) => {
		console.log('handleDateChange')
		setIsBlocking(true);
		setSaved(false);

		if ( field_id === 'first_furnishing' ) {
			setFirstFurnishing(value);
		}
		if ( field_id === 'last_furnishing' ) {
			setLastFurnishing(value);
		}
	}

	// will format value from database (YYYY-MM-DD) for display to user (MM/DD/YYYY)
	const formatDate = (date) => {
		if (date === "" || date === undefined || date === null || date.startsWith("0000")) return "";
		const newDate = DateTime.fromSQL(date).toLocaleString();
		return newDate;
	}

	const changeEstimated = e => {
		const { target } = e
		const { name, value, checked } = target
		
		setIsBlocking(true);
		setSaved(false);
		if ( name === 'ff_est' ) {
			setFfEst(checked ? 1 : 0)
		}
		if ( name === 'lf_est' ) {
			setLfEst(checked ? 1 : 0)
		}
	}

	/* ************************************************************************ */
	/* Save Actions                                                             */
	/* ************************************************************************ */

	const editSettings = () => {
		setReadonly(false);
	}

	const cancelEdit = () => {
		var answer = true
		if ( isBlocking ) {
			answer = confirm('Would you like to discard the current changes?')
		}
		if (answer) {
			if ( prev_variant_id != 0 ) {
				// setLogoUpdated(false);
				setFormVariantId(prev_variant_id);
				// setLogoLocation(prev_location);
			}
			// TODO: reset job setting form fields here
			if ( contact == 0 || location == 0 ) {
				setErrorFound(true);
			}
			setReadonly(true);
		}
		return answer
	}

	/*
	const toggleLogoModal = () => {
		setLogoModal(!logoModal);
	}

	const changeLogo = e => {
		const { target } = e
		const { name, id } = target

		var value = id.substring(9)

		if ( prev_variant_id == 0 ) {
			setPrevVariantId(form_variant_id);
			setPrevLocation(JobSettings.logo_location);
		}

		var logo_loc = '';
		form.form_variant.map(variant => {
			if ( variant.id == value ) {
				logo_loc = window.app_api+ "/" +variant.file.location
			}
		})

		setFormVariantId(value);
		setLogoModal(false);
		setLogoUpdated(true);
		setLogoLocation(logo_loc);
	}
	*/

	const saveSettings = (e) => {

		const data = {
			name               : name,
			form_variant_id    : form_variant_id,
			selectedLocation   : location,
			selectedContact    : contact,
			reference_no       : reference_no,
			first_furnishing   : first_furnishing,
			ff_est             : ff_est,
			last_furnishing    : last_furnishing,
			lf_est             : lf_est,
			total_contract_amt : total_contract_amt,
			total_claim_amt    : total_claim_amt,
			// selectedWorkflow   : this.state.selectedWorkflow,
		}
	
		updateJob(id, data).then(res => {
			// result == true
			if ( !res['result'] ) {
				setErrorFound(true);
				setErrorMsg('Error saving Job Import Settings. Please check field input/selections and try again');
			} else {
				// error saving update
				if ( data['selectedLocation'] == 0 || data['selectedContact'] == 0 ) {
					setReadonly(true);
					// setLogoUpdated(false);
					setErrorFound(true);
					setErrorMsg('Set the Contact and Location values below, to avoid error during Import');
				} else {
					setReadonly(true);
					// setLogoUpdated(false);
					setErrorFound(false);
					setErrorMsg('');
				}
				
			}
		})
	}

	const acceptUserValue = (field, value) => {
		
		var old_value = field;
		switch(field) {
			case "name": setName(value); break;
			case "reference_no": setReferenceNo(value); break;
			case "first_furnishing": setFirstFurnishing(value); break;
			case "last_furnishing": setLastFurnishing(value); break;
			case "total_claim_amt": setTotalClaimAmt( value.replace(/\$/, '').replace(/\,/, '') ); break;
			case "total_contract_amt": setTotalContractAmt( value.replace(/\$/, '').replace(/\,/, '') ); break;
		}
		saveUserData(id, {'field': field, 'value': value}).then(data => {
			if ( !data.success ) {
				switch(field) {
					case "name": setName(old_value); break;
					case "reference_no": setReferenceNo(old_value); break;
					case "first_furnishing": setFirstFurnishing(old_value); break;
					case "last_furnishing": setLastFurnishing(old_value); break;
					case "total_claim_amt": setTotalClaimAmt(old_value); break;
					case "total_contract_amt": setTotalContractAmt(old_value); break;
				}
			}
		});
		
	}

	/*
	const clearUserValue = (fld) => {
		let old_value = user_submitted[fld];
		clearUserData(id, {'field': fld, 'value': old_value}).then(data => {
			setUserSubmitted({
				...user_submitted,
				[fld]: ''
			})
		});
	}

	const handleArchive = () => {
		if ( confirm("Are you sure you would like to Archive this Job?") ) {
			updateStatus({ 'id': id, 'status': 'archived' }).then((resp) => {
				history.push('/jobs')
			});
		}
	}
	
	const handleDelete = () => {
		if ( confirm('Are you sure you would like to Delete this Job?\nWARNING: This cannot be undone!') ) {
			updateStatus({ 'id': id, 'status': 'deleted' }).then((resp) => {
				history.push('/jobs')
			});
		}
	}
	*/

	const company = user.memberships[0].company;
	const reference_label = company.reference_label != '' ? company.reference_label : 'Customer Reference #';

	var clean_claim_amt = '';
	if ( user_submitted?.total_claim_amt ) {
		clean_claim_amt = user_submitted?.total_claim_amt.replace(/,/g, '').replace(/\$/, '');
	}
	var clean_contract_amt = '';
	if ( user_submitted?.total_contract_amt ) {
		clean_contract_amt = user_submitted?.total_contract_amt.replace(/,/g, '').replace(/\$/, '');
	}

	return (
		<Fragment>

			{/*
			<Modal isOpen={logoModal} toggle={toggleLogoModal} style={{ 'maxWidth': '650px' }}>
				<ModalHeader toggle={toggleLogoModal}>
					<span>Choose Form Logo</span>
				</ModalHeader>
				<ModalBody>
					{form.form_variant.map(logo => (
						<div key={logo.id} className="row pb-2 mb-2" style={{ borderBottom: 'solid 1px #CACACA' }}>
							<div className="col-1">
								<span className="align-middle">
								{
									logo.id == form_variant_id ? (
										<i className="far fa-check-circle" style={{ fontSize: '26px', textAlign: 'center', marginTop: '10px' }}></i>
									) : (
										<i id={"job_logo_" +logo.id} className="far fa-circle" style={{ fontSize: '26px', textAlign: 'center', marginTop: '10px', cursor: 'pointer', color: '#007bff' }} onClick={changeLogo}></i>
									)
								}
								</span>
							</div>
							<div className="col-5">
								{logo.file && (
									<img src={`${window.app_api}/${logo.file.location}`} className="img-fluid" />
								)}
							</div>
							<div className="col-6">
								{logo.title}  
							</div>
						</div>
					))}
				</ModalBody>
			</Modal>
			*/}

			{isLoading ? (
				<div><i className="fas fa-sync-alt fa-spin mr-1"></i> Loading Job Settings, Please wait...</div>
			) : (
				<>
					<div className={"card bg-light" + (status === 'pending' && !readonly ? ' border border-danger' : '')}>
						<h2 className="card-header">
							Client Information
							{(status == 'open' || status == 'pending') && (
								<>
									{readonly ? (
										<i className="ml-3 far fa-edit" role="button" onClick={editSettings} />
									) : (
										<>
											<Button className="mx-2 btn-sm" color={isBlocking ? 'primary' : 'secondary'} onClick={saveSettings} disabled={isSaving || !isBlocking}>
												{isSaving ? "Saving" : "Save"}
											</Button>
											<Button className="btn-sm" onClick={cancelEdit} disabled={isSaving}>
												Cancel
											</Button>
										</>
									)}	
								</>
							)}
						</h2>
						<div className="card-body">
							{errorFound && (
								<Alert color="danger">
									<i className="fas fa-exclamation-triangle mr-3"></i>
									{errorMsg}
								</Alert>
							)}

							<div>
								<div className="form-group">
									<LocationField value={location} change={changeLocation} readOnly={readonly || !user.api_key} />
								</div>
								<div className="form-group">
									<ContactField value={contact} change={changeContact} readOnly={readonly || !user.api_key} />
								</div>
							</div>
							
							<div className="row">
								
								<div className="col">
									
									<div className="form-group">
										<div className="form-row">
											<div className="col">
												<Input
													name="name"
													label="Job Name"
													value={name}
													onChange={(e) => { handleChange(); setName(e.target.value) }}
													readOnly={readonly}
													className=" inline"
												/>
												{(status !== 'submitted' && user_submitted.project_name != '' && user_submitted.project_name != name) && (
													<Fragment>
														<span className="userValue ml-3" title="Accept User value" onClick={() => acceptUserValue('name', user_submitted.project_name)}>
															{user_submitted.project_name}
															<i className="fas fa-check ml-1"></i>
														</span>
														{/*
														<i className="fas fa-times ml-2" title="Clear User Value"
															onClick={() => clearUserValue('project_name')}
															style={{ cursor: 'pointer', fontWeight: 'bold', color: 'red' }}></i>
														*/}
													</Fragment>
												)}
											</div>
										</div>
									</div>

									<div className="form-group">
										<div className="form-row">
											<div className="col">
												<Input 
													name="reference_no"
													label={reference_label}
													value={reference_no}
													onChange={(e) => { handleChange(); setReferenceNo(e.target.value) }}
													readOnly={readonly}
												/>
												{(status !== 'submitted' && user_submitted.reference_no != '' && user_submitted.reference_no != reference_no) && (
													<Fragment>
														<span className="userValue ml-3" title="Accept User value" onClick={() => acceptUserValue('reference_no', user_submitted.reference_no)}>
															{user_submitted.reference_no}
															<i className="fas fa-check ml-1"></i>
														</span>
														{/*
														<i className="fas fa-times ml-2" title="Clear User Value"
															onClick={() => clearUserValue('reference_no')}
															style={{ cursor: 'pointer', fontWeight: 'bold', color: 'red' }}></i>
														*/}
													</Fragment>
												)}
											</div>
										</div>
									</div>

									{readonly ? (
										<div className="form-group">
											<div className="form-row">
												<div className="col-4">
													<label>First Furnishing</label>
													<span>{formatDate(first_furnishing) + (ff_est == 1 ? ' (estimated)' : '')}</span>
													{(status !== 'submitted' && user_submitted.first_furnishing != '' && user_submitted.first_furnishing != first_furnishing) && (
														<Fragment>
															<span className="userValue ml-3" title="Accept User value" onClick={() => acceptUserValue('first_furnishing', user_submitted.first_furnishing)}>
																{formatDate(user_submitted.first_furnishing)}
																<i className="fas fa-check ml-1"></i>
															</span>
															{/*
															<i className="fas fa-times ml-2" title="Clear User Value"
																onClick={() => clearUserValue('first_furnishing')}
																style={{ cursor: 'pointer', fontWeight: 'bold', color: 'red' }}></i>
															*/}
														</Fragment>
													)}
												</div>
												<div className="col">
													<label>Last Furnishing</label>
													<span>{formatDate(last_furnishing) + (lf_est == 1 ? ' (estimated)' : '')}</span>
													{(status !== 'submitted' && user_submitted.last_furnishing != '' && user_submitted.last_furnishing != last_furnishing) && (
														<Fragment>
															<span className="userValue ml-3" title="Accept User value" onClick={() => acceptUserValue('last_furnishing', user_submitted.last_furnishing)}>
																{formatDate(user_submitted.last_furnishing)}
																<i className="fas fa-check ml-1"></i>
															</span>
															{/*
															<i className="fas fa-times ml-2" title="Clear User Value"
																onClick={() => clearUserValue('last_furnishing')}
																style={{ cursor: 'pointer', fontWeight: 'bold', color: 'red' }}></i>
															*/}
														</Fragment>
													)}
												</div>
											</div>
										</div>
									) : (
										<div className="form-group">
											<div className="form-row">
												<div className={'col-4'}>
													<div className={'form-group'}>
														<DateField
															name={"from"}  
															label={"First Furnishing"}
															field_id={"first_furnishing"}
															onChangeDate={handleDateChange}
															isRequired={true}
															value={formatDate(first_furnishing) ?? ""}
														/>
													</div>
												</div>
												<div className={'col'}>
													<div className={'form-group'}>
														<DateField
															name={"to"}  
															label={"Last Furnishing"}
															field_id={"last_furnishing"}
															onChangeDate={handleDateChange}
															isRequired={true}
															value={formatDate(last_furnishing) ?? ""}
														/>
													</div>
												</div>
												{/*
												<DateRange
													start={formatDate(first_furnishing) ?? ""}
													end={formatDate(last_furnishing) ?? ""}
													onChangeFrom={(value) => setJobSettings({ ...jobSettings, first_furnishing: value })}
													onChangeTo={(value) => setJobSettings({ ...jobSettings, last_furnishing: value })}
												/>
												*/}
											</div>
											<div className="form-row">
												<div className="col-4">
													<div style={{ marginLeft: '10px' }}>
														<Checkbox
															onChange={changeEstimated}
															label="Check if estimated"
															name="ff_est"
															value={ff_est == 1 ? true : false}
														/>
													</div>
												</div>
												<div className="col">
													<div style={{ marginLeft: '10px' }}>
														<Checkbox
															onChange={changeEstimated}
															label="Check if estimated"
															name="lf_est"
															value={lf_est == 1 ? true : false}
														/>
													</div>
												</div>
											</div>
										</div>
									)}
									
									<div className="form-group">
										<div className="form-row">
											<div className="col-4">
												<Input
													type="text"
													label="Total Contract Amount"
													name="total_contract_amt"
													value={/*readonly ? formatMoney.format(total_contract_amt) : */total_contract_amt}
													onChange={(e) => { handleChange(); setTotalContractAmt(e.target.value); }}
													readOnly={readonly}
													pattern="^\d*(\.\d{0,2})?$"
												/>
												{(status !== 'submitted' && clean_contract_amt != '' && clean_contract_amt != total_contract_amt) && (
													<Fragment>
														<span className="userValue ml-3" title="Accept User value" onClick={() => acceptUserValue('total_contract_amt', user_submitted.total_contract_amt)}>
															{user_submitted.total_contract_amt}
															<i className="fas fa-check ml-1"></i>
														</span>
														{/*
														<i className="fas fa-times ml-2" title="Clear User Value"
															onClick={() => clearUserValue('total_contract_amt')}
															style={{ cursor: 'pointer', fontWeight: 'bold', color: 'red' }}></i>
														*/}
													</Fragment>
												)}
											</div>
											
											<div className="col">
												<Input
													type="text"
													label="Total Claim Amount"
													name="total_claim_amt"
													value={/* readonly ? formatMoney.format(total_claim_amt) : */total_claim_amt}
													onChange={(e) => { handleChange(); setTotalClaimAmt(e.target.value); }}
													readOnly={readonly}
													pattern="^\d*(\.\d{0,2})?$"
												/>
												{(status !== 'submitted' && clean_claim_amt != '' && clean_claim_amt != total_claim_amt) && (
													<Fragment>
														<span className="userValue ml-3" title="Accept User value" onClick={() => acceptUserValue('total_claim_amt', user_submitted.total_claim_amt)}>
															{user_submitted.total_claim_amt}
															<i className="fas fa-check ml-1"></i>
														</span>
														{/*
														<i className="fas fa-times ml-2" title="Clear User Value"
															onClick={() => clearUserValue('total_claim_amt')}
															style={{ cursor: 'pointer', fontWeight: 'bold', color: 'red' }}></i>
														*/}
													</Fragment>
												)}
											</div>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
				</>		
			)}
		</Fragment>
	)
}
export default JobSettings;
