import React from 'react'
import Link from '../partials/Link'
import { classnames } from '../../utils/classnames'

import './styles/NavLink.css'

export const NavLink = ({ label, to, highlight, children, classes="" }) => (
	<li className="NavLink">
		<Link highlight={highlight} className={classnames( 'nav-link', classes )} to={to}>
			{children}
		</Link>
    {label && (
      <div className="NavLink--label">{label}</div>
    )}
	</li>
)
