
import React, { Fragment, useState, useEffect, useContext } from 'react'
import { useHistory, useParams, Prompt, useLocation } from 'react-router-dom'
import { JobAppContext } from '../../../src/GlobalState';
import { DateTime } from "luxon";

import {
	canSubmitJobs,
	newJob,
	getJobForm, saveLinkForm, saveJobForm, finalizeJob,
	uploadAttachment, deleteAttachment, updateStatus
} from '../../api'

import ReCAPTCHA from "react-google-recaptcha";

import JobSettings from '../pages/JobSettings'

import { Button, Alert, Table, Nav, NavLink, NavItem } from 'reactstrap'
import { Input }        from '../partials'
import FileDropper      from '../partials/FileDropper'
import Place            from '../partials/Place'
// import { Select }       from '../partials'
import { ListDropDown } from '../partials/ListDropDown'
import DateField    	from '../partials/DateField'
import Form 			from '../Form/Form'

const custom_texts = [
	{
		type: 'alpha', pattern: '^[A-Za-z]$'
	},
	{
		type: 'alphanum', pattern: '^[0-9A-Za-z]$'
	},
	{
		type: 'number', pattern: '^\d*(\.\d{0,2})?$' // numeric values, with two decimal precision
	},
	{
		type: 'date', pattern: '^[0-9]{2}[-\s\.\/]?[0-9]{2}[-\s\.\/]?[0-9]{4}$'
	},
	{
		type: 'email', pattern: '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$'
	},
	{
		type: 'phone', pattern: '^[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$'
	}
];

const default_form_config = {  // default config
	"fields": [
		{ "name": "project_name", "label": "Project Name", "active": true, "req": true }
	], "allow_none": true
};

const ActionButtons = ({
	progress, isBlocking, saved, remaining, isSaving, isUploading, btnClick
}) => {
	
	return (
		<div className="text-right formFooter mb-2">
			<div className="pb-3">
				{(progress == 100 && !isBlocking) && (
					<cite>
						<i className="fas fa-exclamation-circle"></i> Click the "Finalize" button to complete your job data entry
					</cite>
				)}
			</div>
			{saved && <Alert color="success" style={{ display: 'inline', marginRight: '20px' }}>
				Form saved successfully!
			</Alert>}
			<Alert color="danger" id="submitBtnMsg" className="mr-2" style={{ display: 'none' }}></Alert>

			{remaining > 0 && (
				<span className="mr-3 font-weight-bold text-danger">
					{'( ' +remaining+ ' required field' +(remaining > 1 ? 's' : '')+ ' remaining )'}
				</span>
			)}

			<Button 
				onClick={btnClick} 
				disabled={isSaving || isUploading} 
				color={progress < 100 ? 'primary' : 'secondary'}
				title="This button will validate your data entry and enable the Finalize button"
			>
				{isSaving ? 'Saving...' : 'Save Changes'}
			</Button>
			
			{(progress == 100 && !isBlocking) ? (
				<Button type="submit" color='primary' className="validate ml-3">
					Finalize
				</Button>
			) : (
				<Button type="submit" color='secondary' disabled={true} outline className="validate ml-3">
					Finalize
				</Button>
			)}
		</div>
	)
}


const ShowForm = (props) => {

	const {
		user, isAuthenticated, accessToken,
		// mapped_types, address_types
	} = useContext(JobAppContext);

	const history = useHistory();
	const params = useParams();
	const url = useLocation();

	let idVal = props.id;
	let uuidVal = props.uuid;
	let appidVal = props.appid;
	let cntidVal = props.cntid;

	if ( params ) {
		idVal = params.id ?? 0
		uuidVal = params.uuid ?? 0
		appidVal = params.appid ?? 0
		cntidVal = params.cntid ?? 0
	}

	let dragEnterCounter = 0;
	let prettifiedDocs = false;

	const [ canSubmit, setCanSubmit ] = useState(false);
			
	const [ isBlocking, setIsBlocking ] = useState(false);
	const [ isEditing, setIsEditing ] = useState(false);
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isSaving, setIsSaving ] = useState(false);
	const [ isUploading, setIsUploading ] = useState(false);

	const [ filesUploaded, setFilesUploaded ] = useState(false);
	const [ messageStatus, setMessageStatus ] = useState('');
	const [ uploadResult, setUploadResult ] = useState('');

	const [ saved, setSaved ] = useState(false);
	const [ errorMsg, setErrorMsg ] = useState('');

	const [ visible, setVisible ] = useState([]);
	const [ status, setStatus ] = useState(props?.status ?? 'open');
	
	const [ id, setId ] = useState(idVal);
	const [ uuid, setUuid ] = useState(uuidVal);
	const [ appid, setAppid ] = useState(appidVal);
	const [ cntid, setCntid ] = useState(cntidVal);

	const [ project_name, setProjectName ] = useState('');
	/*
	const [ contact, setContact ] = useState(0);
	const [ location, setLocation ] = useState(0);
	*/
	const [ reference_no, setReferenceNo ] = useState('');
	/*
	const [ first_furnishing, setFirstFurnishing ] = useState('');
	const [ ff_est, setFfEst ] = useState(0);
	const [ last_furnishing, setLastFurnishing ] = useState('');
	const [ lf_est, setLfEst ] = useState(0);
	const [ contract_amt, setContractAmt ] = useState('');
	const [ claim_amt, setClaimAmt ] = useState('');
	
	const [ user_id, setUserId ] = useState(0);
	*/

	const [ custom_style, setCustomStyle ] = useState('');

	const [ message, setMessage ] = useState('');
	const [ footnote, setFootnote ] = useState('');
	const [ answers, setAnswers ] = useState([]);
	
	const [ form, setForm ] = useState({
		id,
		sections: [],
		config: default_form_config // default config
	});
	const [ file, setFile ] = useState({});
	const [ attachments, setAttachments ] = useState([]);

	const [ dragOver, setDragOver ] = useState(false);

	const [ progress, setProgress ] = useState(0);
	const [ completed_fields, setCompletedFields ] = useState(0);
	const [ field_count, setFieldCount ] = useState(0);
	const [ remaining, setRemaining ] = useState(0);
	const [ recaptcha, setRecaptcha ] = useState('');

	useEffect(() => {

		let limit = 0;

		let answerData = {}
		let parms = { id, uuid, appid, cntid, limit }

    	getJobForm( parms, accessToken ).then(data => {

			if (data.error) {
				/* DAP - 1.15.19 - If link is invalid and/or job is not found -> redirect to /jobs page */
				history.push('/jobs')
				return;
			}
		
			var chosen_value = 0;
			var additional_party = 0;

			data.form.sections.forEach(section => {
				section.fields.forEach(field => {
					
					if (field.type === 'address' || field.type === 'address_plus') {
						
						const address = field.result === null ? '' : JSON.parse(field.result.value)
						let address_block = {
							company: '',
							name: '',
							phone: '',
							address: '',
							address_2: '',
							parcel_no: '',
							lot_no: '',
							block_no: '',
							county: '',
							id: '',
							city: '',
							state: '',
							zip: '',
							email: ''
						};

						// initialize contact address block for bonding companies with blank bond_no field
						if ( field.type_id == 10 || field.type_id == 11 || field.type_id == 12 ) {
							address_block.bond_no = '';
						}

						answerData[field.id] = {
							id: field.id,
							address: {
								...address_block,
								...address,
							},
						}
						// console.log('answerData['+field.id+']', answerData[field.id])

					} else {

						let output;
						if (field.result?.value) {
							output = field.result.value;
						} else if (field.defaultValue !== '') {
							output = field.defaultValue;
						} else {
							output = null;
						}

						answerData[field.id] = {
							id: field.id, 
							value: output
						}
						
						// I don't know why this is here, but leaving it just in case
						if (field.type === 'choice' && answerData[field.id].value == '') {
							answerData[field.id] = {
								...answerData[field.id],
								value: '',
							}
						}

						if ( field.type === "assoc_choice" ) {
							if ( answerData[field.id]?.value ) {
								// loop through field choices
								field.choices.forEach(choice => {
									if ( choice.id == answerData[field.id]?.value ) {
										chosen_value = choice.value;
										additional_party = choice.extra;
									}
								})
							}
						}
					}
				})
			})

			// Set each section to "visible", unless an Association Option has been chosen, then show/hide sections accordingly
			data.form.sections.map(section => {
				if ( chosen_value !== 0 ) {
					var address_found = false
					
					for( var i=0; i<section.fields.length; i++ ) {
						let field = section.fields[i];
						if ( field['type'] == 'address' ) {
							if ( !field.mapped_type || field?.mapped_type?.reference == 'project_location' ) continue;
							// if ( field.type_id == 2 || field.type_id == null ) continue;
							if ( field.type_id > 2 && field.type_id <= 8 ) {
								if (additional_party == 1) {
									section['visible'] = (chosen_value >= field.type_id) ? true : false
								} else {
									section['visible'] = (chosen_value > field.type_id) ? true : false
								}
								address_found = true
							} else {
								/* Mark sections that are above 5 in contractual chain to always display */
								section['visible'] = true
								address_found = true
							}
						}
					}
					if ( !address_found ) section['visible'] = true
				
				} else {
					section['visible'] = true
				}
				section['readonly'] = data.status == "open" || data.status == 'new' ? false : true
			})

			setId(data.id || 0);
			setUuid(data.uuid || '');
			
			setProjectName(data.name);
			/*
			setContact(data.contact);
			setLocation(data.location);
			*/
			setReferenceNo(data.reference_no);
			/*
			setFirstFurnishing(data.first_furnishing);
			setFfEst(data.ff_est);
			setLastFurnishing(data.last_furnishing);
			setLfEst(data.lf_est);
			setContractAmt(data.contract_amt);
			setClaimAmt(data.claim);
			setUserId(data.created_by || null);
			*/

			setCustomStyle(data.company.custom_style);

			setForm({
				...data.form,
				config: JSON.parse(data.form.config)
			})

			setMessage(data.form.message);
			setFootnote(data.form.footnote);
			setAnswers(answerData);

			setFile(data.form_variant ? data.form_variant.file : '');

			setAttachments(data.attachments);
			setIsLoading(false);
			setStatus(data.status);

			setProgress(data.progress.percent);
			setCompletedFields(data.progress.completed);
			setFieldCount(data.progress.field_count);
			setRemaining(data.progress.remaining);

		})
		.catch(error => {
			console.log('error', error)
			setStatus('closed');
		})

		if ( isAuthenticated ) {
			// user check -> does user have cnt_id and api_key set on their db record?
			canSubmitJobs().then(data => {
				setCanSubmit(data.submittable);
			})
		}
    
	}, [])

	// when status changes from actions taken on ShowJob component (ie. parent of ShowForm)
	useEffect(() => {
		var new_sections = form.sections.map(section => {
			return {
				...section,
				readonly: props.status === 'open' ? false : true // if open, set fields to "editable"
			}
		})
		setStatus(props.status);
		setForm({ ...form, sections: new_sections })
	}, [props.status]) 

	/* ***** Executed by clicking the "Finalize" button ***** */
	const finalizeButton = () => {
		
		setSaved(false);
		finalizeJob({ id, uuid }).then(data => {
			var new_sections = form.sections.map(section => {
				section.readonly = true
				return section
			})
			setStatus(data['job'].status);
			setForm({
				...form,
				sections: new_sections
			})
			
			props?.updateTabs(data['job'].status);
			window.scrollTo(0, 0);

		}, error => console.error(error))

	}

	/* ************************************************************************ */
	/* ***** Handle File Uploads                                          ***** */
	/* ************************************************************************ */
	const attachFile = event => {
		const { target } = event
		const { value, name, files } = target

		if ( files.length > 0 ) {
			// disable buttons: "Save Progress", "Submit Job Information", Upload Dropzone
			// Add "queued" file(s) to table
			setIsUploading(true);
			setFilesUploaded(false);
			setMessageStatus('');
			setUploadResult('');

			// Create FormData, and append file(s) to it
			let formData = new FormData()
			for( var i=0; i<files.length; i++ ) {
				formData.append('files[]', files[i]);
			}
			formData.append('job_id', id);
			formData.append('company_id', form?.company_id);
			initiateUpload(formData)
		}
	}

	const removeAttachment = event => {
		const { target } = event
		const { value, name } = target

		setFilesUploaded(false); // clears upload status message

		deleteAttachment(value).then(data => {
			let newAttachList = [...attachments]
			for (var i=0; i<newAttachList.length; i++) {
				if ( newAttachList[i]['id'] == data['attachment_id'] ) {
					newAttachList.splice(i, 1); break;
				}
			}
			setAttachments([
				...newAttachList
			])
		})
	}

	/* ***** FileDropper Event Handlers ***** */
	// const setDragOver = (value) => {
	// 	console.log('on drag over', value);
	// }

	const fileRetrieved = (files) => {
		if ( files.length > 0 ) {
			// Add "queued" file(s) to table
			setIsUploading(true);
			setFilesUploaded(false);
			setMessageStatus('');
			setUploadResult('');

			// Create FormData, and append file(s) to it
			let formData = new FormData()
			for( var i=0; i<files.length; i++ ) {
				formData.append('files[]', files[i])
			}
			formData.append('job_id', id)
			formData.append('company_id', form?.company_id);
			initiateUpload(formData)
		}
	}

	/*
		actually do the upload / response, called from:
		-> attachFile (click and select file(s) from browse window)
		-> fileRetrieved (drag-n-drop file(s))
	*/
	const initiateUpload = ( formData ) => {
		uploadAttachment(formData)
			.then(data => data.json())
			.then(data => {
			
				// Clear "file" input element
				// document.getElementById('attachFile').value = ''

				setIsUploading(false);
				setFilesUploaded(true);

				if ( data['error'] ) {
					setMessageStatus('danger');
					setUploadResult("Error: " +data['error']);
				} else {
					// Update state value of attachments, to show "completed" uploads
					setMessageStatus('success');
					setUploadResult('Upload completed successfully!');
				}
				setAttachments([...data, ...attachments])
			})
	}

	const selectFile = () => {
		// console.log('executing selectFile()');
		document.getElementById('attachFile').click();
	}
	/* ************************************************************************ */
	/* ************************************************************************ */

	const handleChange = event => {
		const { target } = event
		const { type, name, value, checked } = target

		const name_split = name.split('.')
		const namespace = name_split[name_split.length - name_split.length]
		const field = name_split[name_split.length - 1]

		// can i clean this up?
		// maybe i can use a HOC
		setIsBlocking(true);
		setSaved(false);
		setErrorMsg('');

		if (name_split.length > 2) { // address field change
			
			const mid = name_split[1]
			if ( type == 'checkbox' ) {

				if ( checked ) {
					let updateAddr = {
						...answers[namespace].address
					}
					
					updateAddr['company']   = mid;
					updateAddr['name']      = '';
					updateAddr['phone']     = '';
					updateAddr['address']   = mid;
					updateAddr['address_2'] = '';
					updateAddr['city']      = mid;
					updateAddr['state']     = '';
					updateAddr['zip']       = mid;
					updateAddr['county']    = '';
					updateAddr['email']     = '';

					setAnswers(prevState => ({
						...prevState,
						[namespace]: {
							...prevState[namespace],
							address: updateAddr
						}
					}))
				}

			} else {
				setAnswers(prevState => ({
					...prevState,
					[namespace]: {
						...prevState[namespace],
						[mid]: {
							...prevState[namespace][mid],
							[field]: value,
						},
					},
				}))
			}

		} else if (name_split.length === 2) { // ??
			
			setAnswers(prevState => ({
				...prevState,
				[namespace]: {
					...prevState[namespace],
					[field]: value,
				},
			}))

		} else {    // text or choice field update

			setAnswers(prevState => ({
				...prevState,
				[name]: {
					...prevState[name],
					value: value,
				},
			}))

		}
	}

	const handleDateChange = (field_id, value) => {

		setIsBlocking(true);
		setSaved(false);
		setErrorMsg('');

		setAnswers(prevState => ({
			...prevState,
			[field_id]: {
				...prevState[field_id],
				value: value,
			}
		}))

	}

  	const handle_project_configs = (event) => {
		const { name, value } = event.target
		
		setIsBlocking(true);
		setSaved(false);
		setErrorMsg('');
		switch(name) {
			case "project_name": setProjectName(value); break;
			case "reference_no": setReferenceNo(value); break;
			default:
				console.log('error setting project config field')
		}
	}

	

	const role_update = event => {
		const { target } = event
		const { value, name, type } = target

		if ( type === 'select-one' ) {
			var dataset = target.options[target.selectedIndex].dataset;
			var choice = dataset.choice;
			var ap = dataset.ap; // additional_party flag on assoc_choice field's radio buttons
		} else {
			var choice = target.getAttribute('data-choice');
			var ap = target.getAttribute('data-ap'); // additional_party flag on assoc_choice field's radio buttons
		}

		var section_list = form.sections.map(section => {
			let visibility = true

			section.fields.forEach(field => {
				if ( field.type == 'address' ) {
					if ( field?.mapped_type?.reference == 'project_location' || isNaN(field.type_id) ) return;
					if ( field.type_id > 2 && field.type_id <= 8 ) {
						if ( ap == 1 ) {
							visibility = choice >= field.type_id ? true : false
						} else {
							visibility = choice > field.type_id ? true : false
						}
					} else {
						visibility = true
					}
				}
			})
			return {
				...section, visible: visibility
			}
		})

		setForm({
			...form,
			sections: section_list
		})
		setAnswers(prevState => ({
			...prevState,
			[name]: {
				...prevState[name],
				value: value,
			},
		}))
	}

	// called when saving draft (while job is "open" status)
	const saveDraftUpdates = event => {

		let submitMessage = document.querySelector('#submitBtnMsg')
		if ( submitMessage ) {
			submitMessage.style.display = 'none';
		}

		setSaved(false);
		setIsSaving(true);

		// job is from a link
		if ( uuid ) {
		
			saveLinkForm({
				id, answers, uuid
			}).then((response) => {
				let remaining = response.field_count - response.completed_fields

				setIsSaving(false);
				setIsEditing(false);
				setIsBlocking(false);
				setSaved(true);
				setProgress(response.progress);
				setFieldCount(response.field_count);
				setCompletedFields(response.completed_fields);
				setRemaining(remaining);
			})

		} else {
		
			saveJobForm({
				id, answers
			}).then((response) => {
				let remaining = response.field_count - response.completed_fields
				
				setIsSaving(false);
				setIsEditing(false);
				setIsBlocking(false);
				setSaved(true);
				setProgress(response.progress);
				setFieldCount(response.field_count);
				setCompletedFields(response.completed_fields);
				setRemaining(remaining);
			}, error => console.log('Error saving job form',error))

		}
	}

	// only called from "submit" button from "guest" job form link (user ID will always be null)
	const saveAndFinalize = () => {

		let submitMessage = document.querySelector('#submitBtnMsg')
		if ( submitMessage ) {
			submitMessage.style.display = 'none';
		}

		setSaved(false);
		setIsSaving(true);

		let postData = {
			'appid': appid,
			// 'user_id': user_id,
			
			'project_name': project_name,
			'reference_no': reference_no,
			/*
			'first_furnishing': this.state.first_furnishing,
			'first_furnishing': this.state.first_furnishing,
			'contract_amt': this.state.contract_amt,
			'claim_amt': this.state.claim_amt,
			*/

			'recaptcha': recaptcha,
			'answers': {
				...answers
			},
			'attachments': attachments.map(a => a.id)
		}

		newJob(postData).then((response) => {

			if ( response['success'] ) {
				var new_sections = form.sections.map(section => {
					section.readonly = true
					return section
				})

				setIsSaving(false);
				setIsEditing(false);
				setIsBlocking(false);
				setSaved(true);

				setId(response.id);
				setStatus('pending');

				setForm({
					...form,
					sections: new_sections
				})

				setProgress(response.progress);
				setFieldCount(response.field_count);
				setCompletedFields(response.completed_fields);
				setRemaining(response.remaining);

				window.scrollTo(0, 0);

			} else {
				console.log('Error saving job: ' +response.error);
			}
			
		});
		
	}

	// ----------------------------------------------------------------------------------

	const editSection = (id) => {
		let new_sections = form.sections.map(s => {
			if (s.id == id)
				return { ...s, readonly: false }
			else
				return s;
		})
		setForm({
			...form,
			sections: new_sections
		})
	}
	const cancelEdit = () => {
		var new_sections = form.sections.map(section => {
			section['readonly'] = true
			return section
		})
		setForm({
			...form,
			sections: new_sections
		})
	}
	const saveEdit = event => {
		
		let submitMessage = document.querySelector('#submitBtnMsg')
		if ( submitMessage ) {
			submitMessage.style.display = 'none';
		}

		setSaved(false);
		setIsSaving(true);

		// console.log('ShowForm saveEdit id', id, answers);

		saveJobForm({
			id, answers
		}).then((response) => {

			var new_sections = form.sections.map(section => {
				section['readonly'] = true
				return section
			})

			let remaining = response.field_count - response.completed_fields

			setIsSaving(false);
			setIsEditing(false);
			setIsBlocking(false);
			setSaved(true);

			setForm({
				...form,
				sections: new_sections
			})

			setProgress(response.progress);
			setFieldCount(response.field_count);
			setCompletedFields(response.completed_fields);
			setRemaining(remaining);

		}, error => console.log('Error saving job form',error))
	}

	// ----------------------------------------------------------------------------------

	const formatDate = (date) => {
		if (date === "" || date === undefined || date === null || date.startsWith("0000")) return "";
		const newDate = DateTime.fromSQL(date).toLocaleString();
		return newDate;
	}

	const handleReCaptcha = value => {
		setRecaptcha(value);
	}

    // const { id, uuid, appid, form, attachments, status, remaining, custom_style } = this.state

	let field_config = form.config || default_form_config;

	// console.log('ShowForm values:', uuid, appid, status);

	return (
		<>
		<div className="row">
			
			{!isLoading && ((uuid !== '' && uuid !== 0) || (appid !== '' && appid !== 0)) && (
				<div className='d-none d-xl-block col-12 col-xl-2'>
					<div className="quickNav">
						<h5>Quick Navigation</h5>
						<Nav id="quick-nav" vertical>
							{form.sections.map(section => {
								if ( section.visible ) {
									return (
										<NavItem key={section.id} className={"border-bottom"}>
											<NavLink href={`#${section.title.replace(' ', '-')}`}>
												{section.title}
											</NavLink>
										</NavItem>
									)
								}
							})}
							<NavItem className={"border-bottom"}>
								<NavLink href={`#attachments`}>
									Attachments
								</NavLink>
							</NavItem>
						</Nav>
					</div>
				</div>
			)}

			<div id="form-page" className="col-12 col-xl-10" data-spy="scroll" data-target="#quick-nav" data-offset="0" style={{ display: 'relative' }}>

				{custom_style != null && custom_style != '' && (
					<link rel="stylesheet" type="text/css" href={'../client/' + custom_style} />
				)}

				{status == 'pending' && ((uuid !== '' && uuid !== 0) || (appid !== '' && appid !== 0)) && (
					<Alert color="success" className="mt-3">
						Form Submission is complete! Please contact an Admin if you have any questions or concerns.
					</Alert>
				)}

				{/* status == 'submitted' && (
					<Alert color="success" className="mt-3">
						This job has been imported. Please contact an Admin if you have questions or concerns.
					</Alert>
				) */}
				
				{((status == 'open' || status == 'new') || (
					status == 'pending' && (uuid !== '' && uuid !== 0) || (appid !== '' && appid !== 0)
				)) && !isLoading && (
					<>
						<div className="d-flex mt-4 mb-2">
							{file && (
								<img alt="Logo" id="form-logo" src={`${window.app_api}/${file.location}`} /> 
							)}
							<div className="flex-grow-1">
								<h3 className="Title">{status == 'new' ? 'New Project' : project_name}</h3>
							</div>
						</div>
						{message && (
							<div className="staticTextField">{message}</div>
						)}
					</>
				)}

				<Form id="formContainer" className={(status === 'pending' || status === 'submitted') ? 'bg-light' : ''} submit={status == 'new' ? saveAndFinalize : finalizeButton}>

					<Prompt when={isBlocking} message="You have unsaved changes! Are you sure you want to continue?" />

					<div className="fieldBuilderPreview">

					{isLoading ? (
						<div className="card">
							<h2 className="card-header">
								NCS Job App
							</h2>
							<div className="card-body">
								<i className="fas fa-sync-alt fa-spin mr-1" ></i> Loading job form and data
							</div>
						</div>
					) : (
						<>

						{status == "open" && (
							<ActionButtons
								saved={saved}
								progress={progress}
								remaining={remaining}

								isBlocking={isBlocking}
								isSaving={isSaving}
								isUploading={isUploading}

								btnClick={saveDraftUpdates} />
						)}

						{status == 'new' && (
							<div id="Project-Details" className="card">
								<h2 className="card-header">
									Project Details
								</h2>
								<div className="card-body">
									<div className="form-group">
									
										{field_config.fields.map((field, idx) => {
											if ( !field.active ) { return; }
											return (
												<div key={idx} className="form-row">
													<div className="col">
														<Input
															label={field.label}
															description=""
															type="text"
															name={field.name}
															onChange={handle_project_configs}
															isRequired={field.req ? 1 : 0}
															value={field.name == 'project_name' ? project_name : reference_no}
														/>
													</div>
												</div>
											)
										})}

									</div>
								</div>
							</div>
						)}

						{(!uuid && !appid && isAuthenticated && status != "open") && (
							<JobSettings />
						)}

						{form.sections.map(section => { 
							// console.log('[ShowForm] section', section)
							return (
						<div key={section.id} id={`${section.title.replace(' ', '-')}`}>
							{section.visible && (
							
							<div className={
									"card" + 
									(
										(status === 'pending' || status === 'submitted' ? ' bg-light' : '') + 
										(status === 'pending' && !section.readonly ? ' border border-danger' : '')
									)}
								key={section.id}>
								<h2 className="card-header">
									{section.title}
									{!uuid && !appid && status == 'pending' && (
										<>
											{section.readonly ? (
												<i className="ml-3 far fa-edit" role="button" onClick={() => editSection(section.id)} />
											) : (
												<>
													<Button className="mx-2 btn-sm" onClick={saveEdit} disabled={isSaving}>
														{isSaving ? "Saving" : "Save"}
													</Button>
													<Button className="btn-sm" onClick={cancelEdit} disabled={isSaving}>
														Cancel
													</Button>
												</>
											)}
										</>
									)}
								</h2>
								<div className="card-body">

								{section.fields.length !== 0 && section.fields.map((field, key) => {
									
									var custom_found = custom_texts.find(typ => typ.type == field.type);
									return (
										<Fragment key={field.id}>

										{/* field.type === 'hidden' && (
											<input type="hidden" name={field.id} id={field.title} value={field.defaultValue} />
										) */}

										{(field.type === 'address' || field.type === 'address_plus') && (
											<Place
												fldType={field.type}
												description={field.description}
												hasContact={field.type_id != 2 ? true : false}
												isRequired={field.required}
												label={field.title + ' '}
												onChange={handleChange}
												value={answers[field.id]}
												parent={field.id}
												type_id={field.type_id}
												allow_none={field_config.allow_none}
												readOnly={section.readonly}
												fields={field.field_configs}
												style={field.style ? JSON.parse(field.style) : null}
											/>
										)}

										{custom_found && (
											<div className="form-group">
												<div className="form-row">
													<div className="col">
														{custom_found.type == 'date' ? (  
															<DateField
																name={field.id}  
																description={field.description}
																label={field.title + ' '}
																field_id={field.id}
																onChangeDate={handleDateChange}
																isRequired={field.required}
																value={formatDate(answers[field.id].value) ?? ''}
																readOnly={section.readonly}
															/>
														) : (
															<Input
																label={field.title}
																description={field.description}
																type={custom_found.type}
																pattern={custom_found.pattern}
																name={field.id}
																onChange={handleChange}
																isRequired={field.required}
																value={answers[field.id]?.value ?? ''}
																readOnly={section.readonly}
															/>
														)}
													</div>
												</div>
											</div>
										)}

										{field.type === 'text' && (
											<div className="form-group">
												<div className="form-row">
													<div className="col">
														<Input
															label={field.title}
															description={field.description}
															name={field.id}
															onChange={handleChange}
															isRequired={field.required}
															value={answers[field.id]?.value ?? ''}
															readOnly={section.readonly}
														/>
													</div>
												</div>
											</div>
										)}

										{(field.type === 'choice' || field.type === 'assoc_choice') && (
											<div className="form-group">
												<div className="form-row">
													<div className="col">
														<ListDropDown
															className="form-group input-wrapper"
															label={field.title}
															description={field.description}
															name={field.id}
															selected={answers[field.id]?.value ?? ''}
															onChange={field.type === 'choice' ? handleChange : role_update}
															items={field.choices}
															isRequired={field.required}
															readOnly={section.readonly}
														/>
													</div>
												</div>
											</div>
										)}

										{/* TODO: Change jobinformation.fields.description to "blob" type */}
										{/* allow formatting/html in blob data */}
										{(field.type == 'instructions' && (status == 'open' || status == 'new')) && (
											<div className="row">
												<div className="col">
													<div className="staticTextField">{field.description}</div>
												</div>
											</div>
										)}

										</Fragment>
									)
								})}
								</div>
							</div>
							)}
						</div>
						) 
						})}

						<div id="attachments" className={
							"card" +(
								(status === 'pending' || status === 'submitted') ? ' bg-light' : '') +
								(status === 'pending' && isEditing ? ' border border-danger' : '')
							
						}>
							<h2 className="card-header">
								Attachments
								{!uuid && !appid && status == 'pending' && (
									<>
										{!isEditing ? (
											<i className="ml-3 far fa-edit" role="button" onClick={() => setIsEditing(true)} />
										) : (
											<Button className="btn-sm ml-2" onClick={() => setIsEditing(false)} disabled={isSaving}>
												Cancel
											</Button>
										)}
									</>
								)}
							</h2>
							<div className="card-body">
								
								{filesUploaded && !isUploading && (
									<div className={"alert alert-" +messageStatus+ " col"} role="alert">
										{uploadResult}
									</div>
								)}

								{isUploading && (
									<div className="m-2 p-4" style={{ border: 'solid 1px #CACACA', borderRadius: '10px' }}>
										<i className="fas fa-sync-alt fa-spin mr-1" ></i> Uploading Attachment...
									</div>
								)}

								{( (status == 'open' || status == 'new' || isEditing) && !isSaving && !isUploading ) && (
									<>
										<input type="file" name="attachFile" id="attachFile" multiple onChange={attachFile} style={{ display: 'none' }} />
										<FileDropper setDragState={setDragOver} fileRetrieved={fileRetrieved} onClick={selectFile} className={dragOver ? 'active ' : 'col'}>
											<div className="m-2 p-4">
												<span className="fa fa-upload pr-4" />Click or Drop Files Here to Upload
											</div>
										</FileDropper>
									</>
								)}

								<div className="form-group col mt-3">
									{attachments.length > 0 ? (
										<Table responsive>
											<thead>
												<tr>
													<th>Count</th>
													<th>Name</th>
													<th>Uploaded On</th>
													<th>&nbsp;</th>
												</tr>
											</thead>
											<tbody>
												{attachments.map( (attachment, index) => (
													<tr key={attachment.id}>
														<td>{index+1}</td>
														<td>
														<a href={`/uploads/${attachment.location}`} target="_blank">{attachment.file_name}</a>
														</td>
														<td>{formatDate(attachment.created_at)}</td>
														<td>
															{attachment.status !== 'uploaded' && (status == 'open' || status == 'new' || isEditing) ? (
																<Button name={"del_btn_"+attachment.id} id={"del_attach_"+attachment.id} value={attachment.id} onClick={removeAttachment}>Delete</Button>
															) : (
																<span>&nbsp;</span>
															)}
														</td>
													</tr>
												))}
											</tbody>
										</Table>
									) : (
										<span>No Attachments Found</span>
									)}
								</div>
							</div>
						</div>

						{status == 'new' && (
							<div className="formFooter mb-3">
								<ReCAPTCHA sitekey="6Lde__IUAAAAAO1yiKUd--eHw8URoOPRt7hz-PYS" onChange={handleReCaptcha} className="mb-3" />
								
								{isSaving ? (
									<div>
										<i className="fas fa-sync-alt fa-spin mr-1" ></i> Submitting Form
									</div>
								) : (
									<Button type="submit" color={recaptcha == '' ? 'secondary' : 'primary'} className="validate" disabled={recaptcha == ''}>
										Submit Form
									</Button>
								)}
								
								<Alert color="danger" id="submitBtnMsg" className="ml-3" style={{ display: 'none' }}></Alert>

								<div className="pt-3">
									<cite>
										<i className="fas fa-exclamation-circle"></i> Note: All fields marked with a red asterisk (<span style={{ color: 'red', fontWeight: 'bold' }}>*</span>) are required to Submit.
									</cite>
								</div>
							</div>
						)}

						{status == "open" && (
							<ActionButtons
								status={status}
								saved={saved}
								progress={progress}
								remaining={remaining}

								isBlocking={isBlocking}
								isSaving={isSaving}
								isUploading={isUploading}

								btnClick={saveDraftUpdates} />
						)}

						{/*
							<ol>
								<li>This is an extension of credit and ultimately I am responsible for payment of materials.</li>
								<li>This is not a residential project and I have not received any form of down payment for this job.</li>
								<li>Terms on project accounts will never exceed 60 days and interest will accrue when applicable for past due balances.</li>
								<li>A letter of furnishing will be sent to all parties above via certified mail as required by state.</li>
								<li>I will pay Master Halco for materials related to this project as soon as I am paid.</li>
								<li>I agree to additional non-payment notices that will be sent out to all parties involved should the project not be paid within terms based on the specific state requirements.</li>
								<li>Tax will be charged unless required state tax forms are provided during setup.</li>

								<li>I understand that as a material supplier, Master Halco does not participate in retainage.</li>
							</ol>
						*/}

						{((status === 'open' || status === 'new') && footnote) && (
							<div className="staticTextField">{footnote}</div>
						)}
						</>
					)}

					</div>
				</Form>
			</div>
		</div>

		{!isLoading && (
			<div className="row">
				{((uuid !== '' && uuid !== 0) || (appid !== '' && appid !== 0)) && (
					<div className="d-none d-xl-block col-12 col-xl-2">
					</div>
				)}
				<div className="text-right col-12 col-xl-10">
					<NavLink href={`#page-top`}>
						&uarr; Back to Top
					</NavLink>
				</div>
			</div>
		)}
		</>
	)
}
export default ShowForm;